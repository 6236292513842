import { FC, ReactElement, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Col, Row } from 'antd';
import cs from 'classnames';

import ReCaptcha, { ReCaptchaRef } from 'components/ReCaptcha';

import { toggleSidebar } from 'features/sidebar/sidebarSlice';

import styles from '../Authentication.module.scss';

interface CheckEmailProps {
  handleSubmit: () => Promise<void>;
  canSubmit: boolean;
}

const CheckEmail: FC<CheckEmailProps> = ({
  handleSubmit,
  canSubmit
}): ReactElement => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef<ReCaptchaRef>(null);

  const onCaptchaChange = async (value: string | null): Promise<void> => {
    if (value) {
      try {
        await handleSubmit();
      } catch (error) {
        console.error('Error in handleSubmit:', error);
      }
    }
  };

  const handleContinueClick = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    try {
      if (recaptchaRef.current) {
        recaptchaRef.current.execute();
      }
    } catch (error) {
      console.error('Error executing reCAPTCHA:', error);
    }
  };

  const handleExpired = (): void => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const handleError = (error: Error | undefined): void => {
    console.error('reCAPTCHA error:', error);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ReCaptcha
          ref={recaptchaRef}
          onChange={onCaptchaChange}
          onError={handleError}
          onExpired={handleExpired}
        />
      </Col>
      <Col span={12} className="text-center">
        <Button
          type="link"
          className={cs(styles.borderLess, 'btn')}
          onClick={() => dispatch(toggleSidebar())}
        >
          <FormattedMessage id="form.button.cancel" />
        </Button>
      </Col>
      <Col span={12}>
        <Button
          type="primary"
          className="btn btn-primary"
          disabled={!canSubmit}
          onClick={handleContinueClick}
          block
        >
          <FormattedMessage id="form.control.continue" />
        </Button>
      </Col>
    </Row>
  );
};

export default CheckEmail;
