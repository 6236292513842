import ActiveApplications from 'components/LoggedUserApplications/ActiveApplications';
import InactiveApplications from 'components/LoggedUserApplications/InactiveApplications';

export const tabs = [
  {
    key: 'activeApplications',
    label: 'Active applications',
    component: <ActiveApplications />
  },
  {
    key: 'inactiveApplications',
    label: 'Completed/Inactive applications',
    component: <InactiveApplications />
  }
];
