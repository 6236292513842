import { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Pagination, Row } from 'antd';

import { AppDispatch } from 'app/store';

import Filters from 'components/Filters/Filters';
import JobsList from 'components/Job/JobsList';
import NoJobsAvailable from 'components/Job/NoJobsAvailable/NoJobsAvailable';
import LoadingIndicator from 'components/LoadingIndicator';
import PageTitle from 'components/PageTitle/PageTitle';

import { selectFilters } from 'features/filters/filtersSelectors';
import {
  selectIsJobsLoading,
  selectJobs,
  selectJobsDetails
} from 'features/jobs/jobsSelectors';
import { fetchJobsData } from 'features/jobs/jobsThunks';

import { useInitializeFiltersFromURL } from 'hooks/useInitializeFiltersFromURL';
import { usePagination } from 'hooks/usePagination';

import styles from './Jobs.module.scss';

const BrowseJobsPage = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();
  const filters = useSelector(selectFilters);
  const jobs = useSelector(selectJobs);
  const isJobsLoading = useSelector(selectIsJobsLoading);
  const { totalElements } = useSelector(selectJobsDetails);
  const { currentPage, handlePageChange, pageSize } = usePagination();

  useInitializeFiltersFromURL();

  useEffect(() => {
    dispatch(fetchJobsData());
  }, [dispatch, filters]);

  return (
    <>
      <PageTitle title={intl.formatMessage({ id: 'jobs_list.browse_text' })} />
      <section className={styles.JobsList} id="jobs-list">
        <div className={`${styles.JobsListContainer} container`}>
          <Filters />
          {isJobsLoading && <LoadingIndicator />}
          {!isJobsLoading && jobs?.length === 0 && <NoJobsAvailable />}
          {!isJobsLoading && jobs?.length > 0 && (
            <>
              <Row gutter={[16, 16]}>
                <JobsList jobs={jobs} />
              </Row>
              <div className={styles.pagination}>
                <Pagination
                  current={currentPage}
                  total={totalElements}
                  pageSize={pageSize}
                  showSizeChanger={false}
                  onChange={handlePageChange}
                  responsive
                />
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default BrowseJobsPage;
