import { useEffect, useState } from 'react';

import {
  DynamicThemeFix,
  disable as disableDarkMode,
  enable as enableDarkMode
} from 'darkreader';

import { GTMEventLogger } from 'utils/analytics';

import { GTMEvents } from 'constants/enums';

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(
    localStorage.getItem('darkMode') === 'true'
  );

  const defaultConfig = {};

  const cssIgnoredSelectors = {
    css: `.ant-switch .ant-switch-handle::before { background-color: var(--white);}`
  };

  useEffect(() => {
    if (isDarkMode) {
      enableDarkMode(defaultConfig, cssIgnoredSelectors as DynamicThemeFix);
    } else {
      disableDarkMode();
    }
  }, [isDarkMode]);

  const toggleDarkMode = async () => {
    const newDarkModeState = !isDarkMode;
    setIsDarkMode(newDarkModeState);
    localStorage.setItem('darkMode', String(newDarkModeState));

    if (newDarkModeState) {
      GTMEventLogger(GTMEvents.ClickOnDarkMode);
      enableDarkMode(defaultConfig, cssIgnoredSelectors as DynamicThemeFix);
    } else {
      disableDarkMode();
    }
  };

  return { isDarkMode, toggleDarkMode };
};

export default useDarkMode;
