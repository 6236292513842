import { FC } from 'react';

interface PageCounterProps {
  pageSize: number;
  totalCount: number;
  numberOfElements: number;
  page?: number | null;
}

const PageCounter: FC<PageCounterProps> = ({
  pageSize,
  totalCount,
  numberOfElements,
  page = 0
}) => {
  if (page === null || page === undefined) {
    return '';
  }

  if (numberOfElements === 0) {
    return <span>0 out of 0</span>;
  }

  const from = page * pageSize + 1;
  const to = Math.min(from + numberOfElements - 1, totalCount);
  return <span>{`${from}-${to} out of ${totalCount}`}</span>;
};

export default PageCounter;
