import { Dispatch } from 'react';

import type { UploadFile } from 'antd';
import { StatusCodes } from 'http-status-codes';

import AttachmentsService from 'features/attachments/attachmentsService';

import { catchErrorProps } from 'hooks/useErrorCatch';

import { AttachmentTypes } from 'types/attachments';

import { NO_USER_PERMISSION_MESSAGE_TEXT } from 'constants/notifications';

import { delay } from './delay';

interface uploadFileProps {
  file: UploadFile;
  attachmentType: AttachmentTypes;
  ownerId: string;
  isEditable: boolean;
  catchError: (props: catchErrorProps) => void;
  childId?: string;
  setIsDisabled?: Dispatch<React.SetStateAction<boolean>>;
  onAddFile?: () => void;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
}

const FILE_UPLOAD_DELAY_MS = 1000;

export const uploadFile = async ({
  file,
  attachmentType,
  isEditable = false,
  catchError,
  setIsDisabled,
  onAddFile,
  onUploadStart,
  onUploadEnd
}: uploadFileProps): Promise<void> => {
  try {
    if (onUploadStart) {
      onUploadStart();
    }
    if (setIsDisabled) {
      setIsDisabled(true);
    }

    await AttachmentsService.postUsersDocument(
      file as unknown as Blob,
      attachmentType
    );

    if (setIsDisabled) {
      setIsDisabled(!isEditable);
    }
    if (onAddFile) {
      // We need this, because the BE uploads processes files asynchronously,
      // and we don't know, when we get updated list of attachments. Increase in case of issues.
      await delay(FILE_UPLOAD_DELAY_MS);
      onAddFile();
    }
  } catch (error) {
    catchError({
      error,
      default: {
        message: 'Error uploading file. Please try again later'
      },
      additionalErrorMessageMap: {
        [StatusCodes.FORBIDDEN]: { message: NO_USER_PERMISSION_MESSAGE_TEXT }
      }
    });
    if (onUploadEnd) {
      onUploadEnd();
    }
    if (setIsDisabled) {
      setIsDisabled(false);
    }
  }
};
