import { post } from 'utils/api/api';

import {
  CheckEmailResponse,
  EmailConfig,
  LoginCredentials,
  LoginResponse
} from 'types/auth';

import { CHECK_EMAIL, LOGIN, LOGIN_HASHED } from 'constants/endpoints';

const AuthService = {
  checkEmail: async (config: EmailConfig): Promise<CheckEmailResponse> => {
    const response = await post(CHECK_EMAIL, config);
    return response;
  },

  login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
    const response = await post(LOGIN, credentials);
    return response;
  },

  loginHashed: async (hash: string): Promise<LoginResponse> => {
    const response = await post(LOGIN_HASHED, { hash });
    return response;
  }
};

export default AuthService;
