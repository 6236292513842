import { forwardRef, useImperativeHandle, useRef } from 'react';
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';

interface ReCaptchaProps {
  onChange: (value: string | null) => Promise<void>;
  onError?: (error: Error | undefined) => void;
  onExpired?: () => void;
  onLoad?: () => void;
}

export interface ReCaptchaRef {
  execute: () => void;
  reset: () => void;
}

const ReCaptcha = forwardRef<ReCaptchaRef, ReCaptchaProps>(
  ({ onChange, onError, onExpired, onLoad }, ref) => {
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const key = import.meta.env.VITE_RECAPTCHA_KEY;

    useImperativeHandle(ref, () => ({
      execute: () => {
        if (recaptchaRef.current) {
          recaptchaRef.current.execute();
        }
      },
      reset: () => {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }
    }));

    const handleExpired = () => {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      onExpired?.();
    };

    return (
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={key}
        size="invisible"
        onChange={onChange}
        onErrored={onError}
        onExpired={handleExpired}
        onLoad={onLoad}
        badge="bottomright"
        theme="light"
      />
    );
  }
);

export default ReCaptcha;
