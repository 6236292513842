import { toast } from 'react-toastify';

import Alert from 'components/Alert/Alert';

import { DEFAULT_NOTIFICATION_SHOW_DURATION_SEC } from 'constants/common';
import { AlertType } from 'constants/enums';

import styles from './createToast.module.scss';

interface ToastProps {
  type?: AlertType;
  message?: string;
  description?: string | JSX.Element;
}

export const notify = ({
  type = AlertType.Error,
  message,
  description
}: ToastProps): void => {
  toast[type](
    <Alert type={type} title={message} className={styles.toastAlert}>
      {description}
    </Alert>,
    { icon: false, autoClose: DEFAULT_NOTIFICATION_SHOW_DURATION_SEC * 1000 }
  );
};
