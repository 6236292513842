import { MimeTypes } from 'constants/enums';

export const isDocFile = (fileType: string | undefined): boolean => {
  return fileType === MimeTypes.doc || fileType === MimeTypes.docx;
};

export const isImageFile = (fileType: string | undefined): boolean => {
  return (
    fileType === MimeTypes.jpeg ||
    fileType === MimeTypes.jpg ||
    fileType === MimeTypes.png
  );
};
