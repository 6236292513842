import { FC, KeyboardEvent } from 'react';

import styles from '../styles.module.scss';

interface AuthButtonProps {
  label?: string;
  icon: JSX.Element;
  onClick: () => void;
}

const AuthButton: FC<AuthButtonProps> = ({ label, icon, onClick }) => {
  const handleKeyDown = (callback: () => void) => (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      callback();
    }
  };

  return (
    <div
      className={styles.control}
      onClick={onClick}
      onKeyDown={handleKeyDown(onClick)}
      role="button"
      tabIndex={0}
    >
      {label && <span>{label}</span>}
      {icon}
    </div>
  );
};

export default AuthButton;
