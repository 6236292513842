import { FC, useEffect, useState } from 'react';

import cs from 'classnames';

import { SidebarTabsItem } from 'types/sidebar';

import styles from './SidebarTabs.module.scss';

interface SidebarTabsProps {
  items: SidebarTabsItem[];
}

const SidebarTabs: FC<SidebarTabsProps> = ({ items }) => {
  const [currentItemId, setCurrentItemId] = useState<number | null>(null);

  useEffect(() => {
    if (!items.length) {
      return;
    }

    setCurrentItemId(items[0].id);
  }, [items]);

  const currentComponent =
    items.find((el) => el.id === currentItemId)?.component || null;

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {items.map((item) => (
          <div
            className={cs(
              styles.item,
              currentItemId === item.id ? styles.active : ''
            )}
            key={item.id}
            onClick={() => setCurrentItemId(item.id)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className={styles.content}>{currentComponent}</div>
    </div>
  );
};

export default SidebarTabs;
