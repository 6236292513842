import { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'antd';
import cs from 'classnames';

import styles from 'components/LoggedUserProfile/styles.module.scss';

import { ReactComponent as EditProfileIcon } from 'assets/icons/edit-profile.svg';

export const useEditingState = (): {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  EditButton: ReactNode;
} => {
  const [isEditing, setIsEditing] = useState(false);

  const enableEditing = () => setIsEditing(true);

  const EditButton = (
    <Button
      type="default"
      className={cs(styles.editButton, { [styles.active]: isEditing })}
      icon={<EditProfileIcon />}
      onClick={enableEditing}
    >
      <FormattedMessage id="profile.button.edit" />
    </Button>
  );

  return { isEditing, setIsEditing, EditButton };
};
