import { generateParams } from 'features/filters/filtersSlice';

import { del, get, patch } from 'utils/api/api';

import { Filters } from 'types/filters';
import { NotificationsCount, NotificationsList } from 'types/notifications';

import { MAX_ITEMS_TO_GET } from 'constants/common';
import {
  NOTIFICATIONS,
  NOTIFICATIONS_COUNT,
  NOTIFICATIONS_READ
} from 'constants/endpoints';

class NotificationsService {
  async getReadNotifications(filters: Filters): Promise<NotificationsList> {
    const params = generateParams({
      ...filters,
      read: true
    });

    const response = await get(`${NOTIFICATIONS}${params}`);
    return response;
  }

  async getUnreadNotifications(): Promise<NotificationsList> {
    const params = generateParams({
      page: 0,
      pageSize: MAX_ITEMS_TO_GET,
      read: false
    });

    const response = await get(`${NOTIFICATIONS}${params}`);
    return response;
  }

  async getUnreadNotificationsCount(): Promise<NotificationsCount> {
    const params = generateParams({
      read: false
    });

    const response = await get(`${NOTIFICATIONS_COUNT}${params}`);
    return response;
  }

  async markAsRead(id: string): Promise<string> {
    await patch(`${NOTIFICATIONS}/${id}/read`);
    return id;
  }

  async markAllAsRead(): Promise<void> {
    await patch(`${NOTIFICATIONS_READ}`);
  }

  async deleteReadNotification(id: string): Promise<void> {
    await del(`${NOTIFICATIONS}/${id}`);
  }
  async deleteAllReadNotifications(): Promise<void> {
    await del(`${NOTIFICATIONS_READ}`);
  }
}

export default new NotificationsService();
