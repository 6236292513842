import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CommonsService from 'services/commonsService';

import { selectCountries } from 'features/countries/countriesSelectors';
import {
  setActiveJobCountries,
  setCountries
} from 'features/countries/countriesSlice';

export const useLoadCountries = () => {
  const dispatch = useDispatch();
  const countries = useSelector(selectCountries);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadCountries = async () => {
      setIsLoading(true);
      // TODO: maybe remove such logic? Need to fix problem
      // const cached = getCachedCountries();
      // if (cached) {
      //   const lastUpdated = cached.lastUpdated ?? 0;

      //   dispatch(setCountries({ countries: cached.countries, lastUpdated }));
      //   dispatch(
      //     setActiveJobCountries({
      //       activeJobCountries: cached.activeJobCountries,
      //       lastUpdated
      //     })
      //   );
      //   setIsLoading(false);
      //   return;
      // }

      const [allCountries, jobCountries] = await Promise.all([
        CommonsService.getCountries(),
        CommonsService.getActiveJobCountries()
      ]);

      // setCachedCountries({
      //   countries: allCountries,
      //   activeJobCountries: jobCountries
      // });

      dispatch(
        setCountries({ countries: allCountries, lastUpdated: Date.now() })
      );
      dispatch(
        setActiveJobCountries({
          activeJobCountries: jobCountries,
          lastUpdated: Date.now()
        })
      );

      setIsLoading(false);
    };

    loadCountries();
  }, [dispatch]);

  return { countries, isLoading };
};
