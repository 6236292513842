import { FC } from 'react';

import DarkModeSwitch from 'components/DarkModeSwitch/DarkModeSwitch';

import { ReactComponent as LoginIcon } from 'assets/icons/login.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';

import NotificationsIndicator from '../components/NotificationsIndicator/NotificationsIndicator';
import AuthButton from './AuthButton';
import JobApplications from './JobApplications';
import UserProfile from './UserProfile';

interface ControlsProps {
  isDesktop: boolean;
  isAnonymous: boolean;
  login: () => void;
  logout: () => void;
}

const Controls: FC<ControlsProps> = ({
  isDesktop,
  isAnonymous,
  login,
  logout
}) => (
  <>
    <DarkModeSwitch />
    {isAnonymous ? (
      <AuthButton
        label={isDesktop ? 'Log In' : undefined}
        icon={<LoginIcon />}
        onClick={login}
      />
    ) : (
      <>
        <NotificationsIndicator />
        <JobApplications />
        <UserProfile />
        <AuthButton
          label={isDesktop ? 'Log Out' : undefined}
          icon={<LogoutIcon />}
          onClick={logout}
        />
      </>
    )}
  </>
);

export default Controls;
