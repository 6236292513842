import { ReactElement, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import theme from 'config/antdThemeConfig';
import { routerConfig } from 'config/routerConfig';
import RenderRoutes from 'routes/components/RenderRoutes';

import { AppDispatch } from 'app/store';

import { selectAuthToken } from 'features/auth/authSelectors';
import { fetchUserData } from 'features/auth/authThunks';
import {
  selectAppLocale,
  selectAppLocaleMessages
} from 'features/locale/localeSlice';

import useAppVersionCheck from 'hooks/useAppVersionCheck';
import { useLoadCountries } from 'hooks/useLoadCountries';
import useLoadDictionaries from 'hooks/useLoadDictionaries';

import GDPRPopup from './components/GDPRPopup';
import LOCALES from './constants/locales';
import Loader from './layouts/Suspense';

const App = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const messages = useSelector(selectAppLocaleMessages);
  const locale = useSelector(selectAppLocale);
  const { isOldVersion, clearCache, error } = useAppVersionCheck();

  useEffect(() => {
    if (token) {
      dispatch(fetchUserData());
    }
  }, [token, dispatch]);

  useLoadDictionaries();
  useLoadCountries();

  useEffect(() => {
    if (isOldVersion) {
      clearCache();
    }
  }, [isOldVersion]);

  if (error) {
    console.error(error);
  }

  return (
    <>
      <ConfigProvider theme={theme}>
        {messages && (
          <IntlProvider
            locale={locale}
            defaultLocale={LOCALES.ENGLISH}
            messages={messages}
          >
            <Loader>
              <>
                <Router future={routerConfig}>
                  <RenderRoutes />
                </Router>
                <GDPRPopup />
              </>
            </Loader>
          </IntlProvider>
        )}
      </ConfigProvider>
    </>
  );
};

export default App;
