import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import cs from 'classnames';

import {
  selectShowSidebar,
  selectSidebarContentType
} from 'features/sidebar/sidebarSelectors';
import { closeSidebar } from 'features/sidebar/sidebarSlice';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './Sidebar.module.scss';
import sidebarComponentMapping from './sidebarComponenetMapping';

const Sidebar = (): ReactElement => {
  const dispatch = useDispatch();
  const showSidebar = useSelector(selectShowSidebar);
  const contentType = useSelector(selectSidebarContentType);

  const handleCloseSidebar = (): void => {
    dispatch(closeSidebar());
  };

  const ContentComponent = contentType
    ? sidebarComponentMapping[contentType]
    : null;

  return (
    <>
      {showSidebar && <div className={styles.overlay} />}
      <div className={cs(styles.container, { [styles.show]: showSidebar })}>
        <Button className={styles.closeButton} onClick={handleCloseSidebar}>
          <CloseIcon />
        </Button>
        {ContentComponent}
      </div>
    </>
  );
};

export default Sidebar;
