import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

export const selectAllDictionaries = (state: RootState) =>
  state.dictionaries.data;

export const selectDictionary = (dict: string) =>
  createSelector(
    (state: RootState) => state.dictionaries.data,
    (data) => data[dict] ?? []
  );

export const selectDictionariesStatus = (state: RootState): string =>
  state.dictionaries.status;

export const selectDictionariesError = (state: RootState): string | null =>
  state.dictionaries.error;
