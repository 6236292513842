import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';

import Controls from 'components/Navigation/Header/Controls/Controls';
import { useCheckNotifications } from 'components/Notifications/hooks/useCheckNotifications';

import { selectIsLoggedIn } from 'features/auth/authSelectors';
import { logout } from 'features/auth/authSlice';
import { setSidebarContent } from 'features/sidebar/sidebarSlice';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { DESKTOP_WIDTH } from 'constants/common';
import { RedirectTypes, SidebarContentTypes } from 'constants/enums';
import { MAIN } from 'constants/pathNames';

import Logo from 'assets/images/svg/joblio-logo.svg';

import styles from './styles.module.scss';

const Header = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { width } = useWindowDimensions();
  const isDesktop = width >= DESKTOP_WIDTH;

  useCheckNotifications();

  const login = (): void => {
    dispatch(setSidebarContent(SidebarContentTypes.Authentication));
  };

  const logoutHandler = (): void => {
    dispatch(logout());
    navigate(MAIN, { state: { from: RedirectTypes.Logout } });
  };

  return (
    <header className={styles.headerContainer}>
      <div className={styles.innerContainer}>
        <Row className={styles.rowAlignment}>
          <Col className={styles.logoContainer}>
            <Link to={MAIN}>
              <img src={Logo} alt="logo" />
            </Link>
          </Col>
          <Col>
            <div className={styles.controlsContainer}>
              <Controls
                isDesktop={isDesktop}
                isAnonymous={!isLoggedIn}
                login={login}
                logout={logoutHandler}
              />
            </div>
          </Col>
        </Row>
      </div>
    </header>
  );
};

export default Header;
