import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Form } from 'antd';
import ApplicationService from 'services/applicationService';

import { AppDispatch } from 'app/store';

import Alert from 'components/Alert/Alert';
import IntakeForm from 'components/IntakeForm';

import { selectUser, selectUserId } from 'features/auth/authSelectors';
import { fetchUserData } from 'features/auth/authThunks';

import { notify } from 'utils/toast/createToast';

import { Application } from 'types/jobApplications';
import { Jobseeker } from 'types/jobseekers';

import { FALLBACK_STRING_VALUE } from 'constants/common';
import { AlertType } from 'constants/enums';
import {
  INTAKE_THANK_YOU,
  INTAKE_THANK_YOU_INDUSTRY
} from 'constants/pathNames';

import styles from './IntakePage.module.scss';

const IntakePage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const selectedJobId = location.state?.jobId;
  const selectedIndustry = location.state?.industry;
  const isAfterFirstPasswordSet = location.state?.isAfterFirstPasswordSet;
  const jobseekerId = useSelector(selectUserId);
  const user = useSelector(selectUser);
  const sourcePlatform = 'join.joblio intake';

  const initialValues = useMemo(() => {
    const { email, firstName, lastName, location, phone, country, userId } =
      user || {};

    return {
      email: email || FALLBACK_STRING_VALUE,
      firstName:
        firstName === userId
          ? FALLBACK_STRING_VALUE
          : firstName || FALLBACK_STRING_VALUE,
      lastName: lastName || FALLBACK_STRING_VALUE,
      location: {
        ...location,
        country: country?.code || location?.country || FALLBACK_STRING_VALUE
      },
      phoneNumber: phone,
      ...(selectedIndustry && { lookingForJobInAreas: selectedIndustry })
    };
  }, [user, selectedIndustry]);

  const handleSubmitForm = async (
    formFields: Partial<Jobseeker>
  ): Promise<void> => {
    setLoadingButton(true);

    try {
      await submitIntakeForm(formFields);
      await dispatch(fetchUserData()).unwrap();

      if (selectedJobId) {
        const { id } = await createApplicationRecord(
          jobseekerId,
          selectedJobId
        );
        return navigateToQuestionnaire(id);
      }

      const targetRoute = selectedIndustry
        ? INTAKE_THANK_YOU_INDUSTRY
        : INTAKE_THANK_YOU;
      navigate(targetRoute);
    } catch (error) {
      handleSubmissionError(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const submitIntakeForm = async (
    formFields: Record<string, unknown>
  ): Promise<void> => {
    const fields = {
      ...formFields,
      sourcePlatform
    };
    await ApplicationService.postIntakeForm(fields);
  };

  const createApplicationRecord = async (
    jobseekerId: string,
    jobId: string
  ): Promise<Application> => {
    return ApplicationService.postCreateApplication({ jobseekerId, jobId });
  };

  const navigateToQuestionnaire = (applicationRecordId: string): void => {
    navigate(
      `/questionnaire/${selectedJobId}/${jobseekerId}/${applicationRecordId}`,
      { replace: true }
    );
    notify({
      type: AlertType.Success,
      description: "You've successfully applied for a job"
    });
  };

  const handleSubmissionError = (error: unknown): void => {
    notify({
      type: AlertType.Error,
      message: 'Failed to process intake form',
      description: 'Please try again later'
    });
  };

  return (
    <div className="roundedContainer">
      <div className={styles.content}>
        {isAfterFirstPasswordSet && (
          <Alert type={AlertType.Info} className={styles.alert}>
            <FormattedMessage id="intake.jobseekerFirstTimeInfo" />
          </Alert>
        )}
        <h2 className="title">
          <FormattedMessage id="intake.contentTitle" />
        </h2>
        <p className={styles.textMargin}>
          <FormattedMessage id="intake.contentText" />
        </p>
        <IntakeForm
          form={form}
          onFormSubmit={handleSubmitForm}
          loading={loadingButton}
          initialValues={initialValues}
        />
      </div>
    </div>
  );
};

export default IntakePage;
