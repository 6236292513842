import { useState } from 'react';

import { Button, Typography } from 'antd';
import cs from 'classnames';

import { ReactComponent as ReadMoreArrow } from 'assets/icons/readMoreArrow.svg';

import styles from './ReadMore.module.scss';

const { Paragraph } = Typography;

interface ReadMoreProps {
  text: string;
}

const ReadMore = ({ text }: ReadMoreProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.readMoreContainer}>
      {expanded && <Paragraph>{text}</Paragraph>}
      <Button
        className={styles.button}
        type="link"
        onClick={() => setExpanded((prev) => !prev)}
      >
        {expanded ? 'Read Less' : 'Read More'}
        <ReadMoreArrow
          className={cs(styles.icon, { [styles.rotated]: expanded })}
        />
      </Button>
    </div>
  );
};

export default ReadMore;
