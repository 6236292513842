import { FC, ReactNode, useCallback } from 'react';

import { Avatar as AntAvatar } from 'antd';
import cs from 'classnames';

interface AvatarProps {
  name?: string;
  source?: ReactNode;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const Avatar: FC<AvatarProps> = ({
  name,
  source,
  children,
  className,
  onClick
}) => {
  const getNameLetters = (name?: string): string => {
    return name
      ? name
          .split(/\s/)
          .reduce((response, word) => (response += word.slice(0, 1)), '')
      : '';
  };

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const nameLetters = getNameLetters(name);

  return (
    <AntAvatar
      size="large"
      className={cs(className)}
      onClick={handleClick}
      src={source}
    >
      {nameLetters}
      {children && children}
    </AntAvatar>
  );
};

export default Avatar;
