import { forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import cs from 'classnames';

import { TabProps } from 'types/tabs';

import styles from './styles.module.scss';

const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ tabKey, label, isActive }, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleClick = () => {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('tab', tabKey);
      setSearchParams(newParams, { replace: true });
    };

    return (
      <button
        ref={ref}
        className={cs(styles.tabContainer, { [styles.tabActive]: isActive })}
        onClick={handleClick}
      >
        {label}
      </button>
    );
  }
);

export default Tab;
