import { RootState } from 'app/store';

import { Attachment } from 'types/attachments';

import { UsersAttachmentTypes } from 'constants/enums';

export const selectLoadingAttachmentId = (state: RootState): string | null =>
  state.attachments.loadingAttachmentId;

export const selectUsersDocuments = (
  state: RootState
): Attachment<UsersAttachmentTypes>[] => state.attachments.usersDocuments;
