import { useEffect, useRef } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useNotifications } from 'components/Notifications/hooks/useNotifications';
import { showNotifications } from 'components/Notifications/utils/showNotification';

import { selectUnreadNotificationsToShow } from 'features/notifications/notificationsSelectors';
import {
  clearReadNotifications,
  clearUnreadNotifications,
  resetUnreadNotificationsToShow
} from 'features/notifications/notificationsSlice';

import {
  INIT_DATE_LOCAL_STORAGE_ITEM_NAME,
  NOTIFICATIONS_CHECK_DELAY_SEC
} from 'constants/notifications';

import { useAppDispatch, useAppSelector } from '../../../hooks/useTypedRedux';

dayjs.extend(utc);

const useCheckNotifications = (): void => {
  const dispatch = useAppDispatch();

  const unreadNotificationsToShow = useAppSelector(
    selectUnreadNotificationsToShow
  );

  const { readNotification, checkUnreadNotifications } = useNotifications();

  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    localStorage.setItem(
      INIT_DATE_LOCAL_STORAGE_ITEM_NAME,
      dayjs.utc().toISOString()
    );

    return (): void => {
      localStorage.removeItem(INIT_DATE_LOCAL_STORAGE_ITEM_NAME);
      dispatch(clearReadNotifications());
      dispatch(clearUnreadNotifications());
    };
  }, []);

  useEffect(() => {
    checkUnreadNotifications();

    intervalRef.current = setInterval(
      checkUnreadNotifications,
      NOTIFICATIONS_CHECK_DELAY_SEC * 1000
    );

    return (): void => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [checkUnreadNotifications]);

  useEffect(() => {
    if (!unreadNotificationsToShow?.length) {
      return;
    }

    const initDate = localStorage.getItem(INIT_DATE_LOCAL_STORAGE_ITEM_NAME);

    if (!initDate) {
      return;
    }

    const unreadNotificationsToShowCreatedAfterInit =
      unreadNotificationsToShow.filter((notification) => {
        if (!notification?.createdAt) {
          return false;
        }

        return dayjs.utc(notification.createdAt).isAfter(dayjs.utc(initDate));
      });

    unreadNotificationsToShowCreatedAfterInit.forEach((notification) => {
      showNotifications({ notification, onReadNotification: readNotification });
    });

    dispatch(resetUnreadNotificationsToShow());
  }, [unreadNotificationsToShow]);
};

export { useCheckNotifications };
