import { FC, ReactElement } from 'react';

import { Button, Col, Modal, Row } from 'antd';

import { DialogModalTypes } from 'constants/enums';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import styles from './DialogModal.module.scss';

interface DialogModalProps {
  isOpen: boolean;
  type: DialogModalTypes;
  onClose: () => void;
  onConfirm?: () => void;
  title?: string;
  // to make text partially bold please use <>text <span>bold text</span></>
  description?: string | ReactElement;
}

const MODAL_WIDTH = '550px';

const DialogModal: FC<DialogModalProps> = ({
  isOpen,
  type,
  onClose,
  onConfirm,
  title,
  description
}) => {
  const deleteButton = (
    <Button
      type="primary"
      className={styles.buttonDelete}
      onClick={onConfirm}
      icon={<TrashIcon className="icon-trash" />}
      disabled={false}
    >
      Delete
    </Button>
  );

  const CONFIG = {
    [DialogModalTypes.Delete]: {
      title: 'Delete',
      button: deleteButton
    }
  };

  return (
    <Modal
      className={styles.container}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={MODAL_WIDTH}
      title={title || CONFIG[type].title}
      closeIcon={<CloseIcon />}
      destroyOnClose
    >
      {description && (
        <Row>
          <Col span={24} className={styles.description}>
            {description}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <div className={styles.buttons}>
            <Button
              type="link"
              onClick={onClose}
              className={styles.button}
              disabled={false}
            >
              Cancel
            </Button>
            {CONFIG[type].button}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default DialogModal;
