import { useState } from 'react';

import DialogModal from 'components/DialogModal/DialogModal';
import { useNotifications } from 'components/Notifications/hooks/useNotifications';

import { selectReadNotifications } from 'features/notifications/notificationsSelectors';
import { getReadNotifications } from 'features/notifications/notificationsThunks';

import { useInfiniteScrollPagination } from 'hooks/useInfiniteScrollPagination';
import { useAppSelector } from 'hooks/useTypedRedux';

import { NotificationsList } from 'types/notifications';

import { DialogModalTypes } from 'constants/enums';

import NotificationCard from '../NotificationCard/NotificationCard';
import styles from './NotificationsArchive.module.scss';

const NotificationsArchive = (): JSX.Element => {
  const readNotifications = useAppSelector(selectReadNotifications);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState<boolean>(false);

  const { deleteReadNotification, deleteAllReadNotifications } =
    useNotifications();

  const { lastElementRef } = useInfiniteScrollPagination<NotificationsList>({
    loadItemsThunk: getReadNotifications,
    errorMessage: 'Error loading read notifications.'
  });

  const handleDeleteConfirmModalOpen = (): void => {
    setIsDeleteConfirmModalOpen(true);
  };

  const handleDeleteConfirmModalClose = (): void => {
    setIsDeleteConfirmModalOpen(false);
  };

  const handleDeleteAllReadNotifications = (): void => {
    deleteAllReadNotifications();
    handleDeleteConfirmModalClose();
  };

  const isEmpty = !readNotifications?.length;

  return (
    <>
      <aside>
        <div className={styles.top}>
          <div className={styles.title}>Archived notifications</div>
          {!isEmpty && (
            <button
              onClick={handleDeleteConfirmModalOpen}
              className={styles.deleteBtn}
            >
              Delete all
            </button>
          )}
        </div>

        {isEmpty ? (
          <div className={styles.empty}>Empty</div>
        ) : (
          <div className={styles.list}>
            {readNotifications.map((notification, index) => {
              const isLastElement = readNotifications.length === index + 1;
              return (
                <div
                  key={notification.id}
                  ref={isLastElement ? lastElementRef : null}
                >
                  <NotificationCard
                    notification={notification}
                    onReadNotification={deleteReadNotification}
                  />
                </div>
              );
            })}
          </div>
        )}
      </aside>
      {isDeleteConfirmModalOpen && (
        <DialogModal
          onClose={handleDeleteConfirmModalClose}
          isOpen={isDeleteConfirmModalOpen}
          title="Delete"
          description="Are you sure you want to delete all notifications?"
          type={DialogModalTypes.Delete}
          onConfirm={handleDeleteAllReadNotifications}
        />
      )}
    </>
  );
};

export default NotificationsArchive;
