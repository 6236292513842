import { FC } from 'react';

import { Tooltip } from 'antd';

import { TooltipMapping } from 'constants/tooltipMapping';

import styles from './LabelWithTooltip.module.scss';

interface LabelWithTooltipProps {
  labelKey: string;
  label: string;
}
const LabelWithTooltip: FC<LabelWithTooltipProps> = ({ labelKey, label }) => (
  <Tooltip title={TooltipMapping[labelKey as keyof typeof TooltipMapping]}>
    <div className={styles.label}>{label}</div>
  </Tooltip>
);
export default LabelWithTooltip;
