import { MouseEvent, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Col } from 'antd';
import cs from 'classnames';

import JobIndustry from 'components/Job/JobIndustry/JobIndustry';
import JobLocation from 'components/Job/JobLocation/JobLocation';
import JobType from 'components/Job/JobType/JobType';
import NewTag from 'components/Job/NewTag/NewTag';
import Salary from 'components/Job/Salary/Salary';
import Tag from 'components/Tag/Tag';

import { GTMEventLogger } from 'utils/analytics';
import formatDate from 'utils/formatting/formatDate';

import { Job } from 'types/jobs';

import { GTMEvents } from 'constants/enums';
import { AMBASSADOR, AMBASSADOR_JOBS, JOBS } from 'constants/pathNames';

import styles from './JobCard.module.scss';

interface JobCardProps {
  job: Job;
}

const JobCard = ({ job }: JobCardProps): ReactElement => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleDetailsLink = (e: MouseEvent): void => {
    GTMEventLogger(GTMEvents.ClickOnJobDetails);
    e.preventDefault();
    const basePath = pathname.includes(AMBASSADOR) ? AMBASSADOR_JOBS : JOBS;
    navigate(`${basePath}/${job.id}`, { replace: false });
  };

  return (
    <Col span={24} md={12} lg={8} key={job.id} id={job.id}>
      <div className={styles.container}>
        <div className={styles.jobCardTopContainer}>
          <div className={styles.date}>{formatDate(job.editDate)}</div>
          <div className={styles.salaryContainer}>
            <div className={styles.tags}>
              <NewTag createdDate={job.createdDate} editDate={job.editDate} />
              {job.jobType?.map((jobType) => (
                <JobType jobType={jobType} key={jobType} />
              ))}
            </div>
            <div className={styles.salary}>
              <Salary
                actualSalary={job.actualSalary}
                payrollRates={job.payrollRates}
              />
            </div>
          </div>

          <h3 className={styles.jobTitle}>{job.title}</h3>
          <JobLocation location={job.location} countryCode={job.country.code} />
          <div className={styles.industryBenefitsTags}>
            <JobIndustry industry={job.industry} />
            {job.benefits && (
              <Tag
                label={intl.formatMessage({ id: 'job.label.benefits' })}
                big
                className={styles.benefitTag}
              />
            )}
          </div>
        </div>

        <Button
          type="link"
          className={cs('btn', styles.detailsButton)}
          onClick={handleDetailsLink}
        >
          <FormattedMessage id="job.details" />
        </Button>
      </div>
    </Col>
  );
};

export default JobCard;
