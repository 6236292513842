import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MODULE_SORT_CONFIG } from 'config/sortConfig';

import { AppDispatch } from 'app/store';

import LoadingOverlay from 'components/LoadingOverlay';
import ActiveApplicationTable from 'components/LoggedUserApplications/ActiveApplicationTable';
import PageControls from 'components/PageControls/PageControls';

import { selectAuthToken } from 'features/auth/authSelectors';
import { selectFilters } from 'features/filters/filtersSelectors';
import { setStatus } from 'features/filters/filtersSlice';
import {
  selectJobApplications,
  selectJobApplicationsDetails,
  selectJobApplicationsLoading
} from 'features/jobApplications/jobApplicationsSelectors';
import { fetchJobApplicationsData } from 'features/jobApplications/jobApplicationsThunks';

import useErrorCatch from 'hooks/useErrorCatch';

import { JobApplicationStatus } from 'constants/enums';

const ActiveApplications = () => {
  const dispatch: AppDispatch = useDispatch();
  const token = useSelector(selectAuthToken);
  const filters = useSelector(selectFilters);
  const applications = useSelector(selectJobApplications);
  const { totalElements, numberOfElements } = useSelector(
    selectJobApplicationsDetails
  );
  const isLoading = useSelector(selectJobApplicationsLoading);

  const catchError = useErrorCatch();

  useEffect(() => {
    if (!token) return;

    dispatch(
      setStatus([
        JobApplicationStatus.New,
        JobApplicationStatus.Pending,
        JobApplicationStatus.InReview,
        JobApplicationStatus.Accepted,
        JobApplicationStatus.Hired
      ])
    );
  }, [token, dispatch]);

  useEffect(() => {
    if (!token || !filters.status?.length) return;

    dispatch(fetchJobApplicationsData())
      .unwrap()
      .catch((error) => {
        catchError({
          error,
          default: {
            message: 'Operation Failed',
            description: 'Failed to load applications. Please try again.'
          }
        });
      });
  }, [token, filters.status, dispatch]);

  return isLoading ? (
    <LoadingOverlay isLoading={isLoading} />
  ) : (
    <>
      <PageControls
        sortingConfig={MODULE_SORT_CONFIG.applications}
        totalElements={totalElements}
        numberOfElements={numberOfElements}
      />

      {applications.map((application) => (
        <ActiveApplicationTable
          application={application}
          key={application.id}
        />
      ))}
    </>
  );
};

export default ActiveApplications;
