import { RootState } from 'app/store';

export const selectJobApplicationsDetails = (state: RootState) =>
  state.jobApplications;

export const selectJobApplications = (state: RootState) =>
  state.jobApplications.jobApplications;

export const selectJobApplicationsLoading = (state: RootState) =>
  state.jobApplications.isLoading;
