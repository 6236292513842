import { FC, ReactNode } from 'react';

import Avatar from 'components/Avatar/Avatar';
import ProgressBar from 'components/ProgressBar/ProgressBar';

import { PROGRESS_GRADIENT, PROGRESS_SUCCESS } from 'constants/common';

import styles from './ProfileAvatar.module.scss';

interface ProfileAvatarProps {
  name?: string;
  source?: ReactNode;
  profileProgress: number;
}

const ProfileAvatar: FC<ProfileAvatarProps> = ({
  name,
  source,
  profileProgress
}) => {
  const isProfileComplete = profileProgress === 100;

  const strokeColor = isProfileComplete ? PROGRESS_SUCCESS : PROGRESS_GRADIENT;

  return (
    <div className={styles.profileAvatarContainer}>
      <ProgressBar
        progress={profileProgress}
        strokeColor={strokeColor}
        type="circle"
        size={102}
        strokeWidth={6}
        hideProgress
        className={styles.profileProgressBar}
      >
        <Avatar source={source} className={styles.avatarContainer} />
      </ProgressBar>
      <p className={styles.userName}>{name}</p>
    </div>
  );
};

export default ProfileAvatar;
