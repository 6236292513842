import { ReactElement, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button, Col, Form, Row } from 'antd';

import { useEditingState } from 'components/LoggedUserProfile/hooks/useEditingState';
import styles from 'components/LoggedUserProfile/styles.module.scss';
import MultiSelectCustom from 'components/MultiSelectCustom';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import { Jobseeker } from 'types/jobseekers';

import { SECTION_ROW_GUTTER } from 'constants/common';
import { Dictionaries, GTMEvents } from 'constants/enums';

interface IndustryFormProps {
  loading: boolean;
  user: Partial<Jobseeker>;
  handleFormSubmit: (fields: Partial<Jobseeker>) => Promise<void>;
}

const IndustryForm = ({
  loading,
  user,
  handleFormSubmit
}: IndustryFormProps): ReactElement => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const fieldsValues = Form.useWatch([], form);

  const { isEditing, setIsEditing, EditButton } = useEditingState();

  const defaultValidateMessages = {
    required: 'Field is required'
  };

  const initialValues = useMemo(
    () => ({
      lookingForJobInAreas: user?.lookingForJobInAreas || []
    }),
    [user]
  );

  const industriesDict = useSelector(selectDictionary(Dictionaries.Industry));

  const handleSubmitForm = (): void => {
    handleFormSubmit(fieldsValues);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      validateMessages={defaultValidateMessages}
      initialValues={{ ...initialValues }}
    >
      <Row gutter={SECTION_ROW_GUTTER}>
        <Col span={24} className={styles.editButtonContainer}>
          {EditButton}
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24}>
          <Form.Item
            name="lookingForJobInAreas"
            label={intl.formatMessage({
              id: 'profile.industry.label.industries'
            })}
          >
            <MultiSelectCustom
              name="lookingForJobInAreas"
              labelId="profile.industry.label.industries"
              form={form}
              options={industriesDict}
              placeholderId="form.intake.placeholder.occupationNew"
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className={styles.submitContainer}>
        {isEditing && (
          <>
            <Button
              className={styles.cancelButton}
              onClick={handleCancelClick}
              loading={loading}
            >
              <FormattedMessage id="form.button.cancel" />
            </Button>
            <Button
              type="primary"
              loading={loading}
              className="btn btn-primary intake-button"
              onClick={handleSubmitForm}
            >
              <FormattedMessage id="form.button.save" />
            </Button>
          </>
        )}
      </div>
    </Form>
  );
};

export default IndustryForm;
