import { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Pagination, Row } from 'antd';

import { AppDispatch } from 'app/store';

import PageTitle from 'components/PageTitle/PageTitle';
import TabsNav from 'components/Tabs/Tabs';
import { tabs } from 'components/Tabs/TabsConfigApplications';

import { selectAuthToken } from 'features/auth/authSelectors';
import { selectJobApplications } from 'features/jobApplications/jobApplicationsSelectors';
import { fetchJobApplicationsData } from 'features/jobApplications/jobApplicationsThunks';

import useErrorCatch from 'hooks/useErrorCatch';

const ApplicationsPage = () => {
  const intl = useIntl();
  const dispatch: AppDispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const token = useSelector(selectAuthToken);
  const jobApplications = useSelector(selectJobApplications);
  const activeTab = searchParams.get('tab') || tabs[0]?.key;

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'jobApplications.pageTitle' })}
        subTitle={intl.formatMessage({ id: 'jobApplications.pageSubTitle' })}
      />
      <div className="container">
        <TabsNav tabs={tabs} />
      </div>
    </>
  );
};

export default ApplicationsPage;
