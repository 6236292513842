import { useEffect, useRef, useState } from 'react';

import { AsyncThunk } from '@reduxjs/toolkit';

import { List } from 'types/api';
import { Filters } from 'types/filters';

import { PAGE_SIZE } from 'constants/common';

import useErrorCatch from './useErrorCatch';
import { useAppDispatch } from './useTypedRedux';

interface UseInfiniteScrollPaginationProps<T> {
  // This hook for now is only implemented to use with a thunk,
  // reducer is responsible for data storing and updating
  loadItemsThunk: AsyncThunk<T, Filters, any>;
  errorMessage?: string;
}
const DEFAULT_ERROR_MESSAGE = 'Error loading items.';
const useInfiniteScrollPagination = <T extends List>({
  loadItemsThunk,
  errorMessage = DEFAULT_ERROR_MESSAGE
}: UseInfiniteScrollPaginationProps<T>): {
  lastElementRef: (node: HTMLDivElement | null) => void;
} => {
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const catchError = useErrorCatch();
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    load();
  }, []);

  const load = async (page: number = 0): Promise<void> => {
    const filters: Filters = {
      page,
      pageSize: PAGE_SIZE
    };
    try {
      setIsLoading(true);
      const res = (await dispatch(loadItemsThunk(filters)).unwrap()) as T;
      setHasMore(!res.last);
      setPage(res.pageable.pageNumber);
    } catch (error) {
      catchError({
        error,
        default: {
          description: errorMessage
        }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const lastElementRef = (node: HTMLDivElement | null): void => {
    if (isLoading) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        load(page + 1);
      }
    });
    if (node) {
      observer.current.observe(node);
    }
  };
  return { lastElementRef };
};
export { useInfiniteScrollPagination };
