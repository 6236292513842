import formatAndOrderData from 'utils/formatAndOrderData';

import { Country, CountryOption } from 'types/commons';

import { COUNTRIES_ORDER } from 'constants/common';

interface GetCountriesOptionsProps {
  countries: Country[];
  useNameAsValue?: boolean;
  useLongCode?: boolean;
}

const getCountriesOptions = ({
  countries,
  useNameAsValue = false,
  useLongCode = false
}: GetCountriesOptionsProps): CountryOption[] => {
  const baseOptions = countries.map(({ code, name, longCode }) => ({
    value: useLongCode ? longCode : useNameAsValue ? name : code,
    label: name
  }));

  return formatAndOrderData(baseOptions, COUNTRIES_ORDER) as CountryOption[];
};

export default getCountriesOptions;
