import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthService from 'services/authService';

import { setCredentials } from 'features/auth/authSlice';

import useErrorCatch from 'hooks/useErrorCatch';

const EncryptedLogin = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const catchError = useErrorCatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const rawQuery = location.search;
  const hash: string = rawQuery.startsWith('?') ? rawQuery.slice(1) : '';

  const handleCheckEmail = async (): Promise<void> => {
    try {
      setIsLoading(true);

      const { accessToken } = await AuthService.loginHashed(hash);

      dispatch(
        setCredentials({
          token: accessToken
        })
      );

      setIsSuccess(true);
      navigate('/');
    } catch (error) {
      setIsSuccess(false);
      console.log('error: ', error);

      catchError({
        error
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hash) {
      handleCheckEmail();
    }
  }, [hash]);

  return (
    <div className="container">
      {isLoading ? (
        <p>Loading...</p>
      ) : isSuccess ? (
        <p>Authentication successful!</p>
      ) : isSuccess === false ? (
        <p>Authentication failed. Please try again.</p>
      ) : (
        <p>Waiting for response...</p>
      )}
    </div>
  );
};

export default EncryptedLogin;
