import BadgeIcon from 'components/BadgeIcon/BadgeIcon';

import { selectUnreadNotificationsCount } from 'features/notifications/notificationsSelectors';
import { setSidebarContent } from 'features/sidebar/sidebarSlice';

import { useAppDispatch, useAppSelector } from 'hooks/useTypedRedux';

import { SidebarContentTypes } from 'constants/enums';

import { ReactComponent as NotificationsIcon } from 'assets/icons/notification.svg';

import styles from './NotificationsIndicator.module.scss';

const NotificationsIndicator = (): JSX.Element => {
  const unreadNotifications = useAppSelector(selectUnreadNotificationsCount);
  const isShowBadge = Boolean(unreadNotifications);
  const dispatch = useAppDispatch();

  const handleNotificationsViewOpen = (): void => {
    dispatch(setSidebarContent(SidebarContentTypes.NotificationsView));
  };

  return (
    <div
      className={styles.wrapper}
      onClick={handleNotificationsViewOpen}
      role="button"
      tabIndex={0}
      title="Notifications"
    >
      <BadgeIcon showBadge={isShowBadge} icon={<NotificationsIcon />} />
    </div>
  );
};

export default NotificationsIndicator;
