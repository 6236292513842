import { MimeTypes, UsersAttachmentTypes } from './enums';

export const PREVIEW_FILE_TYPES = [
  MimeTypes.pdf,
  MimeTypes.jpeg,
  MimeTypes.jpg,
  MimeTypes.png,
  MimeTypes.docx,
  MimeTypes.doc
];
export const FORCED_SHOW_THUMBNAILS_FILE_TYPES = [
  MimeTypes.docx,
  MimeTypes.doc
];

export const cvFileTypes = '.pdf,.doc,.docx';
export const commonFileTypes = '.pdf,.doc,.docx,.jpeg,.jpg,.png';
export const imageFileTypes = '.jpeg,.jpg,.png';

export const USER_ATTACHMENTS_FILE_TYPES = {
  [UsersAttachmentTypes.UserAvatar]: imageFileTypes,
  [UsersAttachmentTypes.ClientContract]: commonFileTypes,
  [UsersAttachmentTypes.TaoContract]: commonFileTypes,
  [UsersAttachmentTypes.ExtraPaperWork]: commonFileTypes,
  [UsersAttachmentTypes.JobImage]: imageFileTypes,
  [UsersAttachmentTypes.IdCard]: commonFileTypes,
  [UsersAttachmentTypes.Passport]: commonFileTypes,
  [UsersAttachmentTypes.Visa]: commonFileTypes,
  [UsersAttachmentTypes.WorkPermit]: commonFileTypes,
  [UsersAttachmentTypes.CV]: cvFileTypes,
  [UsersAttachmentTypes.DriverLicense]: commonFileTypes,
  [UsersAttachmentTypes.PreScreeningCopy]: commonFileTypes,
  [UsersAttachmentTypes.UserPrivateDocument]: commonFileTypes,
  [UsersAttachmentTypes.ICContract]: commonFileTypes,
  [UsersAttachmentTypes.PartnerContract]: commonFileTypes,
  [UsersAttachmentTypes.AmbassadorContract]: commonFileTypes,
  [UsersAttachmentTypes.OtherJobseekerDocuments]: commonFileTypes
};
