import { FC, ReactElement } from 'react';

import styles from './PageTitle.module.scss';

interface PageTitleProps {
  title: string;
  subTitle?: string;
}

const PageTitle: FC<PageTitleProps> = ({ title, subTitle }): ReactElement => (
  <section className={styles.pageTitleSection}>
    <div className={`${styles.pageTitleContainer} container`}>
      <h2 className={styles.title}>{title}</h2>
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
    </div>
  </section>
);

export default PageTitle;
