import {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { useIntl } from 'react-intl';

import { Select } from 'antd';
import { FormInstance } from 'antd/es/form';

import { GTMEventLogger } from 'utils/analytics';

import { GTMEvents } from 'constants/enums';

interface MultiSelectCustomProps {
  name: string;
  form: FormInstance;
  options: { value: string; label: string }[];
  customOptions?: ReactNode;
  placeholderId: string;
  labelId?: string;
  optionRender?: (data: any) => ReactElement;
  labelRender?: (data: any) => ReactElement;
  GTMEventName?: GTMEvents;
  disabled?: boolean;
}

const MultiSelectCustom: FC<MultiSelectCustomProps> = ({
  name,
  form,
  options,
  placeholderId,
  labelId,
  optionRender,
  labelRender,
  GTMEventName,
  disabled = false
}) => {
  const intl = useIntl();
  const [selectedValues, setSelectedValues] = useState<string[]>(
    form.getFieldValue(name) || []
  );
  const isFirstRender = useRef(true);

  const handleChange = (values: string[]): void => {
    GTMEventName && GTMEventLogger(GTMEventName);

    setSelectedValues(values);
    form.setFieldsValue({ [name]: values });

    if (!isFirstRender.current) {
      form.validateFields([name]);
    }
  };

  useEffect(() => {
    const formValues = form.getFieldValue(name);
    if (formValues !== selectedValues) {
      setSelectedValues(formValues || []);
    }
    isFirstRender.current = false;
  }, [form.getFieldValue(name)]);

  return (
    <Select
      mode="multiple"
      value={selectedValues}
      options={options.map((o) => ({ value: o.value, label: o.label }))}
      optionLabelProp="label"
      optionFilterProp="label"
      optionRender={optionRender}
      labelRender={labelRender}
      onChange={handleChange}
      maxTagCount="responsive"
      placeholder={intl.formatMessage({ id: placeholderId })}
      disabled={disabled}
    >
      {options.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {optionRender ? optionRender({ value, label }) : label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MultiSelectCustom;
