import { FC, ReactElement, useState } from 'react';

import type { UploadFile } from 'antd';
import { Upload } from 'antd';

import useErrorCatch from 'hooks/useErrorCatch';

import { uploadFile } from 'utils/uplodFile';

import { AttachmentTypes } from 'types/attachments';

import { ReactComponent as FileIcon } from 'assets/icons/file.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

interface UploadFileWithButtonProps {
  allowedFileTypes: string;
  attachmentType: AttachmentTypes;
  ownerId: string;
  onAddFile: () => void;
  childId?: string;
  onUploadStart?: () => void;
  onUploadEnd?: () => void;
  isEditable?: boolean;
}

const MAX_FILES_COUNT = 1;

const UploadFileWithButton: FC<UploadFileWithButtonProps> = ({
  allowedFileTypes,
  attachmentType,
  ownerId,
  onAddFile,
  onUploadStart,
  onUploadEnd,
  isEditable = false,
  childId
}): ReactElement => {
  const [isDisabled, setIsDisabled] = useState(!isEditable);
  const catchError = useErrorCatch();

  const { Dragger } = Upload;

  const handleBeforeUpload = (file: UploadFile): boolean => {
    uploadFile({
      file,
      attachmentType,
      ownerId,
      childId,
      onAddFile,
      setIsDisabled,
      catchError,
      onUploadStart,
      onUploadEnd,
      isEditable
    });

    return false;
  };

  const disabled = isDisabled || !isEditable;

  return (
    <div className={'upload-button-container upload-file-with-button'}>
      <Dragger
        fileList={[]}
        beforeUpload={handleBeforeUpload}
        accept={allowedFileTypes}
        maxCount={MAX_FILES_COUNT}
        iconRender={() => <FileIcon />}
        disabled={disabled}
      >
        <UploadIcon className={isDisabled ? 'icon-disabled' : 'icon-enabled'} />
        <span>Upload a file</span>
      </Dragger>
    </div>
  );
};

export default UploadFileWithButton;
