import { useCallback } from 'react';

import {
  clearReadNotification,
  clearReadNotifications,
  clearUnreadNotification,
  clearUnreadNotifications
} from 'features/notifications/notificationsSlice';
import {
  deleteAllReadNotificationsThunk,
  deleteReadNotificationThunk,
  getUnreadNotifications,
  getUnreadNotificationsCount,
  markAllNotificationsAsRead,
  markNotificationAsRead
} from 'features/notifications/notificationsThunks';

import useErrorCatch from 'hooks/useErrorCatch';
import { useAppDispatch } from 'hooks/useTypedRedux';

const useNotifications = (): {
  loadUnreadNotifications: () => Promise<void>;
  checkUnreadNotifications: () => Promise<void>;
  readNotification: (id: string) => Promise<void>;
  readAllNotifications: () => Promise<void>;
  deleteAllReadNotifications: () => Promise<void>;
  deleteReadNotification: (id: string) => Promise<void>;
} => {
  const dispatch = useAppDispatch();
  const catchError = useErrorCatch();

  const loadUnreadNotifications = useCallback(async () => {
    try {
      await dispatch(getUnreadNotifications()).unwrap();
    } catch (error) {
      // To not to spam errors
      console.error(error);
    }
  }, [dispatch]);

  const checkUnreadNotifications = useCallback(async () => {
    try {
      const countRes = await dispatch(getUnreadNotificationsCount()).unwrap();

      if (!countRes?.count) {
        return;
      }

      loadUnreadNotifications();
    } catch (error) {
      // To not to spam errors
      console.error(error);
    }
  }, [dispatch, loadUnreadNotifications]);

  const readNotification = useCallback(
    async (id: string) => {
      try {
        // Optimistic
        dispatch(clearUnreadNotification(id));

        await dispatch(markNotificationAsRead(id)).unwrap();
      } catch (error) {
        catchError({
          error,
          default: {
            description: 'Error while marking notification as read.'
          }
        });
      } finally {
        loadUnreadNotifications();
      }
    },
    [dispatch, catchError, loadUnreadNotifications]
  );

  const readAllNotifications = useCallback(async () => {
    try {
      // Optimistic
      dispatch(clearUnreadNotifications());

      await dispatch(markAllNotificationsAsRead()).unwrap();
    } catch (error) {
      catchError({
        error,
        default: {
          description: 'Error while marking notifications as read.'
        }
      });
    } finally {
      loadUnreadNotifications();
    }
  }, [dispatch, catchError, loadUnreadNotifications]);

  const deleteAllReadNotifications = useCallback(async () => {
    try {
      // Optimistic
      dispatch(clearReadNotifications());

      await dispatch(deleteAllReadNotificationsThunk()).unwrap();
    } catch (error) {
      catchError({
        error,
        default: {
          description: 'Error while deleting read notifications.'
        }
      });
    } finally {
      loadUnreadNotifications();
    }
  }, [dispatch, catchError, loadUnreadNotifications]);

  const deleteReadNotification = useCallback(
    async (id: string) => {
      try {
        // Optimistic
        dispatch(clearReadNotification(id));

        await dispatch(deleteReadNotificationThunk(id)).unwrap();
      } catch (error) {
        catchError({
          error,
          default: {
            description: 'Error while deleting read notification.'
          }
        });
      } finally {
        loadUnreadNotifications();
      }
    },
    [dispatch, catchError, loadUnreadNotifications]
  );

  return {
    readNotification,
    readAllNotifications,
    loadUnreadNotifications,
    checkUnreadNotifications,
    deleteAllReadNotifications,
    deleteReadNotification
  };
};

export { useNotifications };
