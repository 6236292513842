import cs from 'classnames';
import dayjs from 'dayjs';

import { Notification } from 'types/notifications';

import { DATE_TIME_FORMAT } from 'constants/common';
import { NotificationLevels } from 'constants/enums';

import { ReactComponent as CloseIcon } from 'assets/icons/closeSmall.svg';
import { ReactComponent as SystemIcon } from 'assets/icons/setting.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';

import styles from './NotificationCard.module.scss';

interface NotificationCardProps {
  notification: Notification;
  onReadNotification: (id: string) => Promise<void>;
}

const NotificationCard = ({
  notification,
  onReadNotification
}: NotificationCardProps): JSX.Element => {
  const { id, level, createdAt, notificationData, system, read } = notification;

  const classes = {
    [styles.error]: level === NotificationLevels.Error,
    [styles.alert]: level === NotificationLevels.Alert,
    [styles.info]: level === NotificationLevels.Info,
    [styles.confirm]: level === NotificationLevels.Confirm,
    [styles.read]: read
  };

  const title = notificationData?.title;
  const content = notificationData?.content;

  const dateString = createdAt ? dayjs(createdAt).format(DATE_TIME_FORMAT) : '';

  return (
    <div className={cs(styles.wrapper, classes)}>
      <div className={styles.top}>
        <div className={styles.title}>{title || ''}</div>
        <button
          className={read ? styles.delete : styles.close}
          onClick={() => onReadNotification(id)}
        >
          {read ? <DeleteIcon /> : <CloseIcon />}
        </button>
      </div>

      {content && <div className={styles.content}>{content}</div>}

      <div className={styles.bottom}>
        {dateString && <div className={styles.date}>{dateString}</div>}
        {system && (
          <div className={cs(styles.system, classes)}>
            <SystemIcon />
            <span>System</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationCard;
