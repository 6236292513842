import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Col, Row } from 'antd';
import { Footer } from 'antd/es/layout/layout';

import FindBlock from 'components/FindBlock';

import { GTMEventLogger } from 'utils/analytics';

import {
  COOKIE_PATH,
  DATA_PRIVACY_PATH,
  PRIVACY_PATH,
  TERMS_PATH
} from 'constants/common';
import { COPYRIGHT_YEAR } from 'constants/common';
import { GTMEvents } from 'constants/enums';

import { ReactComponent as Image } from 'assets/images/svg/gdpr_logo_blue.svg';

import { buildVersion } from '../../../main';
import styles from './Footer.module.scss';

const AppFooter = () => {
  const { pathname } = useLocation();

  const handleClickOnLink = (GTMEventName: GTMEvents): void =>
    GTMEventLogger(GTMEventName);

  return (
    <Footer className={styles.Footer}>
      <div className="container">
        {pathname.includes('/jobs/') && <FindBlock />}
        <div className={styles.linkSection}>
          <Row align="middle" justify="space-between">
            <Col xs={24} lg={4}>
              <p>
                <FormattedMessage
                  id="footer.copyright"
                  values={{ copyrightYear: COPYRIGHT_YEAR }}
                />{' '}
                <FormattedMessage id="footer.build" /> {buildVersion?.version}
              </p>
            </Col>
            <Col xs={24} lg={11} className={styles.RightContainer}>
              <Image className={styles.Logo} />
              <div className={`${styles.linksContainer} ${styles.noWrap}`}>
                <a
                  className={styles.FooterLink}
                  href={TERMS_PATH}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Terms of use"
                  onClick={() => handleClickOnLink(GTMEvents.ClickOnTermsOfUse)}
                >
                  <FormattedMessage id="terms_of_use" />
                </a>
                <a
                  className={styles.FooterLink}
                  href={PRIVACY_PATH}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={PRIVACY_PATH}
                  onClick={() =>
                    handleClickOnLink(GTMEvents.ClickOnPrivacyPolicy)
                  }
                >
                  <FormattedMessage id="privacy_policy" />
                </a>
                <a
                  className={styles.FooterLink}
                  href={COOKIE_PATH}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={COOKIE_PATH}
                  onClick={() =>
                    handleClickOnLink(GTMEvents.ClickOnCookiePolicy)
                  }
                >
                  <FormattedMessage id="GDPR.cookie_policy" />
                </a>
                <a
                  className={styles.FooterLink}
                  href={DATA_PRIVACY_PATH}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={DATA_PRIVACY_PATH}
                  onClick={() =>
                    handleClickOnLink(GTMEvents.ClickOnDataPrivacy)
                  }
                >
                  <FormattedMessage id="data_privacy_rights" />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
