import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { To, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Button } from 'antd';
import cs from 'classnames';

import Salary from 'components/Job/Salary/Salary';
import JobDetailsElement from 'components/JobDetailsHeader/JobDetailsElement/JobDetailsElement';
import LocationDisplay from 'components/LocationDisplay/LocationDisplay';

import { Job } from 'types/jobs';

import { Dictionaries } from 'constants/enums';
import { AMBASSADOR_JOBS, JOBS } from 'constants/pathNames';

import { ReactComponent as Arrow } from 'assets/icons/arrow-right.svg';

import styles from './JobDetailsHeader.module.scss';

interface JobDetailsHeaderProps {
  job: Job;
  isUserApplied: boolean;
  isLoading: boolean;
  onApply: () => void;
}

const JobDetailsHeader: FC<JobDetailsHeaderProps> = ({
  job,
  isUserApplied,
  isLoading,
  onApply
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const jobTypes = job.jobType?.join(', ') || '-';
  const jobWorkSchedule = job?.workSchedule || '-';

  const jobLocation =
    job.location && job.country.code ? (
      <LocationDisplay location={job.location} countryCode={job.country.code} />
    ) : (
      '-'
    );

  const buttonLabel = isUserApplied ? 'job.applied' : 'job.apply';

  const handleBackToJobsClick = (): To | void => {
    if (pathname === AMBASSADOR_JOBS) {
      return navigate(AMBASSADOR_JOBS);
    }
    return navigate(JOBS);
  };

  return (
    <section className={styles.container}>
      <div className={`${styles.infoContainer} container`}>
        <div onClick={handleBackToJobsClick} aria-hidden="true">
          <span className={styles.backButton}>
            <Arrow className={styles.backButtonArrow} />
            <FormattedMessage id="job.browse" />
          </span>
        </div>
        <div className={styles.titleContainer}>
          <h1>{job.title}</h1>
          <div>
            <Salary
              actualSalary={job.actualSalary}
              payrollRates={job.payrollRates}
              className={styles.salary}
            />
            <div className={styles.bottomLabel}>
              <FormattedMessage id="job.label.salary" />
            </div>
          </div>
        </div>
        <div className={styles.jobDetailsContainer}>
          <div className={styles.elementsContainer}>
            <JobDetailsElement label="job.label.location" value={jobLocation} />
            <JobDetailsElement
              dict={Dictionaries.Industry}
              label="job.label.industry"
              value={job.industry}
            />
            <JobDetailsElement
              dict={Dictionaries.JobType}
              label="job.label.contractType"
              value={jobTypes}
            />
            <JobDetailsElement
              label="job.label.workSchedule"
              value={jobWorkSchedule}
            />
          </div>
          <Button
            block
            disabled={isLoading || isUserApplied}
            type="default"
            className={cs('btn btn-default', styles.applyButton)}
            onClick={onApply}
          >
            <FormattedMessage id={buttonLabel} />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default JobDetailsHeader;
