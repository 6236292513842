import { ReactElement } from 'react';

import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';

import formatPDFData from 'utils/formatting/formatPDFData';

import { FormattedOption } from 'types/dictionaries';
import { Application } from 'types/jobApplications';
import { Job } from 'types/jobs';
import { TableRowProps } from 'types/pdf';

import { Dictionaries, PDFTitles } from 'constants/enums';

import RubikMedium from 'assets/fonts/rubik-medium.ttf';
import RubikRegular from 'assets/fonts/rubik-regular.ttf';

import Header from './Header/Header';
import TableRow from './TableRow/TableRow';

interface QuestionnairePDFProps {
  application: Partial<Application>;
  job: Partial<Job>;
  dictionaries: Record<Dictionaries, FormattedOption[]>;
}

const QuestionnairePDF = ({
  application,
  job,
  dictionaries
}: QuestionnairePDFProps): ReactElement => {
  const {
    availability,
    experience,
    location,
    legal,
    salaryExpectations,
    availabilityForInterview
  } = formatPDFData(application, job, dictionaries);

  const renderTable = (
    title: string,
    elements: TableRowProps[]
  ): ReactElement => (
    <View style={styles.table} wrap={false}>
      <Text style={styles.tableTitle}>{title}</Text>
      {elements.map((item: TableRowProps, order) => (
        <TableRow
          key={item.label}
          value={item.value}
          label={item.label}
          order={order}
          type={item.type}
          fullWidth={item?.fullWidth}
          show={item?.show}
        />
      ))}
    </View>
  );

  return (
    <Document>
      <Page style={styles.page}>
        <Header />
        {renderTable(PDFTitles.Availability, availability)}
        {renderTable(PDFTitles.Experience, experience)}
        {renderTable(PDFTitles.Location, location)}
        {renderTable(PDFTitles.Legal, legal)}
        {renderTable(PDFTitles.SalaryExpectations, salaryExpectations)}
        {renderTable(
          PDFTitles.AvailabilityForInterview,
          availabilityForInterview
        )}
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Rubik',
  fonts: [
    {
      src: RubikRegular
    },
    {
      src: RubikMedium,
      fontWeight: 'bold'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Rubik'
  },
  table: {
    marginHorizontal: 48,
    marginBottom: 36,
    borderBottom: '1px solid #E7E8EA'
  },
  tableTitle: {
    marginBottom: 12,
    fontSize: 16,
    lineHeight: 1.25,
    color: '#222222',
    fontWeight: 'bold'
  }
});

export default QuestionnairePDF;
