import { FC, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Button } from 'antd';

import BadgeIcon from 'components/BadgeIcon/BadgeIcon';
import PageCounter from 'components/PageCounter/PageCounter';
import SortDropdown from 'components/SortDropdown/SortDropdown';

import { selectFilters } from 'features/filters/filtersSelectors';

import { SortConfig } from 'types/sorting';

import { ReactComponent as FiltersIcon } from 'assets/icons/filters.svg';
import { ReactComponent as MessagesIcon } from 'assets/icons/messages.svg';

import styles from './PageControls.module.scss';

interface PageControlsProps {
  sortingConfig: SortConfig;
  numberOfElements: number;
  totalElements: number;
  hideFilterButton?: boolean;
}

const PageControls: FC<PageControlsProps> = ({
  sortingConfig,
  numberOfElements,
  totalElements,
  hideFilterButton = false
}): ReactElement => {
  const { page, pageSize, sortField } = useSelector(selectFilters);

  // TODO: dummy data for future Filter task
  const isFiltersSet = false;
  const isDisabled = false;
  const handleFiltersClick = () => {};

  return (
    <div className={styles.pageContainer}>
      <div className={styles.controlsContainer}>
        <div className={styles.count}>
          <PageCounter
            pageSize={pageSize}
            totalCount={totalElements}
            numberOfElements={numberOfElements}
            page={page}
          />
        </div>
        <SortDropdown config={sortingConfig} />
        {sortField === 'actualSalary' && (
          <div className={styles.text}>
            <FormattedMessage id="filter.salaryAdditionalText" />
          </div>
        )}
        <div className={styles.innerContainer}>
          <Button className={styles.button} onClick={handleFiltersClick}>
            <MessagesIcon />
          </Button>
          {!hideFilterButton && (
            <Button
              icon={
                <BadgeIcon showBadge={isFiltersSet} icon={<FiltersIcon />} />
              }
              className={styles.button}
              onClick={handleFiltersClick}
              disabled={isDisabled}
              type="link"
            >
              Filters
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageControls;
