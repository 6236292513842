import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import cs from 'classnames';

import { SECTION_ROW_GUTTER } from 'constants/common';
import { SUPPORT_EMAIL } from 'constants/common';
import { RESET_PASSWORD } from 'constants/pathNames';

import { ReactComponent as IconEmail } from 'assets/icons/email.svg';

import styles from './ChangeEmailPassword.module.scss';

interface ChangeEmailPasswordProps {
  email: string;
}

const TOOLTIP_OFFSET = [0, 15];

const ChangeEmailPassword: FC<ChangeEmailPasswordProps> = ({ email }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const setFormValue = (fieldName: string, fieldValue: string) =>
    form.setFieldValue(fieldName, fieldValue);

  const handlePasswordChange = () => {
    navigate(RESET_PASSWORD);
  };

  useEffect(() => {
    setFormValue('password', '123232323');
    setFormValue('email', email);
  }, [email]);

  return (
    <Form
      form={form}
      name="changeEmailAndPassword"
      className={cs('form-container', styles.changeEmailContainer)}
    >
      <Row gutter={SECTION_ROW_GUTTER}>
        <Col span={24} md={12}>
          <Tooltip
            title={intl.formatMessage({
              id: 'profile.changePassword.email.tooltip'
            })}
            placement="top"
            trigger="hover"
            align={{
              offset: TOOLTIP_OFFSET
            }}
          >
            <Form.Item
              label={intl.formatMessage({ id: 'form.email' })}
              name="email"
            >
              <Input suffix={<IconEmail className="icon" />} disabled />
            </Form.Item>
            <Button
              type="link"
              className={styles.inputLink}
              href={`mailto:${SUPPORT_EMAIL}`}
            >
              {intl.formatMessage({ id: 'profile.changePassword.mail.link' })}
            </Button>
          </Tooltip>
        </Col>
        <Col span={24} md={12}>
          <Tooltip
            title={intl.formatMessage({
              id: 'profile.changePassword.password.tooltip'
            })}
            placement="top"
            trigger="hover"
            align={{
              offset: TOOLTIP_OFFSET
            }}
          >
            <Form.Item
              name="password"
              label={intl.formatMessage({ id: 'form.passwordShort' })}
            >
              <Input.Password disabled />
            </Form.Item>
            <Button
              type="link"
              className={styles.inputLink}
              onClick={handlePasswordChange}
            >
              {intl.formatMessage({
                id: 'profile.changePassword.password.link'
              })}
            </Button>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  );
};

export default ChangeEmailPassword;
