import { UploadFile } from 'antd';
import { FormInstance } from 'antd/lib/form';
import dayjs from 'dayjs';

import { Attachment } from 'types/attachments';
import { Application } from 'types/jobApplications';

import { UsersAttachmentTypes } from 'constants/enums';

export const getExistingResume = (
  attachments: Attachment<UsersAttachmentTypes>[]
): UploadFile | null => {
  if (!attachments?.length) return null;

  const cvAttachments = attachments.filter(
    (attachment) => attachment.type === 'CV'
  );

  if (!cvAttachments.length) return null;

  const lastCvAttachment = cvAttachments[cvAttachments.length - 1];

  return {
    uid: lastCvAttachment.id,
    name: lastCvAttachment.filename,
    status: 'done',
    url: lastCvAttachment.url
  } as UploadFile;
};

export const setInitialFormValues = (
  form: FormInstance,
  application: Application
): void => {
  if (!application) return;

  form.setFieldsValue({
    startDate: application.preferredStartDate
      ? dayjs(application.preferredStartDate)
      : undefined,
    yearsOfIndustryExperience: application.yearsOfIndustryExperience,
    commuting: application.commuting,
    workAuthorization: application.workAuthorization,
    okWithClientSalary: application.okWithClientSalary,
    salaryExpectation: application.salaryExpectation,
    clientInterviewAvailability: application.clientInterviewAvailability,
    alternateInterviewDate: application.alternateInterviewDate
      ? dayjs(application.alternateInterviewDate)
      : undefined
  });
};
