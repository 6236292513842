import { ReactElement, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'antd';

import { PRIVACY_PATH, TERMS_PATH } from 'constants/common';

import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = (): ReactElement => {
  const privacyLink = (
    <Button
      type="link"
      className={styles.resetButton}
      href={TERMS_PATH}
      target="_blank"
    >
      <FormattedMessage id="terms_of_service" />
    </Button>
  );

  const termsLink = (
    <Button
      type="link"
      className={styles.resetButton}
      href={PRIVACY_PATH}
      target="_blank"
    >
      <FormattedMessage id="privacy_policy" />
    </Button>
  );

  return (
    <p>
      <FormattedMessage
        id="terms_and_policy_text"
        values={{ terms: termsLink, policy: privacyLink }}
      />
    </p>
  );
};

export default TermsAndConditions;
