import { createAsyncThunk } from '@reduxjs/toolkit';

import AttachmentsService from './attachmentsService';

export const getUsersDocuments = createAsyncThunk(
  'attachments/getUsersDocuments',
  async (_, { rejectWithValue }) => {
    try {
      const response = await AttachmentsService.getUsersDocuments();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
