import { FC } from 'react';

import cs from 'classnames';

import jobApplicationStatusMap from 'components/LoggedUserApplications/utils/jobApplicationStatusMap';

import { JobApplicationStatus } from 'constants/enums';

import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as DeclinedIcon } from 'assets/icons/close-square.svg';
import { ReactComponent as HiredIcon } from 'assets/icons/emoji-happy.svg';
import { ReactComponent as NewIcon } from 'assets/icons/notification.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick-square.svg';
import { ReactComponent as InReviewIcon } from 'assets/icons/user-search.svg';

import styles from './ApplicationStatus.module.scss';

const statusClassNames: Record<string, string> = {
  [JobApplicationStatus.New]: styles.new,
  [JobApplicationStatus.Pending]: styles.pending,
  [JobApplicationStatus.InReview]: styles.inReview,
  [JobApplicationStatus.Accepted]: styles.accepted,
  [JobApplicationStatus.Declined]: styles.declined,
  [JobApplicationStatus.Hired]: styles.hired,
  [JobApplicationStatus.Completed]: styles.completed
};

const statusIcons: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  [JobApplicationStatus.New]: NewIcon,
  [JobApplicationStatus.Pending]: ClockIcon,
  [JobApplicationStatus.InReview]: InReviewIcon,
  [JobApplicationStatus.Accepted]: TickIcon,
  [JobApplicationStatus.Declined]: DeclinedIcon,
  [JobApplicationStatus.Hired]: HiredIcon,
  [JobApplicationStatus.Completed]: TickIcon
};

interface ApplicationStatusProps {
  status: JobApplicationStatus;
}

const ApplicationStatus: FC<ApplicationStatusProps> = ({ status }) => {
  const IconComponent = statusIcons[status];

  return (
    <div className={cs(styles.statusContainer, statusClassNames[status])}>
      {<IconComponent className={styles.statusIcon} />}
      {jobApplicationStatusMap[status]}
    </div>
  );
};

export default ApplicationStatus;
