import { ReactElement } from 'react';

import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import cs from 'classnames';

import styles from './Button.module.scss';

type ButtonProps = AntdButtonProps & { isRightIconPosition?: boolean };

const Button = ({
  children,
  type,
  isRightIconPosition = false,
  ...rest
}: ButtonProps): ReactElement[] | ReactElement => {
  return (
    <AntdButton
      {...rest}
      className={cs({
        [styles.btnDefault]: type === 'default',
        [styles.btnPrimary]: type === 'primary',
        [styles.iconRightPosition]: isRightIconPosition
      })}
    >
      {children}
    </AntdButton>
  );
};

export default Button;
