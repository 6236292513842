import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import UserService from 'services/userService';

import { AppDispatch } from 'app/store';

import Alert from 'components/Alert/Alert';
import LoadingIndicator from 'components/LoadingIndicator';
import ChangeEmailPassword from 'components/LoggedUserProfile/PersonalInformation/ChangeEmailPassword/ChangeEmailPassword';
import PersonalInformationForm from 'components/LoggedUserProfile/PersonalInformation/PersonalInformationForm';
import ProfileAvatar from 'components/ProfileAvatar/ProfileAvatar';

import {
  selectIsJobsLoading,
  selectProfile
} from 'features/profile/profileSelectors';
import { fetchProfileData } from 'features/profile/profileThunks';

import useErrorCatch from 'hooks/useErrorCatch';

import { calculateProfileProgress } from 'utils/calculateProfileProgress';
import { notify } from 'utils/toast/createToast';

import { Jobseeker } from 'types/jobseekers';

import { AlertType } from 'constants/enums';

import { ReactComponent as ProfileCircle } from 'assets/icons/profile-circle.svg';

import styles from '../styles.module.scss';

const PersonalInformation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectProfile);
  const isUserLoading = useSelector(selectIsJobsLoading);
  const catchError = useErrorCatch();
  const [progress, setProgress] = useState(0);

  const userFullName = `${user.firstName} ${user.lastName}`;

  useEffect(() => {
    if (!isUserLoading && user) {
      const calculatedProgress = calculateProfileProgress(user);
      setProgress(calculatedProgress);
    }
  }, [user, isUserLoading]);

  const isProfileComplete = progress === 100;

  const handleSubmitForm = async (
    fields: Partial<Jobseeker>
  ): Promise<void> => {
    try {
      await UserService.updateProfile(fields);
      await dispatch(fetchProfileData()).unwrap();

      notify({
        type: AlertType.Success,
        message: 'Profile saved'
      });
    } catch (error) {
      catchError({
        error,
        default: {
          message: "Can't save profile",
          description: 'Please try again later'
        }
      });
    }
  };

  return (
    <div className="container">
      {isUserLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ProfileAvatar
            profileProgress={progress}
            source={<ProfileCircle />}
            name={userFullName}
          />
          {!isProfileComplete && (
            <Alert type={AlertType.Warning} className={styles.infoMessage}>
              <FormattedMessage
                id="profile.infoMessage"
                values={{
                  progress
                }}
              />
            </Alert>
          )}
          <ChangeEmailPassword email={user.email || ''} />
          <PersonalInformationForm
            user={user}
            loading={isUserLoading}
            handleFormSubmit={handleSubmitForm}
          />
        </>
      )}
    </div>
  );
};

export default PersonalInformation;
