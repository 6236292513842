import { MimeTypes } from 'constants/enums';

import { getFile } from './api';

const fetchFileWithMimeType = async (
  url: string
): Promise<{ blob: Blob; fileType: MimeTypes | undefined }> => {
  try {
    const response = await getFile(url);

    const contentType = response?.headers?.get('Content-Type');

    const fileType =
      Object.values(MimeTypes).find((type) => type === contentType) ||
      undefined;

    const resBlob = await response.blob();

    const blob = new Blob([resBlob], { type: fileType });

    return { blob, fileType };
  } catch (error) {
    console.error('Failed to load the file', error);
    throw error;
  }
};

export default fetchFileWithMimeType;
