import { Attachment, AttachmentTypes } from 'types/attachments';

import {
  FORCED_SHOW_THUMBNAILS_FILE_TYPES,
  PREVIEW_FILE_TYPES
} from 'constants/attachmentsSettings';

const isAttachmentToDownload = (
  attachment: Attachment<AttachmentTypes>
): boolean => {
  return (
    !PREVIEW_FILE_TYPES.includes(attachment.fileType) ||
    // Handle case if PDF Thumbnails generation is broken
    (FORCED_SHOW_THUMBNAILS_FILE_TYPES.includes(attachment.fileType) &&
      !(attachment?.thumbnails && attachment.thumbnails[0]))
  );
};

export { isAttachmentToDownload };
