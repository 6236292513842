import { ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { selectIsLoggedIn } from 'features/auth/authSelectors';

import { useAppSelector } from 'hooks/useTypedRedux';

import { notify } from 'utils/toast/createToast';

import { AlertType, RedirectTypes } from 'constants/enums';
import { MAIN } from 'constants/pathNames';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): ReactNode => {
  const isAuthenticated = useAppSelector(selectIsLoggedIn);
  const location = useLocation();
  const isLogoutRedirect = location.state?.from === RedirectTypes.Logout;

  useEffect(() => {
    if (!isAuthenticated && !isLogoutRedirect) {
      notify({
        type: AlertType.Info,
        description:
          'You must be logged in to access this page. Please login to continue.'
      });
    }
  }, [isAuthenticated, isLogoutRedirect]);

  if (!isAuthenticated && !isLogoutRedirect) {
    return <Navigate to={MAIN} state={{ from: location.pathname }} />;
  }

  return children;
};

export default ProtectedRoute;
