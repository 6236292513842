import { RootState } from 'app/store';

import { LoggedUser } from 'types/commons';

export const selectAuthToken = (state: RootState): string | null =>
  state.auth.token;
export const selectIsLoggedIn = (state: RootState): boolean =>
  state.auth.isLoggedIn;
export const selectUser = (state: RootState): LoggedUser => state.auth.user;
export const selectUserId = (state: RootState): string =>
  state.auth.user.userId;
