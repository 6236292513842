import { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import { App as AntApp } from 'antd';
import 'styles/Main.scss';

import store from 'app/store';

import packageJson from '../package.json';
import App from './App';

export const buildVersion = packageJson;

const container = document.getElementById('root');
container?.setAttribute('data-version', packageJson.version);
if (!container) throw new Error('container not found!');

const root = createRoot(container);

const Main = (): ReactElement => (
  <Provider store={store}>
    <AntApp>
      <App />
      <ToastContainer
        toastClassName="toast"
        position="top-right"
        closeButton={false}
      />
    </AntApp>
  </Provider>
);

root.render(<Main />);
