import { FC, ReactNode, useEffect, useState } from 'react';

import { Progress } from 'antd';
import type { ProgressProps } from 'antd';
import cs from 'classnames';

import styles from './ProgressBar.module.scss';

interface ProgressBarProps extends Omit<ProgressProps, 'percent'> {
  progress: number;
  strokeColor?: ProgressProps['strokeColor'];
  size?: number;
  type?: ProgressProps['type'];
  hideProgress?: boolean;
  className?: string;
  children?: ReactNode;
}

const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  strokeColor,
  size = 54,
  type = 'dashboard',
  hideProgress = false,
  className,
  children,
  ...rest
}) => {
  return (
    <div className={styles.container}>
      <Progress
        percent={progress}
        strokeColor={strokeColor}
        strokeWidth={12}
        showInfo={false}
        type={type}
        size={size}
        className={cs(className)}
        {...rest}
      />
      {!hideProgress && !children && (
        <span className={styles.value}>{progress}%</span>
      )}
      {children}
    </div>
  );
};

export default ProgressBar;
