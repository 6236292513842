import { FC } from 'react';

import SidebarTabs from 'components/SidebarTabs/SidebarTabs';

import { SidebarTabsItem } from 'types/sidebar';

import NotificationsArchive from '../NotificationsArchive/NotificationsArchive';
import NotificationsView from '../NotificationsView/NotificationsView';

const items: SidebarTabsItem[] = [
  {
    id: 1,
    title: 'Unread',
    component: <NotificationsView />
  },
  {
    id: 2,
    title: 'Archive',
    component: <NotificationsArchive />
  }
];

const NotificationsSidebar: FC = () => {
  return <SidebarTabs items={items} />;
};

export default NotificationsSidebar;
