import { useEffect } from 'react';

import { useNotifications } from 'components/Notifications/hooks/useNotifications';

import { selectUnreadNotifications } from 'features/notifications/notificationsSelectors';

import { useAppSelector } from 'hooks/useTypedRedux';

import NotificationCard from '../NotificationCard/NotificationCard';
import styles from './NotificationsView.module.scss';

const NotificationsView = (): JSX.Element => {
  const unreadNotifications = useAppSelector(selectUnreadNotifications);

  const { loadUnreadNotifications, readAllNotifications, readNotification } =
    useNotifications();

  useEffect(() => {
    loadUnreadNotifications();
  }, [loadUnreadNotifications]);

  const isEmpty = !unreadNotifications?.length;

  return (
    <aside>
      <div className={styles.top}>
        <div className={styles.title}>Notifications</div>
        {!isEmpty && (
          <button className={styles.readBtn} onClick={readAllNotifications}>
            Read all
          </button>
        )}
      </div>

      {isEmpty ? (
        <div className={styles.empty}>Empty</div>
      ) : (
        <div className={styles.list}>
          {unreadNotifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
              onReadNotification={readNotification}
            />
          ))}
        </div>
      )}
    </aside>
  );
};

export default NotificationsView;
