import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { MenuProps } from 'antd';

import { AppDispatch } from 'app/store';

import LabelWithTooltip from 'components/LabelWithTooltip/LabelWithTooltip';

import {
  setPage,
  setSortField,
  setSortOrder
} from 'features/filters/filtersSlice';

import { scrollToTop } from 'utils/scrollToTop';

import { SortOrders } from 'types/filters';
import { SortConfig } from 'types/sorting';

import { FIRST_PAGE_INDEX } from 'constants/common';

const useSortOptions = (config: SortConfig) => {
  const dispatch: AppDispatch = useDispatch();
  const [sortOption, setSortOption] = useState<string>(config.defaultOption);

  const items: MenuProps['items'] = useMemo(() => {
    return Object.keys(config.options).map((key) => ({
      key,
      label: <LabelWithTooltip labelKey={key} label={config.labels[key]} />
    }));
  }, [config]);

  const currentLabel = useMemo(() => {
    const currentItem = items.find((item) => item?.key === sortOption);
    // @ts-ignore
    return currentItem?.label || '';
  }, [items, sortOption]);

  const handleMenuClick = useCallback(
    (e: { key: string }) => {
      const selectedOption = config.options[e.key];
      if (selectedOption) {
        setSortOption(e.key);
        dispatch(setSortField(selectedOption.field));
        dispatch(setSortOrder(selectedOption.order as SortOrders));
        dispatch(setPage(FIRST_PAGE_INDEX));
        scrollToTop();
      }
    },
    [dispatch, config]
  );

  useEffect(() => {
    handleMenuClick({ key: config.defaultOption });
  }, [config, handleMenuClick]);

  return {
    items,
    handleMenuClick,
    currentLabel,
    sortOption,
    value: {
      sortField: config.options[sortOption]?.field,
      order: config.options[sortOption]?.order
    }
  };
};

export { useSortOptions };
