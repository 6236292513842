import { FC, ReactElement } from 'react';

import { Col } from 'antd';

export interface ColumnConfig {
  lg?: number;
  md?: number;
  xs?: number;
  className?: string;
}

export const DEFAULT_COLUMN_CONFIG: ColumnConfig = {
  lg: 6,
  md: 8,
  xs: 24,
  className: 'table-card-item'
};

export interface TableColumnProps {
  title: string | ReactElement;
  label: string;
  flex?: string | number;
  columnConfig?: Partial<ColumnConfig>;
}

const TableColumn: FC<TableColumnProps> = ({
  title,
  label,
  flex,
  columnConfig
}) => {
  const finalConfig = {
    ...DEFAULT_COLUMN_CONFIG,
    ...columnConfig
  };

  return (
    <Col {...(flex ? { flex } : finalConfig)} className="table-card-item">
      <div className="table-card-title">{title}</div>
      <div className="table-card-item-label">{label}</div>
    </Col>
  );
};

export default TableColumn;
