import { ReactElement, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';

import { selectIsLoggedIn } from 'features/auth/authSelectors';
import { selectFilters } from 'features/filters/filtersSelectors';
import { setClearFilters } from 'features/filters/filtersSlice';

import { GTMEventLogger } from 'utils/analytics';

import { AlertType, GTMEvents } from 'constants/enums';
import { INTAKE } from 'constants/pathNames';

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg';
import { ReactComponent as RotateLeft } from 'assets/icons/rotateLeft.svg';
import { noDataImage } from 'assets/images/noDataImage';

import styles from './NoJobsAvailable.module.scss';

const NoJobsAvailable = (): ReactElement[] | ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { industry } = useSelector(selectFilters);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleClearAll = useCallback(() => {
    GTMEventLogger(GTMEvents.ClickOnClearFilter);
    dispatch(setClearFilters());
  }, [dispatch]);

  const handleMoveToIntakePage = (): void => {
    navigate(INTAKE, { state: { industry } });
  };

  return (
    <div className={styles.wrapper}>
      <Lottie
        animationData={noDataImage}
        play
        loop={false}
        style={{ width: '250px', height: '150px' }}
      />
      <h1 className={styles.header}>
        <FormattedMessage id="job.noAvailable" />
      </h1>
      {isLoggedIn && (
        <div className={styles.alertWrapper}>
          <Alert type={AlertType.Warning}>
            <FormattedMessage id="job.noAvailableWarningMessage" />
          </Alert>
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        <Button type="default" icon={<RotateLeft />} onClick={handleClearAll}>
          <FormattedMessage id="button.resetFilters" />
        </Button>
        {isLoggedIn && (
          <Button
            type="primary"
            icon={<ArrowRight />}
            isRightIconPosition
            onClick={handleMoveToIntakePage}
          >
            <FormattedMessage id="button.leaveYourInformation" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default NoJobsAvailable;
