import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Attachment } from 'types/attachments';

import { UsersAttachmentTypes } from 'constants/enums';

import { getUsersDocuments } from './attachmentsThunks';

interface AttachmentsState {
  loadingAttachmentId: string | null;
  usersDocuments: Attachment<UsersAttachmentTypes>[];
}

const initialState: AttachmentsState = {
  loadingAttachmentId: null,
  usersDocuments: []
};

const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    setLoadingAttachmentId: (
      state: AttachmentsState,
      action: PayloadAction<string | null>
    ) => {
      state.loadingAttachmentId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersDocuments.fulfilled, (state, { payload }) => {
      state.usersDocuments = payload;
    });
  }
});

export const { setLoadingAttachmentId } = attachmentsSlice.actions;

export default attachmentsSlice.reducer;
