export const noDataImage = {
  nm: 'Flow 5',
  ddd: 0,
  h: 150,
  w: 238,
  meta: { g: 'LottieFiles Figma v75' },
  layers: [
    {
      ty: 0,
      nm: 'form',
      sr: 1,
      st: 0,
      op: 170.04,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [119, 75] },
        s: { a: 0, k: [100, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [119, 75] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 }
      },
      w: 238,
      h: 150,
      refId: '1',
      ind: 1
    }
  ],
  v: '5.7.0',
  fr: 60,
  op: 169.04,
  ip: 0,
  assets: [
    {
      nm: '[Asset] form',
      id: '1',
      layers: [
        {
          ty: 0,
          nm: 'Frame',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: true,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 58.63],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 56.9],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [86, 57],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [86, 57],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [86, 57],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 39],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 39],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 166.96170341968536
                },
                { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [86, 39], t: 169 },
                { s: [86, 39], t: 169.44255512952805 }
              ]
            },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [119, 75],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [119, 75],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [119, 55.37],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [119, 57.1],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [119, 57],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [119, 57],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [119, 57],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [119, 75],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [119, 75],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [119, 75],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [119, 75],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [119, 75],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [119, 75],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [119, 75],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [119, 75],
                  t: 166.96170341968536
                },
                { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [119, 75], t: 169 },
                { s: [119, 75], t: 169.44255512952805 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100 }
          },
          masksProperties: [
            {
              nm: '',
              inv: false,
              mode: 'a',
              x: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              pt: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 117.27],
                          [0, 117.27],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 113.81],
                          [0, 113.81],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 114],
                          [0, 114],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 114],
                          [0, 114],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 114],
                          [0, 114],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 162
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 164.48085170984268
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 166.96170341968536
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 169
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 169.44255512952805
                  }
                ]
              }
            }
          ],
          w: 238,
          h: 150,
          refId: '2',
          ind: 1
        },
        {
          ty: 4,
          nm: 'form Bg',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [119, 75] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [119, 75] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100 }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, -4.42],
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0]
                        ],
                        v: [
                          [238, 8],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8],
                          [8, 0],
                          [230, 0]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, -4.42],
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0]
                        ],
                        v: [
                          [238, 8],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8],
                          [8, 0],
                          [230, 0]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 162
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 164.48085170984268
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 166.96170341968536
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, -4.42],
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 4.42],
                          [0, 0],
                          [-4.42, 0],
                          [0, 0],
                          [0, -4.42],
                          [0, 0],
                          [4.42, 0]
                        ],
                        v: [
                          [238, 8],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8],
                          [8, 0],
                          [230, 0]
                        ]
                      }
                    ],
                    t: 169
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0]
                        ],
                        o: [
                          [0, -4.41828],
                          [0, 0],
                          [4.418000000000006, 0],
                          [0, 0],
                          [0, 4.418000000000006],
                          [0, 0],
                          [-4.41828, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 8],
                          [8, 0],
                          [230, 0],
                          [238, 8],
                          [238, 142],
                          [230, 150],
                          [8, 150],
                          [0, 142],
                          [0, 8]
                        ]
                      }
                    ],
                    t: 169.44255512952805
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [1, 1, 1] },
              r: 1,
              o: { a: 0, k: 0 }
            }
          ],
          ind: 2
        }
      ]
    },
    {
      nm: '[Asset] Frame',
      id: '2',
      layers: [
        {
          ty: 4,
          nm: 'Ellipse 749',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [5, 5] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [146, 98],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [146, 98],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [111.1, 44.56],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [114.17, 49.26],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [114, 49],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [114, 49],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [114, 49],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [114, 20],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [114, 20],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [114, 20],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [114, 20],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [114, 20],
                  t: 161.8851102590561
                },
                { s: [112, 18], t: 169 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [100],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [100],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [100],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [100],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [100],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [100],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [100],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [0],
                  t: 161.8851102590561
                },
                { s: [100], t: 169 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76, 0],
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76]
                        ],
                        o: [
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76],
                          [2.76, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76, 0],
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76]
                        ],
                        o: [
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76],
                          [2.76, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76, 0],
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76]
                        ],
                        o: [
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76],
                          [2.76, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 169
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [1, 1, 1] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 1
        },
        {
          ty: 4,
          nm: 'Ellipse 748',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [5, 5] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [43, 98],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [43, 98],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [58.27, 44.56],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [56.92, 49.26],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [57, 49],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [57, 49],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [57, 49],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [56, 20],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [56, 20],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [56, 20],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [56, 20],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [56, 20],
                  t: 161.8851102590561
                },
                { s: [58, 18], t: 169 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [100],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [100],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [100],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [100],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [100],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [100],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [100],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [0],
                  t: 161.8851102590561
                },
                { s: [100], t: 169 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76, 0],
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76]
                        ],
                        o: [
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76],
                          [2.76, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76, 0],
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76]
                        ],
                        o: [
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76],
                          [2.76, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76142, 0],
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142]
                        ],
                        o: [
                          [0, 2.76142],
                          [-2.76142, 0],
                          [0, -2.76142],
                          [2.76142, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [2.76, 0],
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76]
                        ],
                        o: [
                          [0, 2.76],
                          [-2.76, 0],
                          [0, -2.76],
                          [2.76, 0],
                          [0, 0]
                        ],
                        v: [
                          [10, 5],
                          [5, 10],
                          [0, 5],
                          [5, 0],
                          [10, 5]
                        ]
                      }
                    ],
                    t: 169
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [1, 1, 1] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 2
        },
        {
          ty: 4,
          nm: 'Vector13',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [14.35, 13.07],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.07],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.07],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [14.35, 13.07],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [14.35, 13.07],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [14.51, 10.27],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [14.5, 10.51],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [14.5, 10.5],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.5, 10.5],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [14.34, 13.3],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.05],
                  t: 166.96170341968536
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.07],
                  t: 169
                },
                { s: [14.35, 13.07], t: 169.44255512952805 }
              ]
            },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [149.35, 98.07],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [149.35, 98.07],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [112.27, 44.62],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [115.54, 49.33],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [115.35, 49.06],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [115.35, 49.07],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [115.35, 49.07],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [115.35, 18.07],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [115.35, 18.07],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [115.35, 18.68],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [115.35, 18.63],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [115.35, 18.63],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [115.35, 18.63],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [115.35, 18.02],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [115.35, 18.07],
                  t: 166.96170341968536
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1, y: 1 },
                  s: [115.35, 18.07],
                  t: 169
                },
                { s: [115.35, 18.07], t: 169.44255512952805 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100 }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.18],
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0]
                        ],
                        o: [
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0],
                          [0, 8.18],
                          [0, 0]
                        ],
                        v: [
                          [14.35, 26.13],
                          [28.7, 13.07],
                          [14.35, 0],
                          [0, 13.07],
                          [14.35, 26.13]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.18],
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0]
                        ],
                        o: [
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0],
                          [0, 8.18],
                          [0, 0]
                        ],
                        v: [
                          [14.35, 26.13],
                          [28.7, 13.07],
                          [14.35, 0],
                          [0, 13.07],
                          [14.35, 26.13]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.423763009999998],
                          [7.83634693, 0],
                          [0, -6.423695754000001],
                          [-7.836393278999999, 0]
                        ],
                        o: [
                          [7.83634693, 0],
                          [0, -6.423695754000001],
                          [-7.836393278999999, 0],
                          [0, 6.423763009999998],
                          [0, 0]
                        ],
                        v: [
                          [14.51343267, 20.53442783],
                          [29.02686534, 10.27131868],
                          [14.51343267, 0],
                          [0, 10.27131868],
                          [14.51343267, 20.53442783]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.578070779999998],
                          [7.828668540000001, 0],
                          [0, -6.578011212],
                          [-7.828708162, 0]
                        ],
                        o: [
                          [7.828668540000001, 0],
                          [0, -6.578011212],
                          [-7.828708162, 0],
                          [0, 6.578070779999998],
                          [0, 0]
                        ],
                        v: [
                          [14.49920026, 21.02771874],
                          [28.99840052, 10.51806504],
                          [14.49920026, 0],
                          [0, 10.51806504],
                          [14.49920026, 21.02771874]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.569239429999998],
                          [7.829107990000001, 0],
                          [0, -6.569179422],
                          [-7.829147997, 0]
                        ],
                        o: [
                          [7.829107990000001, 0],
                          [0, -6.569179422],
                          [-7.829147997, 0],
                          [0, 6.569239429999998],
                          [0, 0]
                        ],
                        v: [
                          [14.50001481, 20.99948669],
                          [29.00002962, 10.503943240000002],
                          [14.50001481, 0],
                          [0, 10.503943240000002],
                          [14.50001481, 20.99948669]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.569399999999998],
                          [7.8291, 0],
                          [0, -6.56934],
                          [-7.82914, 0]
                        ],
                        o: [
                          [7.8291, 0],
                          [0, -6.56934],
                          [-7.82914, 0],
                          [0, 6.569399999999998],
                          [0, 0]
                        ],
                        v: [
                          [14.5, 21],
                          [29, 10.5042],
                          [14.5, 0],
                          [0, 10.5042],
                          [14.5, 21]
                        ]
                      }
                    ],
                    t: 162
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.32073699],
                          [7.741953069999998, 0],
                          [0, -8.320764246],
                          [-7.741916721000001, 0]
                        ],
                        o: [
                          [7.741953069999998, 0],
                          [0, -8.320764246],
                          [-7.741916721000001, 0],
                          [0, 8.32073699],
                          [0, 0]
                        ],
                        v: [
                          [14.33846733, 26.59867217],
                          [28.67693466, 13.30468132],
                          [14.33846733, 0],
                          [0, 13.30468132],
                          [14.33846733, 26.59867217]
                        ]
                      }
                    ],
                    t: 164.48085170984268
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.166429220000001],
                          [7.749631459999998, 0],
                          [0, -8.166448788],
                          [-7.749601838, 0]
                        ],
                        o: [
                          [7.749631459999998, 0],
                          [0, -8.166448788],
                          [-7.749601838, 0],
                          [0, 8.166429220000001],
                          [0, 0]
                        ],
                        v: [
                          [14.35269974, 26.105381259999998],
                          [28.70539948, 13.05793496],
                          [14.35269974, 0],
                          [0, 13.05793496],
                          [14.35269974, 26.105381259999998]
                        ]
                      }
                    ],
                    t: 166.96170341968536
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.18],
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0]
                        ],
                        o: [
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0],
                          [0, 8.18],
                          [0, 0]
                        ],
                        v: [
                          [14.35, 26.13],
                          [28.7, 13.07],
                          [14.35, 0],
                          [0, 13.07],
                          [14.35, 26.13]
                        ]
                      }
                    ],
                    t: 169
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.17526057],
                          [7.749192009999998, 0],
                          [0, -8.175280577999999],
                          [-7.749162003, 0]
                        ],
                        o: [
                          [7.749192009999998, 0],
                          [0, -8.175280577999999],
                          [-7.749162003, 0],
                          [0, 8.17526057],
                          [0, 0]
                        ],
                        v: [
                          [14.35188519, 26.133613309999998],
                          [28.70377038, 13.072056759999999],
                          [14.35188519, 0],
                          [0, 13.072056759999999],
                          [14.35188519, 26.133613309999998]
                        ]
                      }
                    ],
                    t: 169.44255512952805
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 3
        },
        {
          ty: 4,
          nm: 'Vector12',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [14.35, 13.07],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.07],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.07],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [14.35, 13.07],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [14.35, 13.07],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.35, 13.07],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [14.51, 10.27],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [14.5, 10.51],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [14.5, 10.5],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [14.5, 10.5],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [14.34, 13.3],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.05],
                  t: 166.96170341968536
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1, y: 1 },
                  s: [14.35, 13.07],
                  t: 169
                },
                { s: [14.35, 13.07], t: 169.44255512952805 }
              ]
            },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [41.35, 98.07],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [41.35, 98.07],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [57.71, 44.62],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [56.27, 49.33],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [56.35, 49.06],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [56.35, 49.07],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [56.35, 49.07],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [56.35, 18.07],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [56.35, 18.07],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [56.35, 18.68],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [56.35, 18.63],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [56.35, 18.63],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [56.35, 18.63],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [56.35, 18.02],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [56.35, 18.07],
                  t: 166.96170341968536
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1, y: 1 },
                  s: [56.35, 18.07],
                  t: 169
                },
                { s: [56.35, 18.07], t: 169.44255512952805 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100 }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.18],
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0]
                        ],
                        o: [
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0],
                          [0, 8.18],
                          [0, 0]
                        ],
                        v: [
                          [14.35, 26.13],
                          [28.7, 13.07],
                          [14.35, 0],
                          [0, 13.07],
                          [14.35, 26.13]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.18],
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0]
                        ],
                        o: [
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0],
                          [0, 8.18],
                          [0, 0]
                        ],
                        v: [
                          [14.35, 26.13],
                          [28.7, 13.07],
                          [14.35, 0],
                          [0, 13.07],
                          [14.35, 26.13]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.1751],
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0]
                        ],
                        o: [
                          [7.749199999999998, 0],
                          [0, -8.17512],
                          [-7.74917, 0],
                          [0, 8.1751],
                          [0, 0]
                        ],
                        v: [
                          [14.3519, 26.1331],
                          [28.7038, 13.0718],
                          [14.3519, 0],
                          [0, 13.0718],
                          [14.3519, 26.1331]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.423763009999998],
                          [7.83634693, 0],
                          [0, -6.423695754000001],
                          [-7.836393278999999, 0]
                        ],
                        o: [
                          [7.83634693, 0],
                          [0, -6.423695754000001],
                          [-7.836393278999999, 0],
                          [0, 6.423763009999998],
                          [0, 0]
                        ],
                        v: [
                          [14.51343267, 20.53442783],
                          [29.02686534, 10.27131868],
                          [14.51343267, 0],
                          [0, 10.27131868],
                          [14.51343267, 20.53442783]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.578070779999998],
                          [7.828668540000001, 0],
                          [0, -6.578011212],
                          [-7.828708162, 0]
                        ],
                        o: [
                          [7.828668540000001, 0],
                          [0, -6.578011212],
                          [-7.828708162, 0],
                          [0, 6.578070779999998],
                          [0, 0]
                        ],
                        v: [
                          [14.49920026, 21.02771874],
                          [28.99840052, 10.51806504],
                          [14.49920026, 0],
                          [0, 10.51806504],
                          [14.49920026, 21.02771874]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.569239429999998],
                          [7.829107990000001, 0],
                          [0, -6.569179422],
                          [-7.829147997, 0]
                        ],
                        o: [
                          [7.829107990000001, 0],
                          [0, -6.569179422],
                          [-7.829147997, 0],
                          [0, 6.569239429999998],
                          [0, 0]
                        ],
                        v: [
                          [14.50001481, 20.99948669],
                          [29.00002962, 10.503943240000002],
                          [14.50001481, 0],
                          [0, 10.503943240000002],
                          [14.50001481, 20.99948669]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 6.569399999999998],
                          [7.8291, 0],
                          [0, -6.56934],
                          [-7.82914, 0]
                        ],
                        o: [
                          [7.8291, 0],
                          [0, -6.56934],
                          [-7.82914, 0],
                          [0, 6.569399999999998],
                          [0, 0]
                        ],
                        v: [
                          [14.5, 21],
                          [29, 10.5042],
                          [14.5, 0],
                          [0, 10.5042],
                          [14.5, 21]
                        ]
                      }
                    ],
                    t: 162
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.32073699],
                          [7.741953069999998, 0],
                          [0, -8.320764246],
                          [-7.741916721000001, 0]
                        ],
                        o: [
                          [7.741953069999998, 0],
                          [0, -8.320764246],
                          [-7.741916721000001, 0],
                          [0, 8.32073699],
                          [0, 0]
                        ],
                        v: [
                          [14.33846733, 26.59867217],
                          [28.67693466, 13.30468132],
                          [14.33846733, 0],
                          [0, 13.30468132],
                          [14.33846733, 26.59867217]
                        ]
                      }
                    ],
                    t: 164.48085170984268
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.166429220000001],
                          [7.749631459999998, 0],
                          [0, -8.166448788],
                          [-7.749601838, 0]
                        ],
                        o: [
                          [7.749631459999998, 0],
                          [0, -8.166448788],
                          [-7.749601838, 0],
                          [0, 8.166429220000001],
                          [0, 0]
                        ],
                        v: [
                          [14.35269974, 26.105381259999998],
                          [28.70539948, 13.05793496],
                          [14.35269974, 0],
                          [0, 13.05793496],
                          [14.35269974, 26.105381259999998]
                        ]
                      }
                    ],
                    t: 166.96170341968536
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.18],
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0]
                        ],
                        o: [
                          [7.75, 0],
                          [0, -8.18],
                          [-7.75, 0],
                          [0, 8.18],
                          [0, 0]
                        ],
                        v: [
                          [14.35, 26.13],
                          [28.7, 13.07],
                          [14.35, 0],
                          [0, 13.07],
                          [14.35, 26.13]
                        ]
                      }
                    ],
                    t: 169
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 8.17526057],
                          [7.749192009999998, 0],
                          [0, -8.175280577999999],
                          [-7.749162003, 0]
                        ],
                        o: [
                          [7.749192009999998, 0],
                          [0, -8.175280577999999],
                          [-7.749162003, 0],
                          [0, 8.17526057],
                          [0, 0]
                        ],
                        v: [
                          [14.35188519, 26.133613309999998],
                          [28.70377038, 13.072056759999999],
                          [14.35188519, 0],
                          [0, 13.072056759999999],
                          [14.35188519, 26.133613309999998]
                        ]
                      }
                    ],
                    t: 169.44255512952805
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 4
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [2.75, 2.48] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [169.26, 75.32] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0.714537],
                    [0.19107999999999992, -0.5779400000000003],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.11677000000000004, 0.6409769999999999],
                    [0.08492999999999995, -0.6725099999999999],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.10616000000000003, -0.5884399999999999],
                    [-0.15922999999999998, 0.5569099999999998],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, -0.5989500000000003],
                    [-0.1486200000000002, 0.630477],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.16985000000000006, -0.5779300000000003],
                    [0, 0.661997],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.15922999999999998, 0.5779299999999998],
                    [0.1486099999999997, -0.5779299999999998],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [4.32047, 3.01576],
                    [4.26739, 0.977233],
                    [3.71538, 2.88967],
                    [3.09969, 4.83362],
                    [2.21861, 4.83362],
                    [1.68784, 2.91068],
                    [1.23139, 0.977233],
                    [1.14646, 3.07881],
                    [1.06154, 4.95972],
                    [0, 4.95972],
                    [0.318464, 0],
                    [1.82585, 0],
                    [2.31416, 1.66025],
                    [2.73877, 3.43607],
                    [3.21646, 1.64974],
                    [3.75785, 0],
                    [5.23339, 0],
                    [5.50938, 4.95972],
                    [4.44784, 4.95972],
                    [4.32047, 3.01576]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 5
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [1.93, 2.48] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [164.09, 75.27] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [1.34815, 0.94571],
                    [0, 0.94571],
                    [0, 0],
                    [3.85336, 0],
                    [3.85336, 0.94571],
                    [2.48398, 0.94571],
                    [2.48398, 4.95971],
                    [1.34815, 4.95971],
                    [1.34815, 0.94571]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 6
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [14.35, 13.07] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [148.04, 64.92] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [7.749199999999998, 0],
                    [0, 8.1751],
                    [-7.74917, 0],
                    [0, -8.17512]
                  ],
                  o: [
                    [0, 8.1751],
                    [-7.74917, 0],
                    [0, -8.17512],
                    [7.749199999999998, 0],
                    [0, 0]
                  ],
                  v: [
                    [28.7038, 13.0718],
                    [14.3519, 26.1331],
                    [0, 13.0718],
                    [14.3519, 0],
                    [28.7038, 13.0718]
                  ]
                }
              }
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-0.7399299999999993, -1.0831],
                    [-1.2226999999999997, -0.4946000000000019],
                    [-1.2943999999999996, 0.26010000000000133],
                    [-0.9311000000000007, 0.9273999999999987],
                    [-0.25459999999999994, 1.2830000000000013],
                    [0.5074000000000005, 1.2072000000000003],
                    [1.0988000000000007, 0.7255699999999994],
                    [1.3209, 0.000020000000000131024],
                    [0.8262999999999998, -0.32596999999999987],
                    [0.6269600000000004, -0.6245699999999994],
                    [0.32407000000000075, -0.8200000000000003],
                    [-0.03237000000000023, -0.8798999999999992],
                    [0, 0]
                  ],
                  o: [
                    [0.008390000000000342, 1.307500000000001],
                    [0.7399300000000011, 1.0831000000000017],
                    [1.2228000000000012, 0.4945999999999984],
                    [1.294500000000001, -0.26019999999999754],
                    [0.9309999999999974, -0.927500000000002],
                    [0.25459999999999994, -1.2829999999999995],
                    [-0.5074000000000005, -1.207180000000001],
                    [-1.0988000000000007, -0.7255700000000003],
                    [-0.8891000000000009, -0.02648000000000028],
                    [-0.8262999999999998, 0.3259799999999995],
                    [-0.6269799999999996, 0.6245700000000003],
                    [-0.32406999999999986, 0.8200000000000003],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [7.66425, 13.0718],
                    [8.81191, 16.7381],
                    [11.8219, 19.1578],
                    [15.6824, 19.5174],
                    [19.0956, 17.696],
                    [20.914, 14.3059],
                    [20.5263, 10.4868],
                    [18.0629, 7.52262],
                    [14.3519, 6.40981],
                    [11.7514, 6.86383],
                    [9.54824, 8.30486],
                    [8.10647, 10.4948],
                    [7.66425, 13.0718],
                    [7.66425, 13.0718]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 7
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [3.83, 12.65] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [123.18, 64.92] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.0935100000000002, -0.09250000000000114],
                    [0.13187999999999978, -0.00969999999999871],
                    [0, 0],
                    [0.09351399999999999, 0.0926000000000009],
                    [0.00980878, 0.1305000000000014],
                    [0, 0],
                    [-0.0933254, 0.094305],
                    [-0.13280099999999995, 0.0121187],
                    [0, 0],
                    [-0.09332999999999991, -0.09430539999999998],
                    [-0.009500000000000064, -0.131679],
                    [0, 0]
                  ],
                  o: [
                    [-0.009809999999999874, 0.1305000000000014],
                    [-0.09351999999999983, 0.0926000000000009],
                    [0, 0],
                    [-0.13188499999999997, -0.00969999999999871],
                    [-0.0935145, -0.09250000000000114],
                    [0, 0],
                    [0.0095021, -0.131679],
                    [0.09332599999999999, -0.09430539999999998],
                    [0, 0],
                    [0.13279999999999959, 0.0121187],
                    [0.09332000000000029, 0.094305],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [7.66427, 24.7881],
                    [7.50411, 25.1339],
                    [7.15474, 25.2925],
                    [0.509531, 25.2925],
                    [0.160163, 25.1339],
                    [0, 24.7881],
                    [0, 0.514884],
                    [0.159272, 0.164843],
                    [0.509531, 0],
                    [7.15474, 0],
                    [7.505, 0.164843],
                    [7.66427, 0.514884],
                    [7.66427, 24.7881]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 8
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [9.74, 12.66] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [103.33, 64.94] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [-0.10660000000000025, -0.10559999999999903],
                    [-0.02459999999999951, -0.14720000000000155],
                    [0, 0],
                    [0.10670000000000002, -0.10560000000000258],
                    [0.1487000000000016, -0.02430000000000021],
                    [0, 0],
                    [0.09351399999999999, 0.09249999999999758],
                    [0.00980876, 0.1305999999999976],
                    [0, 0],
                    [-0.0933254, 0.094305],
                    [-0.13280099999999995, 0.0121187],
                    [0, 0],
                    [-0.09549999999999947, -0.0945314],
                    [-0.009830000000000005, -0.13333300000000003],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0.1487000000000016, 0.02430000000000021],
                    [0.10670000000000002, 0.10549999999999926],
                    [0, 0],
                    [-0.02459999999999951, 0.147199999999998],
                    [-0.10660000000000025, 0.10549999999999926],
                    [0, 0],
                    [-0.13188499999999997, -0.009700000000002262],
                    [-0.0935146, -0.0926000000000009],
                    [0, 0],
                    [0.00950209, -0.131679],
                    [0.09332500000000002, -0.09430539999999998],
                    [0, 0],
                    [0.1346999999999996, 0.00973476],
                    [0.09550000000000036, 0.094532],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [7.64303, 18.8932],
                    [18.8847, 18.8932],
                    [19.2773, 19.0929],
                    [19.4791, 19.4816],
                    [19.4791, 24.7356],
                    [19.2773, 25.1243],
                    [18.8847, 25.324],
                    [0.509539, 25.324],
                    [0.160163, 25.1655],
                    [0, 24.8196],
                    [0, 0.514884],
                    [0.15928, 0.164843],
                    [0.509539, 0],
                    [7.14411, 0],
                    [7.50097, 0.161636],
                    [7.66426, 0.514884],
                    [7.64303, 18.8932]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 9
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [12.2, 12.66] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [74.47, 64.93] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0.09351399999999999, 0.0926000000000009],
                    [0.00980876, 0.1305000000000014],
                    [0, 0],
                    [-0.09332549999999999, 0.09430600000000003],
                    [-0.13280099999999995, 0.0121187],
                    [0, 0],
                    [0, -3.8879200000000003],
                    [0.3667999999999978, -0.8095700000000008],
                    [0.677699999999998, -0.5807000000000002],
                    [-0.5215999999999994, -1.012500000000001],
                    [0.03760000000000119, -1.1358999999999995],
                    [6.263, 0],
                    [0, 0]
                  ],
                  o: [
                    [-0.13188499999999997, -0.00969999999999871],
                    [-0.09351469999999999, -0.09250000000000114],
                    [0, 0],
                    [0.00950209, -0.131679],
                    [0.09332500000000002, -0.09430539999999998],
                    [0, 0],
                    [5.8809000000000005, 0],
                    [0.012700000000002376, 0.8871500000000001],
                    [-0.3667000000000016, 0.8095300000000005],
                    [0.950999999999997, 0.6368999999999989],
                    [0.5217000000000027, 1.0124999999999993],
                    [0, 4.854700000000001],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0.509531, 25.2925],
                    [0.160155, 25.1339],
                    [0, 24.7881],
                    [0, 0.514884],
                    [0.159272, 0.164843],
                    [0.509531, 0],
                    [14.5536, 0],
                    [23.5448, 7.04028],
                    [23.0073, 9.61617],
                    [21.4217, 11.7268],
                    [23.6666, 14.2413],
                    [24.4046, 17.5166],
                    [14.5218, 25.3135],
                    [0.509531, 25.2925]
                  ]
                }
              }
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 1.050790000000001],
                    [1.8364000000000011, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [1.7939999999999987, 0],
                    [0, -1.0507899999999992],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [7.66426, 9.65674],
                    [13.4921, 9.65674],
                    [15.923, 7.84939],
                    [13.2373, 5.95797],
                    [7.66426, 5.95797],
                    [7.66426, 9.65674]
                  ]
                }
              }
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 1.250399999999999],
                    [2.1761, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [2.2185999999999986, 0],
                    [0, -1.2504000000000008],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [7.66426, 19.3135],
                    [13.7893, 19.3135],
                    [16.8147, 17.2119],
                    [13.7044, 15.1103],
                    [7.66426, 15.1103],
                    [7.66426, 19.3135]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 10
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [14.35, 13.07] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [41.22, 64.92] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [7.749199999999998, 0],
                    [0, 8.1751],
                    [-7.74917, 0],
                    [0, -8.17512]
                  ],
                  o: [
                    [0, 8.1751],
                    [-7.74917, 0],
                    [0, -8.17512],
                    [7.749199999999998, 0],
                    [0, 0]
                  ],
                  v: [
                    [28.7038, 13.0718],
                    [14.3519, 26.1331],
                    [0, 13.0718],
                    [14.3519, 0],
                    [28.7038, 13.0718]
                  ]
                }
              }
            },
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-0.7441700000000004, -1.0791999999999984],
                    [-1.2238000000000007, -0.48939999999999984],
                    [-1.2926000000000002, 0.26459999999999795],
                    [-0.9273999999999987, 0.9297000000000004],
                    [-0.25090000000000146, 1.2828],
                    [0.5099000000000018, 1.2052000000000014],
                    [1.099499999999999, 0.7229499999999991],
                    [1.3200000000000003, -0.0019900000000001583],
                    [0.8272999999999993, -0.3285499999999999],
                    [0.6262899999999991, -0.6278499999999996],
                    [0.3214199999999998, -0.823030000000001],
                    [-0.03678000000000026, -0.8815999999999988],
                    [0, 0]
                  ],
                  o: [
                    [0.014660000000000117, 1.3064999999999998],
                    [0.7441800000000001, 1.0791000000000004],
                    [1.2239000000000004, 0.4894999999999996],
                    [1.2926000000000002, -0.26450000000000173],
                    [0.9274000000000022, -0.9297000000000004],
                    [0.25079999999999814, -1.2828000000000017],
                    [-0.5098999999999982, -1.2051499999999997],
                    [-1.099499999999999, -0.72295],
                    [-0.891, -0.025260000000000282],
                    [-0.8272999999999993, 0.3285400000000003],
                    [-0.6263000000000005, 0.6278400000000008],
                    [-0.3214300000000003, 0.8228999999999989],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [7.67488, 13.0718],
                    [8.83864, 16.7305],
                    [11.8568, 19.1361],
                    [15.7161, 19.4811],
                    [19.1208, 17.6496],
                    [20.9279, 14.2565],
                    [20.5306, 10.4409],
                    [18.0624, 7.48395],
                    [14.3519, 6.37828],
                    [11.7469, 6.83808],
                    [9.54321, 8.28802],
                    [8.10642, 10.4876],
                    [7.67488, 13.0718],
                    [7.67488, 13.0718]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 11
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [10.62, 12.86] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: { a: 0, k: [10.67, 65.13] },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: {
              a: 1,
              k: [
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 0 },
                { o: { x: 0, y: 0 }, i: { x: 1.11, y: 1.11 }, s: [100], t: 60 },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [0],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [0.54],
                  t: 89.77022171020508
                },
                { s: [0], t: 104.65533256530762 }
              ]
            }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-1.7939999999999987, 0],
                    [0, 2.1016000000000012],
                    [0, 0],
                    [-0.09549999999999947, 0.094532],
                    [-0.1346999999999987, 0.00973476],
                    [0, 0],
                    [-0.09339999999999904, -0.09430539999999998],
                    [-0.009499999999999176, -0.131679],
                    [0, 0],
                    [7.048600000000002, 0],
                    [0, 4.854600000000001],
                    [0, 0],
                    [-0.14516600000000002, 0.1512999999999991],
                    [-0.20751300000000006, 0.0365000000000002],
                    [0, 0],
                    [-0.10662000000000038, -0.1056000000000008],
                    [-0.02458999999999989, -0.14730000000000132],
                    [0, 0]
                  ],
                  o: [
                    [0, 2.1016000000000012],
                    [1.7940000000000005, 0],
                    [0, 0],
                    [0.009800000000000253, -0.13333300000000003],
                    [0.09550000000000125, -0.0945314],
                    [0, 0],
                    [0.13279999999999959, 0.0121187],
                    [0.09329999999999927, 0.09430600000000003],
                    [0, 0],
                    [0, 5.8109],
                    [-6.43286, 0],
                    [0, 0],
                    [0.0262083, -0.20699999999999896],
                    [0.14516600000000002, -0.1512000000000011],
                    [0, 0],
                    [0.14876000000000023, 0.024299999999998434],
                    [0.10660999999999987, 0.10549999999999926],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [7.61118, 16.1086],
                    [10.5941, 19.261],
                    [13.5664, 16.014],
                    [13.5664, 0.514884],
                    [13.7297, 0.161636],
                    [14.0865, 0],
                    [20.7211, 0],
                    [21.0714, 0.164843],
                    [21.2306, 0.514884],
                    [21.2306, 15.7303],
                    [10.6153, 25.7128],
                    [0, 17.5797],
                    [0, 16.1086],
                    [0.263826, 15.557],
                    [0.806764, 15.268],
                    [7.00611, 15.268],
                    [7.39881, 15.4677],
                    [7.60057, 15.8564],
                    [7.61118, 16.1086]
                  ]
                }
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 12
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [48.8, 16.15] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [62.93, 24.27],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [62.93, 24.27],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [62.93, 104.99],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [62.93, 97.87],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [62.93, 98.28],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [62.93, 98.27],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [62.93, 98.27],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [62.93, 62.27],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [62.93, 62.27],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [62.93, 62.27],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [62.93, 62.27],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [62.93, 62.27],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [62.93, 62.27],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [62.93, 62.27],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [62.93, 62.27],
                  t: 166.96170341968536
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1, y: 1 },
                  s: [62.93, 62.27],
                  t: 169
                },
                { s: [62.93, 62.27], t: 169.44255512952805 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100 }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.36, -6.38],
                          [7.63, -0.01],
                          [13.06, -15.76],
                          [0, 0],
                          [-26.64, 0]
                        ],
                        o: [
                          [13.06, -0.08],
                          [-7.3, -2.19],
                          [-23.41, 0],
                          [0, 0],
                          [10.68, -19.09],
                          [0, 0]
                        ],
                        v: [
                          [60.33, 0],
                          [97.61, 9.63],
                          [75.09, 6.34],
                          [17.86, 32.3],
                          [0, 32.3],
                          [60.33, 0]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.36, -6.38],
                          [7.63, -0.01],
                          [13.06, -15.76],
                          [0, 0],
                          [-26.64, 0]
                        ],
                        o: [
                          [13.06, -0.08],
                          [-7.3, -2.19],
                          [-23.41, 0],
                          [0, 0],
                          [10.68, -19.09],
                          [0, 0]
                        ],
                        v: [
                          [60.33, 0],
                          [97.61, 9.63],
                          [75.09, 6.34],
                          [17.86, 32.3],
                          [0, 32.3],
                          [60.33, 0]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 162
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 164.48085170984268
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 166.96170341968536
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.36, -6.38],
                          [7.63, -0.01],
                          [13.06, -15.76],
                          [0, 0],
                          [-26.64, 0]
                        ],
                        o: [
                          [13.06, -0.08],
                          [-7.3, -2.19],
                          [-23.41, 0],
                          [0, 0],
                          [10.68, -19.09],
                          [0, 0]
                        ],
                        v: [
                          [60.33, 0],
                          [97.61, 9.63],
                          [75.09, 6.34],
                          [17.86, 32.3],
                          [0, 32.3],
                          [60.33, 0]
                        ]
                      }
                    ],
                    t: 169
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-11.361800000000002, -6.382859999999999],
                          [7.627899999999997, -0.009920000000000151],
                          [13.056799999999999, -15.761800000000001],
                          [0, 0],
                          [-26.644399999999997, 0]
                        ],
                        o: [
                          [13.063899999999997, -0.07662738999999999],
                          [-7.2997999999999905, -2.190549999999999],
                          [-23.406799999999997, 0],
                          [0, 0],
                          [10.679, -19.092800000000004],
                          [0, 0]
                        ],
                        v: [
                          [60.3268, 0.00129739],
                          [97.6078, 9.62652],
                          [75.0928, 6.33755],
                          [17.855, 32.3025],
                          [0, 32.3025],
                          [60.3268, 0.00129739]
                        ]
                      }
                    ],
                    t: 169.44255512952805
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 13
        },
        {
          ty: 4,
          nm: 'Vector',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: { a: 0, k: [52.52, 20.21] },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [101.41, 20.21],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [101.41, 20.21],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [101.41, 100.92],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [101.41, 93.81],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [101.41, 94.22],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [101.41, 94.21],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [101.41, 94.21],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [101.41, 58.21],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [101.41, 58.21],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [101.41, 58.21],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [101.41, 58.21],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [101.41, 58.21],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [101.41, 58.21],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [101.41, 58.21],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [101.41, 58.21],
                  t: 166.96170341968536
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1, y: 1 },
                  s: [101.41, 58.21],
                  t: 169
                },
                { s: [101.41, 58.21], t: 169.44255512952805 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100 }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: false,
                        i: [
                          [0, 0],
                          [-9.57, -23.54],
                          [0, 0],
                          [27.4, 0],
                          [8.2, -2.93],
                          [-13.65, -0.07]
                        ],
                        o: [
                          [30.56, 0],
                          [0, 0],
                          [-10.26, -20.37],
                          [-8.72, 0],
                          [11.84, -6.73],
                          [0, 0]
                        ],
                        v: [
                          [38.92, 0],
                          [105.05, 40.42],
                          [86.78, 40.42],
                          [25.57, 5.75],
                          [0, 10.17],
                          [38.92, 0]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: false,
                        i: [
                          [0, 0],
                          [-9.57, -23.54],
                          [0, 0],
                          [27.4, 0],
                          [8.2, -2.93],
                          [-13.65, -0.07]
                        ],
                        o: [
                          [30.56, 0],
                          [0, 0],
                          [-10.26, -20.37],
                          [-8.72, 0],
                          [11.84, -6.73],
                          [0, 0]
                        ],
                        v: [
                          [38.92, 0],
                          [105.05, 40.42],
                          [86.78, 40.42],
                          [25.57, 5.75],
                          [0, 10.17],
                          [38.92, 0]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 162
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 164.48085170984268
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 166.96170341968536
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: false,
                        i: [
                          [0, 0],
                          [-9.57, -23.54],
                          [0, 0],
                          [27.4, 0],
                          [8.2, -2.93],
                          [-13.65, -0.07]
                        ],
                        o: [
                          [30.56, 0],
                          [0, 0],
                          [-10.26, -20.37],
                          [-8.72, 0],
                          [11.84, -6.73],
                          [0, 0]
                        ],
                        v: [
                          [38.92, 0],
                          [105.05, 40.42],
                          [86.78, 40.42],
                          [25.57, 5.75],
                          [0, 10.17],
                          [38.92, 0]
                        ]
                      }
                    ],
                    t: 169
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [-9.57480000000001, -23.5377],
                          [0, 0],
                          [27.3981, 0],
                          [8.20096, -2.9294800000000008],
                          [-13.651799999999998, -0.07057097]
                        ],
                        o: [
                          [30.561500000000002, 0],
                          [0, 0],
                          [-10.265, -20.3748],
                          [-8.718599999999999, -0.002500000000000391],
                          [11.837, -6.732710000000001],
                          [0, 0]
                        ],
                        v: [
                          [38.9158, 0.00104367],
                          [105.049, 40.4249],
                          [86.7802, 40.4249],
                          [25.5723, 5.74885],
                          [0, 10.1727],
                          [38.9158, 0.00104367]
                        ]
                      }
                    ],
                    t: 169.44255512952805
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [0.0236, 0.4118, 0.6942] },
              r: 1,
              o: { a: 0, k: 100 }
            }
          ],
          ind: 14
        },
        {
          ty: 4,
          nm: 'Frame Bg',
          sr: 1,
          st: 0,
          op: 170.04,
          ip: 0,
          hd: false,
          ddd: 0,
          bm: 0,
          hasMask: false,
          ao: 0,
          ks: {
            a: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 58.63],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 56.9],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [86, 57],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [86, 57],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [86, 57],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 39],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 39],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 166.96170341968536
                },
                { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [86, 39], t: 169 },
                { s: [86, 39], t: 169.44255512952805 }
              ]
            },
            s: { a: 0, k: [100, 100] },
            sk: { a: 0, k: 0 },
            p: {
              a: 1,
              k: [
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 0
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 60
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 58.63],
                  t: 74.88511085510254
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 56.9],
                  t: 89.77022171020508
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [86, 57],
                  t: 104.65533256530762
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [86, 57],
                  t: 105
                },
                {
                  o: { x: 0.36, y: 0 },
                  i: { x: 0.66, y: -0.56 },
                  s: [86, 57],
                  t: 111
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 129
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 147
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 39],
                  t: 151.96170341968536
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 156.92340683937073
                },
                {
                  o: { x: 1, y: 1 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 161.8851102590561
                },
                {
                  o: { x: 0, y: 0 },
                  i: { x: 1.11, y: 1.11 },
                  s: [86, 39],
                  t: 162
                },
                {
                  o: { x: 1.11, y: 1.11 },
                  i: { x: 0.99, y: 0.99 },
                  s: [86, 39],
                  t: 164.48085170984268
                },
                {
                  o: { x: 0.99, y: 0.99 },
                  i: { x: 1, y: 1 },
                  s: [86, 39],
                  t: 166.96170341968536
                },
                { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [86, 39], t: 169 },
                { s: [86, 39], t: 169.44255512952805 }
              ]
            },
            r: { a: 0, k: 0 },
            sa: { a: 0, k: 0 },
            o: { a: 0, k: 100 }
          },
          shapes: [
            {
              ty: 'sh',
              bm: 0,
              hd: false,
              nm: '',
              d: 1,
              ks: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 0
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 60
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 117.2652],
                          [0, 117.2652],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 74.88511085510254
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 113.8056],
                          [0, 113.8056],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 89.77022171020508
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 114.0036],
                          [0, 114.0036],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 104.65533256530762
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 0],
                          [172, 114],
                          [0, 114],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 105
                  },
                  {
                    o: { x: 0.36, y: 0 },
                    i: { x: 0.66, y: -0.56 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 0],
                          [172, 114],
                          [0, 114],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 111
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 129
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 147
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 151.96170341968536
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 156.92340683937073
                  },
                  {
                    o: { x: 1, y: 1 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 161.8851102590561
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1.11, y: 1.11 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 162
                  },
                  {
                    o: { x: 1.11, y: 1.11 },
                    i: { x: 0.99, y: 0.99 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 164.48085170984268
                  },
                  {
                    o: { x: 0.99, y: 0.99 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 166.96170341968536
                  },
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [172, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 169
                  },
                  {
                    s: [
                      {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0, 0],
                          [172, 0],
                          [172, 78],
                          [0, 78],
                          [0, 0]
                        ]
                      }
                    ],
                    t: 169.44255512952805
                  }
                ]
              }
            },
            {
              ty: 'fl',
              bm: 0,
              hd: false,
              nm: '',
              c: { a: 0, k: [1, 1, 1] },
              r: 1,
              o: { a: 0, k: 0 }
            }
          ],
          ind: 15
        }
      ]
    }
  ]
};
