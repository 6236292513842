import Authentication from 'components/Authentication';
import NotificationsSidebar from 'components/Notifications/components/NotificationsSidebar/NotificationsSidebar';

import { SidebarContentTypes } from 'constants/enums';

const sidebarComponentMapping = {
  [SidebarContentTypes.Authentication]: <Authentication />,
  [SidebarContentTypes.NotificationsView]: <NotificationsSidebar />
};

export default sidebarComponentMapping;
