import { ReactElement, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Col, Form, Row } from 'antd';

import SkillsetSelect from 'components/FormCommon/SkillsetSelect';
import { useEditingState } from 'components/LoggedUserProfile/hooks/useEditingState';
import styles from 'components/LoggedUserProfile/styles.module.scss';
import SelectLanguageLevels from 'components/SelectLanguageLevels/SelectLanguageLevels';

import { Jobseeker } from 'types/jobseekers';

import { SECTION_ROW_GUTTER } from 'constants/common';

interface LanguagesAndSkillsFormProps {
  loading: boolean;
  user: Partial<Jobseeker>;
  handleFormSubmit: (fields: Partial<Jobseeker>) => Promise<void>;
}

const LanguagesAndSkillsForm = ({
  loading,
  user,
  handleFormSubmit
}: LanguagesAndSkillsFormProps): ReactElement => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const fieldsValues = Form.useWatch([], form);

  const { isEditing, setIsEditing, EditButton } = useEditingState();

  const defaultValidateMessages = {
    required: 'Field is required'
  };

  const initialValues = useMemo(
    () => ({
      languages: user?.languages || [],
      skills: user?.skills || []
    }),
    [user]
  );

  const handleSubmitForm = (): void => {
    handleFormSubmit(fieldsValues);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      validateMessages={defaultValidateMessages}
      initialValues={{ ...initialValues }}
    >
      <Row gutter={SECTION_ROW_GUTTER}>
        <Col span={24} className={styles.editButtonContainer}>
          {EditButton}
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24} md={12}>
          <SelectLanguageLevels
            form={form}
            name="languages"
            label={intl.formatMessage({
              id: 'profile.languagesAndSkills.label.languages'
            })}
            placeholder={intl.formatMessage({
              id: 'form.intake.placeholder.languages'
            })}
            disabled={!isEditing}
          />
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="skills"
            label={intl.formatMessage({
              id: 'profile.languagesAndSkills.label.skillsets'
            })}
          >
            <SkillsetSelect
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.professionNew'
              })}
              title=""
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className={styles.submitContainer}>
        {isEditing && (
          <>
            <Button
              className={styles.cancelButton}
              onClick={handleCancelClick}
              loading={loading}
            >
              <FormattedMessage id="form.button.cancel" />
            </Button>
            <Button
              type="primary"
              loading={loading}
              className="btn btn-primary intake-button"
              onClick={handleSubmitForm}
            >
              <FormattedMessage id="form.button.save" />
            </Button>
          </>
        )}
      </div>
    </Form>
  );
};

export default LanguagesAndSkillsForm;
