import { FC } from 'react';

import { Col, Row } from 'antd';
import cs from 'classnames';

import { ReactComponent as ScreeningAlertIcon } from 'assets/icons/screening-alert.svg';

import styles from './styles.module.scss';

interface ScreeningProgressProps {
  formLink: string;
  preScreeningCompleted: boolean | null;
}

const ScreeningProgress: FC<ScreeningProgressProps> = ({
  formLink,
  preScreeningCompleted
}) => {
  return (
    <Row className={styles.screeningProgress}>
      <Col flex="auto">
        <div
          className={cs(styles.message, {
            [styles.completed]: preScreeningCompleted
          })}
        >
          <ScreeningAlertIcon className={styles.icon} />
          <span>
            {preScreeningCompleted ? (
              'Your screening is 100% complete. Your application will be escalated for review!'
            ) : (
              <>
                Your screening is 0% complete. Please fill out the{' '}
                <a href={formLink} className={styles.formLink}>
                  form
                </a>{' '}
                to speed up your application.
              </>
            )}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default ScreeningProgress;
