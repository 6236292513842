import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  JobApplicationsList,
  JobApplicationsState
} from 'types/jobApplications';

import { RequestStatus } from 'constants/enums';

import { fetchJobApplicationsData } from './jobApplicationsThunks';

const initialState: JobApplicationsState = {
  jobApplications: [],
  isLoading: false,
  status: RequestStatus.Idle,
  error: null,
  numberOfElements: 0,
  totalElements: 0
};

const jobApplicationsSlice = createSlice({
  name: 'jobApplications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobApplicationsData.pending, (state) => {
        state.status = RequestStatus.Loading;
        state.isLoading = true;
      })
      .addCase(
        fetchJobApplicationsData.fulfilled,
        (state, action: PayloadAction<JobApplicationsList>) => {
          state.status = RequestStatus.Succeeded;
          state.jobApplications = action.payload.content;
          state.isLoading = false;
          state.totalElements = action.payload.totalElements;
          state.numberOfElements = action.payload.numberOfElements;
          state.error = null;
        }
      )
      .addCase(fetchJobApplicationsData.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  }
});

export default jobApplicationsSlice.reducer;
