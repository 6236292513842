import { createSlice } from '@reduxjs/toolkit';

import { NotificationsState } from 'types/notifications';

import {
  getReadNotifications,
  getUnreadNotifications,
  getUnreadNotificationsCount
} from './notificationsThunks';

const initialState: NotificationsState = {
  unreadNotifications: [],
  readNotifications: [],
  unreadNotificationsToShow: [],
  unreadCount: 0,
  isLoading: false
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetUnreadNotificationsToShow: (state) => {
      state.unreadNotificationsToShow = [];
    },
    // Optimistic
    clearUnreadNotifications: (state) => {
      state.unreadNotifications = [];
      state.unreadCount = 0;
    },
    // Optimistic
    clearReadNotifications: (state) => {
      state.readNotifications = [];
    },
    // Optimistic
    clearUnreadNotification: (state, { payload }) => {
      state.unreadNotifications = state.unreadNotifications.filter(
        (el) => el.id !== payload
      );
      state.unreadNotificationsToShow = state.unreadNotificationsToShow.filter(
        (el) => el.id !== payload
      );
      state.unreadCount = state.unreadCount - 1;
    },
    clearReadNotification: (state, { payload }) => {
      state.readNotifications = state.readNotifications.filter(
        (el) => el.id !== payload
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnreadNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnreadNotifications.fulfilled, (state, { payload }) => {
        const currentNotifications = state.unreadNotifications;
        const receivedNotification = payload.content || [];

        const unreadNotificationsToShow = receivedNotification.filter(
          ({ id }) => !currentNotifications.find((el) => el.id === id)
        );

        state.unreadNotifications = receivedNotification;
        state.unreadNotificationsToShow = unreadNotificationsToShow;

        state.isLoading = false;
      })
      .addCase(getUnreadNotifications.rejected, (state) => {
        state.isLoading = false;
        state.unreadNotifications = [];
        state.unreadNotificationsToShow = [];
      })

      .addCase(getUnreadNotificationsCount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnreadNotificationsCount.fulfilled, (state, { payload }) => {
        state.unreadCount = payload.count;
        state.isLoading = false;
      })
      .addCase(getUnreadNotificationsCount.rejected, (state) => {
        state.isLoading = false;
        state.unreadCount = 0;
      })

      .addCase(getReadNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReadNotifications.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const receivedNotifications = payload.content || [];

        // for infinite scroll pagination
        if (payload.pageable.pageNumber === 0) {
          state.readNotifications = [...receivedNotifications];
          return;
        }
        state.readNotifications = [
          ...state.readNotifications,
          ...receivedNotifications
        ];
      })
      .addCase(getReadNotifications.rejected, (state) => {
        state.isLoading = false;
        state.readNotifications = [];
      });
  }
});

export default notificationsSlice.reducer;
export const {
  resetUnreadNotificationsToShow,
  clearUnreadNotification,
  clearUnreadNotifications,
  clearReadNotifications,
  clearReadNotification
} = notificationsSlice.actions;
