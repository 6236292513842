import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CountriesState, Country } from 'types/commons';

const initialState: CountriesState = {
  countries: [],
  activeJobCountries: [],
  lastUpdated: null
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountries: (
      state,
      action: PayloadAction<{ countries: Country[]; lastUpdated: number }>
    ) => {
      state.countries = action.payload.countries;
      state.lastUpdated = action.payload.lastUpdated;
    },
    setActiveJobCountries: (
      state,
      action: PayloadAction<{
        activeJobCountries: Country[];
        lastUpdated: number;
      }>
    ) => {
      state.activeJobCountries = action.payload.activeJobCountries;
      state.lastUpdated = action.payload.lastUpdated;
    }
  }
});

export const { setCountries, setActiveJobCountries } = countriesSlice.actions;
export default countriesSlice.reducer;
