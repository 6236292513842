import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LoggedUser } from 'types/commons';

import { fetchUserData } from './authThunks';

const defaultUser: LoggedUser = {
  userId: '',
  email: '',
  firstName: '',
  lastName: '',
  location: {
    country: '',
    state: '',
    city: ''
  },
  phone: '',
  country: {
    code: '',
    name: '',
    longCode: ''
  }
};

interface AuthState {
  token: string | null;
  isLoggedIn: boolean;
  user: LoggedUser;
}

const tokenFromStorage = localStorage.getItem('token');

const initialState: AuthState = {
  token: tokenFromStorage,
  isLoggedIn: !!tokenFromStorage,
  user: defaultUser
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.user = defaultUser;
      localStorage.setItem('token', action.payload.token);
    },
    updateUser: (state, action: PayloadAction<Partial<LoggedUser>>) => {
      state.user = {
        ...state.user,
        ...action.payload
      };
    },
    logout: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.user = defaultUser;
      localStorage.removeItem('token');
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUserData.fulfilled,
      (state, action: PayloadAction<LoggedUser>) => {
        state.user = {
          ...state.user,
          ...action.payload
        };
        state.isLoggedIn = true;
      }
    );
  }
});

export const { setCredentials, updateUser, logout } = authSlice.actions;

export default authSlice.reducer;
