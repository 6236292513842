import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Col, Form, FormInstance, Input, Row } from 'antd';
import cs from 'classnames';

import Alert from 'components/Alert/Alert';

import { MIN_PASSWORD_LENGTH, SECTION_ROW_GUTTER } from 'constants/common';
import { AlertType, AuthSteps } from 'constants/enums';

import styles from '../Authentication.module.scss';
import VerificationCode from '../VerificationCode';

interface ChangePasswordProps {
  handleSubmit: () => void;
  handleCancel: () => void;
  canSubmit: boolean;
  form: FormInstance;
  isLoading: boolean;
  email?: string;
  submitLabel?: string;
  step?: AuthSteps.ForgotPassword | AuthSteps.SetPassword;
}

const ChangePassword: FC<ChangePasswordProps> = ({
  handleSubmit,
  handleCancel,
  canSubmit,
  form,
  isLoading,
  email,
  submitLabel = 'form.control.continue',
  step
}) => {
  const intl = useIntl();
  const userEmail = form.getFieldValue('email') || email;

  const validatePassword = (_: any, value: any): Promise<void> => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(
        new Error(intl.formatMessage({ id: 'validation.confirmPassword' }))
      );
    }
    return Promise.resolve();
  };

  return (
    <>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24}>
          <VerificationCode email={userEmail} />
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24}>
          <Form.Item
            name="password"
            label={intl.formatMessage(
              { id: 'form.newPassword' },
              { minLength: MIN_PASSWORD_LENGTH }
            )}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="validation.password"
                    values={{ minLength: MIN_PASSWORD_LENGTH }}
                  />
                )
              },
              {
                min: MIN_PASSWORD_LENGTH,
                message: (
                  <FormattedMessage
                    id="validation.password.length"
                    values={{ minLength: MIN_PASSWORD_LENGTH }}
                  />
                )
              }
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24}>
          <Form.Item
            name="confirm"
            label={intl.formatMessage(
              { id: 'form.confirmNewPassword' },
              { minLength: MIN_PASSWORD_LENGTH }
            )}
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please, confirm the password' },
              { validator: validatePassword }
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      {step === AuthSteps.SetPassword && (
        <Row gutter={SECTION_ROW_GUTTER} className="row-section">
          <Col span={24}>
            <Alert type={AlertType.Info}>
              <FormattedMessage id="auth.label.redirectInfo" />
            </Alert>
          </Col>
        </Row>
      )}
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={12} className="text-center">
          <Button
            type="link"
            className={cs(styles.borderLess, 'btn')}
            onClick={handleCancel}
            loading={isLoading}
          >
            <FormattedMessage id="form.button.cancel" />
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="primary"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={!canSubmit}
            loading={isLoading}
            block
          >
            <FormattedMessage id={submitLabel} />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ChangePassword;
