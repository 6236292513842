import { createAsyncThunk } from '@reduxjs/toolkit';

import { Filters } from 'types/filters';

import NotificationsService from './notificationsService';

export const getReadNotifications = createAsyncThunk(
  'notifications/getReadNotifications',
  async (filters: Filters, { rejectWithValue }) => {
    try {
      const response = await NotificationsService.getReadNotifications(filters);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnreadNotificationsCount = createAsyncThunk(
  'notifications/getUnreadNotificationsCount',
  async (_, { rejectWithValue }) => {
    try {
      const response = await NotificationsService.getUnreadNotificationsCount();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnreadNotifications = createAsyncThunk(
  'notifications/getUnreadNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await NotificationsService.getUnreadNotifications();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await NotificationsService.markAsRead(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markAllNotificationsAsRead = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async (_, { rejectWithValue }) => {
    try {
      const response = await NotificationsService.markAllAsRead();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAllReadNotificationsThunk = createAsyncThunk(
  'notifications/deleteAllReadNotificationsThunk',
  async (_, { rejectWithValue }) => {
    try {
      const response = await NotificationsService.deleteAllReadNotifications();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteReadNotificationThunk = createAsyncThunk(
  'notifications/deleteReadNotificationThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await NotificationsService.deleteReadNotification(id);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
