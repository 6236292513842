import AttachmentsView from 'components/AttachmentsView/AttachmentsView';
import BackgroundInformation from 'components/LoggedUserProfile/BackgroundInformation/BackgroundInformation';
import Industry from 'components/LoggedUserProfile/Industry/Industry';
import LanguageAndSkills from 'components/LoggedUserProfile/LanguageAndSkills/LanguageAndSkills';
import PersonalInformation from 'components/LoggedUserProfile/PersonalInformation/PersonalInformation';

export const tabs = [
  {
    key: 'personal',
    label: 'Personal information',
    component: <PersonalInformation />
  },
  {
    key: 'skills',
    label: 'Languages & skills',
    component: <LanguageAndSkills />
  },
  {
    key: 'industry',
    label: 'Industry preferences',
    component: <Industry />
  },
  {
    key: 'background',
    label: 'Background information',
    component: <BackgroundInformation />
  },
  {
    key: 'documents',
    label: 'Documents',
    component: <AttachmentsView />
  }
];
