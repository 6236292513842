import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AutoComplete, Form, Select } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import { FormInstance } from 'antd/lib';
import CommonsService from 'services/commonsService';

import CountryPreview from 'components/CountryPreview/CountryPreview';

import { selectCountries } from 'features/countries/countriesSelectors';

import useErrorCatch from 'hooks/useErrorCatch';

import { GTMEventLogger } from 'utils/analytics';
import getCountriesOptions from 'utils/getCountriesOptions';
import parseCity from 'utils/parseCity';

import { CountryOption } from 'types/commons';

import { GTMEvents } from 'constants/enums';

interface UseCountryCitySelectProps {
  form: FormInstance;
  countryFieldName?: string | string[];
  cityFieldName?: string | string[];
  isCountryRequired?: boolean;
  isCityRequired?: boolean;
  countryCode?: string;
  isCountriesLoading?: boolean;
  disabled?: boolean;
}

const MIN_SPELLING_SYMBOLS = 3;

const useCountryCitySelect = ({
  form,
  countryFieldName = ['location', 'country'],
  cityFieldName = ['location', 'city'],
  isCountryRequired = false,
  isCityRequired = false,
  countryCode,
  isCountriesLoading = false,
  disabled = false
}: UseCountryCitySelectProps): {
  countrySelect: JSX.Element;
  citySelect: JSX.Element;
  rawString: string;
  setRawString: React.Dispatch<React.SetStateAction<string>>;
} => {
  const intl = useIntl();
  const [cities, setCities] = useState<DefaultOptionType[]>([]);
  const [rawString, setRawString] = useState<string>('');
  const catchError = useErrorCatch();
  const countries = useSelector(selectCountries);

  const countryWatched = Form.useWatch(countryFieldName, form);
  const country = countryCode ?? countryWatched;

  const countriesOptions = getCountriesOptions({ countries });

  const fetchCities = useCallback(
    async (spelling: string, country: string) => {
      if (spelling.length < MIN_SPELLING_SYMBOLS) {
        setCities([]);
        return;
      }

      try {
        const response = await CommonsService.getCitiesByCountryCodeAndSpelling(
          country,
          spelling
        );
        setCities(
          response.map((city: string) => ({
            label: city,
            value: city,
            key: city
          }))
        );
      } catch (error) {
        catchError({
          error,
          default: {
            message: 'Operation Failed',
            description: 'Failed to fetch Cities'
          }
        });
      }
    },
    [catchError]
  );

  const handleCitiesSearch = useCallback(
    (spelling: string) => {
      fetchCities(spelling, country);
    },
    [fetchCities, country]
  );

  const handleCitySelect = useCallback(
    (value: string) => {
      const parsedCity = parseCity(value);
      setRawString(value);
      form.setFieldValue(cityFieldName, parsedCity.city);
    },
    [form, cityFieldName]
  );

  const handleCountryChange = useCallback(() => {
    GTMEventLogger(GTMEvents.SelectCountryOfOrigin);

    form.setFieldValue(cityFieldName, '');
    setRawString('');
  }, [form]);

  const renderCountryOption = ({
    data
  }: {
    data: CountryOption;
  }): ReactElement => (
    <CountryPreview countryCode={data.value} countryName={data.label} />
  );

  const renderCountryLabel = (option: DefaultOptionType): ReactNode => (
    <CountryPreview
      countryName={String(option.label)}
      countryCode={String(option.value)}
    />
  );

  useEffect(() => {
    if (form.getFieldValue(countryFieldName) === '') {
      form.setFieldValue(countryFieldName, undefined);
    }
  }, [form.getFieldValue(countryFieldName)]);

  const countrySelect = (
    <Form.Item
      name={countryFieldName}
      label={intl.formatMessage({ id: 'form.intake.countryOfLivingNew' })}
      rules={[{ required: isCountryRequired }]}
    >
      <Select
        onChange={handleCountryChange}
        placeholder={intl.formatMessage({
          id: 'form.intake.placeholder.countryOfLivingNew'
        })}
        options={countriesOptions}
        showSearch
        optionFilterProp="label"
        loading={isCountriesLoading}
        optionRender={renderCountryOption}
        labelRender={renderCountryLabel}
        disabled={disabled}
      />
    </Form.Item>
  );

  const citySelect = (
    <Form.Item
      name={cityFieldName}
      label="City/State"
      rules={[{ required: isCityRequired }]}
    >
      <AutoComplete
        options={cities}
        filterOption={false}
        onSearch={handleCitiesSearch}
        onSelect={handleCitySelect}
        value={form.getFieldValue(cityFieldName)}
        placeholder="Type or select a city and state"
        disabled={disabled}
        allowClear
      />
    </Form.Item>
  );

  return {
    countrySelect,
    citySelect,
    rawString,
    setRawString
  };
};

export default useCountryCitySelect;
