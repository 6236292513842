import { Jobseeker } from 'types/jobseekers';

interface FieldWeight {
  field: keyof Jobseeker;
  weight: number;
  validator: (value: any) => boolean;
}

const validateLocation = (value: any) => !!value?.country && !!value?.city;

const PROFILE_FIELDS: FieldWeight[] = [
  { field: 'firstName', weight: 10, validator: (v) => !!v?.trim() },
  { field: 'lastName', weight: 10, validator: (v) => !!v?.trim() },
  { field: 'phoneNumber', weight: 10, validator: (v) => !!v?.trim() },
  { field: 'location', weight: 10, validator: validateLocation },
  { field: 'citizenship', weight: 10, validator: (v) => !!v?.trim() },
  { field: 'mailingAddress', weight: 10, validator: (v) => !!v?.trim() },
  {
    field: 'languages',
    weight: 10,
    validator: (v) => !!v && Object.keys(v).length > 0
  },
  { field: 'skills', weight: 10, validator: Array.isArray },
  { field: 'lookingForJobInAreas', weight: 10, validator: Array.isArray },
  {
    field: 'backgroundCheckConsent',
    weight: 5,
    validator: (v) => typeof v === 'boolean'
  },
  {
    field: 'felonyConviction',
    weight: 5,
    validator: (v) => typeof v === 'boolean'
  }
];

export const calculateProfileProgress = (
  profile: Partial<Jobseeker>
): number => {
  if (!profile) return 0;

  const progress = PROFILE_FIELDS.reduce(
    (acc, { field, weight, validator }) =>
      acc + (validator(profile[field]) ? weight : 0),
    0
  );

  return Math.min(progress, 100);
};
