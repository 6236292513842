import { Button } from 'antd';
import cs from 'classnames';

import { AlertType } from 'constants/enums';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg';
import { ReactComponent as InfoCircleIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/tick-circle.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/warning.svg';

import styles from './Alert.module.scss';

interface AlertProps {
  type: AlertType;
  message?: string;
  title?: string;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const ALERT_ICONS_MAPPING = {
  [AlertType.Info]: InfoCircleIcon,
  [AlertType.Warning]: DangerIcon,
  [AlertType.Error]: ErrorIcon,
  [AlertType.Success]: SuccessIcon
};

const Alert = ({
  type = AlertType.Warning,
  title,
  message,
  onClose,
  className,
  style,
  children
}: AlertProps): JSX.Element => {
  const Icon = ALERT_ICONS_MAPPING[type];

  return (
    <div
      className={cs(styles.container, styles[type], className)}
      style={style}
    >
      <div className={`${styles.iconWrapper} ${styles[`${type}IconWrapper`]}`}>
        <Icon className={styles.icon} />
      </div>
      <div className={styles.text}>
        {title && <div className={styles.title}>{title}</div>}
        {message && <div className={styles.message}>{message}</div>}
        {children && <div className={styles.message}>{children}</div>}
      </div>
      {onClose && (
        <div className={styles.closeButtonWrapper}>
          <Button
            className={styles.closeButton}
            type="text"
            icon={<CloseIcon className={styles.buttonCloseIcon} />}
            onClick={onClose}
          />
        </div>
      )}
    </div>
  );
};

export default Alert;
