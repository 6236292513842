import { generateParams } from 'features/filters/filtersSlice';

import { Filters } from 'types/filters';

export const updateURLWithFilters = (filters: Filters) => {
  const searchParams = new URLSearchParams(window.location.search);

  // Save existing parameters (e.g. tab, other queries)
  const preservedParams = new URLSearchParams(searchParams);

  // Adding/updating filters
  const filterParams = new URLSearchParams(generateParams(filters));

  filterParams.forEach((value, key) => {
    preservedParams.set(key, value);
  });

  // Updating URLs
  window.history.replaceState(null, '', `?${preservedParams.toString()}`);
};
