import { FC, ReactElement } from 'react';

import { Badge } from 'antd';
import cs from 'classnames';

import styles from './BadgeIcon.module.scss';

interface BadgeIconProps {
  showBadge: boolean;
  icon: ReactElement;
  badgeColor?: string;
  className?: string;
}

const BadgeIcon: FC<BadgeIconProps> = ({
  showBadge,
  icon,
  badgeColor = '#F63D74',
  className
}) => {
  return showBadge ? (
    <Badge dot color={badgeColor} className={cs(styles.badge, className)}>
      {icon}
    </Badge>
  ) : (
    icon
  );
};

export default BadgeIcon;
