import { useEffect, useState } from 'react';

import { Form, FormInstance } from 'antd';
import {
  isValidPhoneNumber,
  parsePhoneNumberFromString
} from 'libphonenumber-js';

interface Country {
  label: string;
  value: string;
  code: string;
  callingCode: string;
}

interface UseInitialPhoneNumberResult {
  selectedCountry: Country;
  phoneCode: string;
  phoneNumber: string;
  isValid: boolean;
  setSelectedCountry: React.Dispatch<React.SetStateAction<Country>>;
  setPhoneCode: React.Dispatch<React.SetStateAction<string>>;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultCountry = {
  label: 'United States',
  value: 'United States',
  code: 'US',
  callingCode: '1'
};

const useInitialPhoneNumber = (
  form: FormInstance,
  name: string,
  countryList: Country[]
): UseInitialPhoneNumberResult => {
  const [selectedCountry, setSelectedCountry] =
    useState<Country>(defaultCountry);
  const [phoneCode, setPhoneCode] = useState<string>(
    defaultCountry.callingCode
  );
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const initialPhoneNumber = Form.useWatch([name], form);

  useEffect(() => {
    if (!initialPhoneNumber) {
      return;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(initialPhoneNumber);
    if (parsedPhoneNumber && isValidPhoneNumber(parsedPhoneNumber.number)) {
      const country = countryList.find(
        (c) => c.code === parsedPhoneNumber.country
      );
      if (country) {
        setSelectedCountry(country);
        setPhoneCode(country.callingCode);
        setPhoneNumber(parsedPhoneNumber.nationalNumber);
      }
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [countryList, initialPhoneNumber]);

  return {
    selectedCountry,
    phoneCode,
    phoneNumber,
    isValid,
    setSelectedCountry,
    setPhoneCode,
    setPhoneNumber,
    setIsValid
  };
};

export default useInitialPhoneNumber;
