import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { MODULE_SORT_CONFIG } from 'config/sortConfig';

import CountrySelect from 'components/Filters/components/CountrySelect';
import SelectCommon from 'components/Filters/components/SelectCommon';
import SelectedFiltersTags from 'components/Filters/components/SelectedFiltersTags';
import useHandleChange from 'components/Filters/hooks/useHandleChange';
import PageControls from 'components/PageControls/PageControls';

import { selectActiveJobCountries } from 'features/countries/countriesSelectors';
import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';
import { selectFilters } from 'features/filters/filtersSelectors';
import { setCountry, setIndustry } from 'features/filters/filtersSlice';
import { selectJobsDetails } from 'features/jobs/jobsSelectors';

import formatOptionsWithLabel from 'utils/formatOptionsWithLabel';
import getCountriesOptions from 'utils/getCountriesOptions';

import { Dictionaries } from 'constants/enums';

import styles from './Filters.module.scss';

const Filters = (): ReactElement => {
  const { country, industry } = useSelector(selectFilters);
  const countries = useSelector(selectActiveJobCountries);
  const industries = useSelector(selectDictionary(Dictionaries.Industry));
  const { totalElements, numberOfElements } = useSelector(selectJobsDetails);
  const industriesOptions = formatOptionsWithLabel(industries);

  const countriesOptions = getCountriesOptions({ countries });

  const handleChange = useHandleChange<string | undefined>();
  const handleArrayChange = useHandleChange<string[]>();
  const handleCountryChange = handleChange(setCountry);
  const handleIndustryChange = handleArrayChange(setIndustry);

  return (
    <div className={styles.filtersMainContainer}>
      <PageControls
        sortingConfig={MODULE_SORT_CONFIG.jobs}
        numberOfElements={numberOfElements}
        totalElements={totalElements}
      />
      <div className={styles.filtersContainer}>
        <CountrySelect
          title="Country"
          value={country}
          // @ts-ignore
          onChange={handleCountryChange}
          placeholder="Please select country"
          countries={countriesOptions}
        />
        <SelectCommon
          title="Industry"
          value={industry}
          options={industriesOptions}
          onChange={handleIndustryChange}
          placeholder="Please select industry"
        />
      </div>
      <SelectedFiltersTags industries={industriesOptions} />
    </div>
  );
};

export default Filters;
