import { useSelector } from 'react-redux';

import UserService from 'services/userService';

import LoadingIndicator from 'components/LoadingIndicator';
import BackgroundInformationForm from 'components/LoggedUserProfile/BackgroundInformation/BackgroundInformationForm';

import {
  selectIsJobsLoading,
  selectProfile
} from 'features/profile/profileSelectors';

import useErrorCatch from 'hooks/useErrorCatch';

import { notify } from 'utils/toast/createToast';

import { Jobseeker } from 'types/jobseekers';

import { AlertType } from 'constants/enums';

const BackgroundInformation = () => {
  const user = useSelector(selectProfile);
  const isUserLoading = useSelector(selectIsJobsLoading);
  const catchError = useErrorCatch();

  const handleSubmitForm = async (
    fields: Partial<Jobseeker>
  ): Promise<void> => {
    try {
      UserService.updateProfile(fields);

      notify({
        type: AlertType.Success,
        message: 'Profile saved'
      });
    } catch (error) {
      catchError({
        error,
        default: {
          message: "Can't save profile",
          description: 'Please try again later'
        }
      });
    }
  };

  return (
    <div className="container">
      {isUserLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <BackgroundInformationForm
            user={user}
            loading={isUserLoading}
            handleFormSubmit={handleSubmitForm}
          />
        </>
      )}
    </div>
  );
};

export default BackgroundInformation;
