import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'antd';

import { tabs } from 'components/Tabs/TabsConfigProfile';

import { PROFILE } from 'constants/pathNames';

import { ReactComponent as ProfileArrow } from 'assets/icons/profile-bottom-arrow.svg';
import { ReactComponent as ProfileCircle } from 'assets/icons/profile-circle.svg';

import styles from '../styles.module.scss';

const UserProfile = () => {
  const navigate = useNavigate();

  const handleNavigation = (key: string) => navigate(`${PROFILE}?tab=${key}`);

  const menuItems = tabs.map(({ key, label }) => ({
    key,
    label,
    onClick: () => handleNavigation(key)
  }));

  return (
    <Dropdown
      className={styles.control}
      menu={{ items: menuItems }}
      trigger={['click']}
      placement="bottomLeft"
      rootClassName="custom-dropdown-root-container"
    >
      <div className={styles.profileContainer}>
        <ProfileCircle width={24} height={24} />
        <ProfileArrow />
      </div>
    </Dropdown>
  );
};

export default UserProfile;
