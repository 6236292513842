import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Form } from 'antd';
import cs from 'classnames';
import UserService from 'services/userService';

import ChangePassword from 'components/Authentication/ChangePassword';
import PageTitle from 'components/PageTitle/PageTitle';

import { selectUser } from 'features/auth/authSelectors';

import useErrorCatch from 'hooks/useErrorCatch';

import { notify } from 'utils/toast/createToast';

import { AlertType } from 'constants/enums';
import { PROFILE } from 'constants/pathNames';

import styles from './ResetPasswordPage.module.scss';

const ResetPasswordPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const intl = useIntl();

  const { email } = useSelector(selectUser);
  const catchError = useErrorCatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const handleFieldsChange = (): void => {
    const fieldsValue = form.getFieldsValue();
    const fieldsError = form.getFieldsError();

    const isValid =
      Object.values(fieldsValue).every(Boolean) &&
      !fieldsError.some(({ errors }) => errors.length);

    setCanSubmit(isValid);
  };

  const handleChangePassword = (): void => {
    setIsLoading(true);

    const { password, verification } = form.getFieldsValue([
      'password',
      'verification'
    ]);

    UserService.changePasswordProfile({
      email,
      password,
      verification
    })
      .then(() => {
        navigate(PROFILE);
        notify({
          type: AlertType.Success,
          description: intl.formatMessage({ id: 'auth.success.changePassword' })
        });
      })
      .catch((error) => {
        catchError({
          error,
          default: {
            message: 'Operation Failed',
            description: intl.formatMessage({ id: 'auth.error.changePassword' })
          }
        });
      })
      .finally(() => {
        setIsLoading(false);
        setCanSubmit(false);
      });
  };

  const handleCancel = () => navigate(PROFILE);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'profile.resetPassword.pageTitle' })}
      />
      <div className="container">
        <Form
          form={form}
          className={cs('form-container', styles.resetPassContainer)}
          requiredMark={false}
          autoComplete="off"
          onFieldsChange={handleFieldsChange}
        >
          <h3 className={styles.title}>
            <FormattedMessage id="profile.resetPassword.pageTitle" />
          </h3>
          <ChangePassword
            form={form}
            handleSubmit={handleChangePassword}
            handleCancel={handleCancel}
            submitLabel="form.button.save"
            canSubmit={canSubmit}
            isLoading={isLoading}
            email={email}
          />
        </Form>
      </div>
    </>
  );
};

export default ResetPasswordPage;
