import { JobApplicationStatus } from 'constants/enums';

const jobApplicationStatusMap: Record<JobApplicationStatus, string> = {
  [JobApplicationStatus.New]: 'NEW',
  [JobApplicationStatus.Pending]: 'pending',
  [JobApplicationStatus.InReview]: 'in review',
  [JobApplicationStatus.Accepted]: 'accepted',
  [JobApplicationStatus.Declined]: 'declined',
  [JobApplicationStatus.Hired]: 'hired',
  [JobApplicationStatus.Completed]: 'completed'
};

export default jobApplicationStatusMap;
