import { MimeTypes } from 'constants/enums';

const generateFileName = (
  url: string,
  fileType: string | undefined
): string => {
  let fileExtension = '';

  switch (fileType) {
    case MimeTypes.docx:
      fileExtension = 'docx';
      break;
    case MimeTypes.doc:
      fileExtension = 'doc';
      break;
    case MimeTypes.pdf:
      fileExtension = 'pdf';
      break;
    case MimeTypes.jpg:
    case MimeTypes.jpeg:
      fileExtension = 'jpg';
      break;
    case MimeTypes.png:
      fileExtension = 'png';
      break;
    default:
      throw new Error(`Unsupported file type: ${fileType}`);
  }

  const fileName = url.split('/').pop()?.split('.')[0] || 'document';

  return `${fileName}.${fileExtension}`;
};

export default generateFileName;
