import { FC, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { TabsProps } from 'types/tabs';

import Tab from './Tab';
import styles from './styles.module.scss';

const Tabs: FC<TabsProps> = ({ tabs, defaultTab }) => {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || defaultTab || tabs[0]?.key;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<Record<string, HTMLButtonElement | null>>({});

  const { width } = useWindowDimensions();
  const [totalWidth, setTotalWidth] = useState<number | 'auto'>('auto');

  useEffect(() => {
    const container = containerRef.current;
    const activeElement = tabRefs.current[activeTab];

    if (container && activeElement) {
      const containerRect = container.getBoundingClientRect();
      const tabRect = activeElement.getBoundingClientRect();

      container.style.setProperty(
        '--indicator-offset',
        `${tabRect.left - containerRect.left}px`
      );
      container.style.setProperty('--indicator-width', `${tabRect.width}px`);
    }
  }, [activeTab, width]);

  useEffect(() => {
    if (!containerRef.current) return;

    const totalTabsWidth = Object.values(tabRefs.current)
      .filter(Boolean)
      .reduce((sum, tab) => sum + (tab?.offsetWidth || 0), 0);

    setTotalWidth(totalTabsWidth);
  }, [width, tabs]);

  return (
    <div>
      <div className={styles.tabsWrapper} ref={wrapperRef}>
        <div
          className={styles.tabsContainer}
          ref={containerRef}
          style={{ width: totalWidth }}
        >
          {tabs.map(({ key, label }) => (
            <Tab
              key={key}
              tabKey={key}
              label={label}
              isActive={key === activeTab}
              ref={(el) => (tabRefs.current[key] = el)}
            />
          ))}
        </div>
      </div>

      <div className={styles.tabContentContainer}>
        {tabs.find(({ key }) => key === activeTab)?.component || (
          <div>Not found</div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
