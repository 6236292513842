import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Jobseeker } from 'types/jobseekers';
import { ProfileState } from 'types/user';

import { RequestStatus } from 'constants/enums';

import { fetchProfileData } from './profileThunks';

const defaultUser = {
  email: '',
  firstName: '',
  lastName: ''
};

const initialState: ProfileState = {
  user: defaultUser,
  isLoading: false,
  status: RequestStatus.Idle,
  error: null
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileData.pending, (state) => {
        state.status = RequestStatus.Loading;
        state.isLoading = true;
      })
      .addCase(
        fetchProfileData.fulfilled,
        (state, action: PayloadAction<Jobseeker>) => {
          state.status = RequestStatus.Succeeded;
          state.user = action.payload;
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(fetchProfileData.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        state.isLoading = false;
        state.error = action.error.message || null;
      });
  }
});

export default profileSlice.reducer;
