import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Col, Row, Tooltip } from 'antd';
import cs from 'classnames';

import ApplicationStatus from 'components/ApplicationStatus/ApplicationStatus';
import LocationDisplay from 'components/LocationDisplay/LocationDisplay';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import ScreeningProgress from 'components/ScreeningProgress/ScreeningProgress';
import TableColumn from 'components/TableColumn/TableColumn';

import { selectUserId } from 'features/auth/authSelectors';

import { Application } from 'types/jobApplications';

import { FALLBACK_VALUE, PROGRESS_GRADIENT } from 'constants/common';
import { JobApplicationStatus } from 'constants/enums';

import { ReactComponent as ChatIcon } from 'assets/icons/sms.svg';
import { ReactComponent as OfferLetterIcon } from 'assets/icons/task-square.svg';

import styles from './styles.module.scss';

const COMING_SOON_TEXT = 'Coming soon';

const SCREENING_STATUSES = new Set([
  JobApplicationStatus.New,
  JobApplicationStatus.Pending,
  JobApplicationStatus.InReview
]);

interface ActiveApplicationTableProps {
  application: Application;
}

const ActiveApplicationTable: FC<ActiveApplicationTableProps> = ({
  application
}) => {
  const navigate = useNavigate();
  const jobseekerId = useSelector(selectUserId);

  const showStarAndSeparationDatesStatuses = useMemo(
    () => new Set([JobApplicationStatus.Accepted, JobApplicationStatus.Hired]),
    []
  );

  const isShowStarAndSeparationDates = showStarAndSeparationDatesStatuses.has(
    application.status
  );

  const { jobseekerProgressPercentage = 0 } = application;

  const showScreeningProgress = SCREENING_STATUSES.has(application.status);

  const formLink = `/questionnaire/${application.jobId}/${jobseekerId}/${application.id}`;

  const handleFormClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(formLink);
  };

  const renderDateColumns = () => {
    if (isShowStarAndSeparationDates) {
      return (
        <>
          <TableColumn
            title={application?.startDate || FALLBACK_VALUE}
            label="start date"
          />
          <TableColumn
            title={application?.separationDate || FALLBACK_VALUE}
            label="separation date"
          />
        </>
      );
    }

    return (
      <>
        <TableColumn
          title={application?.clientInterviewDate || FALLBACK_VALUE}
          label="interview date"
        />
        <TableColumn
          title={application?.estimatedStartDate || FALLBACK_VALUE}
          label="estimated start date"
        />
      </>
    );
  };

  return (
    <div className={cs('table-card', styles.applicationTable)}>
      <Row>
        <Col className="table-card-item">
          <ProgressBar
            progress={jobseekerProgressPercentage}
            strokeColor={PROGRESS_GRADIENT}
          />
          <div className={styles.extraContainer}>
            <Tooltip title={COMING_SOON_TEXT}>
              <ChatIcon />
            </Tooltip>
            <Tooltip title="Form">
              <a href={formLink} onClick={handleFormClick}>
                <OfferLetterIcon />
              </a>
            </Tooltip>
          </div>
        </Col>
        <Col flex="auto">
          <Row>
            <TableColumn
              title={
                application?.status ? (
                  <ApplicationStatus status={application?.status} />
                ) : (
                  FALLBACK_VALUE
                )
              }
              label="application stage"
            />
            <TableColumn
              title={application?.jobName || FALLBACK_VALUE}
              label="job title"
              flex="auto"
            />
            {renderDateColumns()}
            <TableColumn
              title={
                application?.location ? (
                  <LocationDisplay
                    location={application?.location}
                    countryCode={application?.country.code}
                  />
                ) : (
                  FALLBACK_VALUE
                )
              }
              label="location"
            />
          </Row>
          {showScreeningProgress && (
            <ScreeningProgress
              formLink={formLink}
              preScreeningCompleted={application.preScreeningCompleted}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActiveApplicationTable;
