import { toast } from 'react-toastify';

import { Notification } from 'types/notifications';

import { DEFAULT_NOTIFICATION_SHOW_DURATION_SEC } from 'constants/common';
import { AlertType, NotificationLevels } from 'constants/enums';

import NotificationCard from '../components/NotificationCard/NotificationCard';

interface createNotificationProps {
  notification: Notification;
  onReadNotification: (id: string) => Promise<void>;
}

const showNotifications = ({
  notification,
  onReadNotification
}: createNotificationProps): void => {
  let toastId: string | number = '';

  const NOTIFICATION_LEVELS_TO_ALERT_TYPES_MAPPING: Record<
    NotificationLevels,
    AlertType
  > = {
    [NotificationLevels.Error]: AlertType.Error,
    [NotificationLevels.Info]: AlertType.Info,
    [NotificationLevels.Alert]: AlertType.Warning,
    [NotificationLevels.Confirm]: AlertType.Success
  };

  const handleReadNotification = async (id: string): Promise<void> => {
    toast.dismiss(toastId);
    onReadNotification(id);
  };

  const alertType =
    NOTIFICATION_LEVELS_TO_ALERT_TYPES_MAPPING[notification.level];

  toastId = toast[alertType](
    <NotificationCard
      onReadNotification={handleReadNotification}
      notification={notification}
    />,
    { icon: false, autoClose: DEFAULT_NOTIFICATION_SHOW_DURATION_SEC * 1000 }
  );
};

export { showNotifications };
