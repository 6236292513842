import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';

import { AlertType } from 'constants/enums';
import { MAIN } from 'constants/pathNames';

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/tick-circle.svg';

import styles from './IntakeThankYouPageIndustry.module.scss';

const IntakeThankYouPageIndustry = (): ReactElement => {
  const navigate = useNavigate();

  const handleMoveToHomePage = (): void => {
    navigate(MAIN);
  };

  return (
    <div className="container">
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <SuccessIcon />
        </div>
        <h1 className={styles.header}>
          <FormattedMessage id="intake.thankYouForInfo" />
        </h1>
        <div className={styles.alertWrapper}>
          <Alert type={AlertType.Success}>
            <FormattedMessage id="intake.inTouchNewOpportunities" />
          </Alert>
        </div>
        <Button
          type="primary"
          icon={<ArrowRight />}
          isRightIconPosition
          onClick={handleMoveToHomePage}
        >
          <FormattedMessage id="button.go_to_main_page" />
        </Button>
      </div>
    </div>
  );
};

export default IntakeThankYouPageIndustry;
