import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { AppDispatch } from 'app/store';

import PageTitle from 'components/PageTitle/PageTitle';
import TabsNav from 'components/Tabs/Tabs';
import { tabs } from 'components/Tabs/TabsConfigProfile';

import { selectAuthToken } from 'features/auth/authSelectors';
import { fetchProfileData } from 'features/profile/profileThunks';

import useErrorCatch from 'hooks/useErrorCatch';

const LoggedUserProfilePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector(selectAuthToken);
  const [searchParams] = useSearchParams();
  const catchError = useErrorCatch();
  const activeTab = searchParams.get('tab') || tabs[0]?.key;
  const getSubTitle = tabs.find(({ key }) => key === activeTab)?.label || '';

  useEffect(() => {
    if (!token) return;

    dispatch(fetchProfileData())
      .unwrap()
      .catch((error) => {
        catchError({
          error,
          default: {
            message: 'Operation Failed',
            description: 'Failed to load profile data. Please try again.'
          }
        });
      });
  }, [token, dispatch]);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'profile.page.title' })}
        subTitle={getSubTitle}
      />
      <div className="container">
        <TabsNav tabs={tabs} />
      </div>
    </>
  );
};

export default LoggedUserProfilePage;
