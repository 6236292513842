import { del, get, postFormData } from 'utils/api/api';

import { Attachment } from 'types/commons';

import { USERS_DOCUMENTS } from 'constants/endpoints';
import { UserAttachmentTypes } from 'constants/enums';

class NotificationsService {
  async getUsersDocuments(): Promise<Attachment<UserAttachmentTypes>[]> {
    const response = await get(`${USERS_DOCUMENTS}`);
    return response;
  }

  async deleteUsersDocument(): Promise<void> {
    const response = await del(`${USERS_DOCUMENTS}`);
    return response;
  }

  async postUsersDocument(
    file: Blob,
    attachmentType: UserAttachmentTypes
  ): Promise<void> {
    const formData = new FormData();

    formData.append('attachment', file);

    const response = await postFormData(
      `${USERS_DOCUMENTS}/${attachmentType}`,
      formData
    );
    return response;
  }
}

export default new NotificationsService();
