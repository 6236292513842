import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

import attachmentsReducer from 'features/attachments/attachmentsSlice';
import authReducer from 'features/auth/authSlice';
import countriesReducer from 'features/countries/countriesSlice';
import dictionariesReducer from 'features/dictionaries/dictionariesSlice';
import filtersReducer from 'features/filters/filtersSlice';
import jobApplicationsReducer from 'features/jobApplications/jobApplicationsSlice';
import jobsReducer from 'features/jobs/jobsSlice';
import localeReducer from 'features/locale/localeSlice';
import notificationsReducer from 'features/notifications/notificationsSlice';
import profileReducer from 'features/profile/profileSlice';
import sidebarReducer from 'features/sidebar/sidebarSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    jobApplications: jobApplicationsReducer,
    locale: localeReducer,
    dictionaries: dictionariesReducer,
    sidebar: sidebarReducer,
    filters: filtersReducer,
    jobs: jobsReducer,
    profile: profileReducer,
    countries: countriesReducer,
    notifications: notificationsReducer,
    attachments: attachmentsReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
