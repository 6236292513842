import { RootState } from 'app/store';

import { Notification } from 'types/notifications';

export const selectUnreadNotifications = (state: RootState): Notification[] =>
  state.notifications.unreadNotifications;

export const selectUnreadNotificationsToShow = (
  state: RootState
): Notification[] => state.notifications.unreadNotificationsToShow;

export const selectReadNotifications = (state: RootState): Notification[] =>
  state.notifications.readNotifications;

export const selectUnreadNotificationsCount = (state: RootState): number => {
  return state.notifications.unreadCount;
};

export const selectNotificationsLoading = (state: RootState): boolean =>
  state.notifications.isLoading;
