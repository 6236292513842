import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { pdf } from '@react-pdf/renderer';
import { Form } from 'antd';
import type { UploadFile } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ApplicationService from 'services/applicationService';
import JobsService from 'services/jobsService';

import ExtraHeader from 'components/ExtraHeader';
import LoadingIndicator from 'components/LoadingIndicator';
import QuestionnaireForm from 'components/QuestionnaireForm';
import QuestionnairePDF from 'components/QuestionnairePDF/QuestionnairePDF';

import { selectUser } from 'features/auth/authSelectors';
import { selectAllDictionaries } from 'features/dictionaries/dictionariesSelectors';

import { notify } from 'utils/toast/createToast';

import { Application } from 'types/jobApplications';
import { Job } from 'types/jobs';

import { DATE_FORMAT } from 'constants/common';
import { AlertType } from 'constants/enums';
import { QUESTIONNAIRE_THANK_YOU } from 'constants/pathNames';

const removeFields = ['confirmationResponse', 'confirmationRead'];

const QuestionnairePage = (): ReactElement | null => {
  const { jobId, userId, applicationId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { email, firstName, lastName } = useSelector(selectUser);
  const dictionaries = useSelector(selectAllDictionaries);

  const [state, setState] = useState({
    isLoading: true,
    disableButtons: false,
    jobData: null as Job | null,
    application: null as Application | null,
    resume: null as UploadFile | null
  });

  const fieldsValues = Form.useWatch([], form);

  if (!jobId || !userId || !applicationId) {
    return null;
  }

  const fetchData = async () => {
    setState((prev) => ({ ...prev, isLoading: true }));
    try {
      const [job, application] = await Promise.all([
        JobsService.getJobById(jobId),
        ApplicationService.getApplication(applicationId)
      ]);
      setState((prev) => ({ ...prev, jobData: job, application }));
    } catch {
      handleError('Error loading data');
    } finally {
      setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const handleError = (message: string) => {
    notify({
      type: AlertType.Error,
      description: message
    });
  };

  const handleResumeChange = (file: UploadFile | null): void => {
    setState((prev) => ({ ...prev, resume: file }));
  };

  const generatePdfDocument = async (
    fields: Partial<Application>
  ): Promise<Blob> =>
    pdf(
      <QuestionnairePDF
        application={fields}
        job={jobData || {}}
        dictionaries={dictionaries}
      />
    ).toBlob();

  const handleSubmit = async (): Promise<void> => {
    setState((prev) => ({ ...prev, disableButtons: true }));
    const { resume, jobData } = state;
    try {
      const fields: {
        startDate?: Dayjs;
        alternateInterviewDate?: Dayjs;
        applicationId: string;
      } = {
        ...Object.fromEntries(
          Object.entries(fieldsValues).filter(
            ([key]) => !removeFields.includes(key)
          )
        ),
        applicationId
      };

      const formattedFields = {
        ...fields,
        startDate: dayjs(fields.startDate).format(DATE_FORMAT),
        alternateInterviewDate: dayjs(fields.alternateInterviewDate).format(
          DATE_FORMAT
        )
      };

      const copy = await generatePdfDocument(formattedFields);
      const copyName = `questionnaire_${applicationId}.pdf`;

      const formData = new FormData();
      if (resume) formData.append('cv', resume as unknown as Blob);
      formData.append('copy', copy, copyName);
      formData.append(
        'request',
        new Blob([JSON.stringify(formattedFields)], {
          type: 'application/json'
        })
      );

      await ApplicationService.postPreScreeningFormData(
        userId,
        jobId,
        formData
      );
      navigate(QUESTIONNAIRE_THANK_YOU, {
        state: {
          jobIndustry: jobData?.industry,
          thankYouMessage: 'screening.thankYou.submission'
        }
      });
    } catch {
      handleError('Error handling form submission. Please try again later');
    } finally {
      setState((prev) => ({ ...prev, disableButtons: false }));
    }
  };

  const handleSendLinkToEmail = async (): Promise<void> => {
    setState((prev) => ({ ...prev, disableButtons: true }));
    const { jobData } = state;

    const data = {
      job_title: jobData?.title || '',
      job_id: jobId,
      application_id: applicationId,
      email,
      first_name: firstName,
      last_name: lastName
    };

    try {
      await ApplicationService.postPreScreeningEmail(userId, data);

      navigate(QUESTIONNAIRE_THANK_YOU, {
        state: {
          jobIndustry: jobData?.industry,
          thankYouMessage: 'screening.thankYou.email'
        }
      });
    } catch {
      handleError('Error handling sending email. Please try again later');
    } finally {
      setState((prev) => ({ ...prev, disableButtons: false }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [jobId, applicationId]);

  const { isLoading, jobData, application, disableButtons } = state;

  return (
    <>
      <ExtraHeader
        title="screening.header.title"
        description="screening.header.description"
      />
      {isLoading || !jobData || !application ? (
        <LoadingIndicator />
      ) : (
        <QuestionnaireForm
          form={form}
          disable={disableButtons}
          jobData={jobData}
          application={application}
          onChangeResume={handleResumeChange}
          handleSubmit={handleSubmit}
          handleSendLinkToEmail={handleSendLinkToEmail}
        />
      )}
    </>
  );
};

export default QuestionnairePage;
