import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import * as Flags from 'country-flag-icons/react/3x2';

import { selectCountries } from 'features/countries/countriesSelectors';

import { flagCodes } from 'constants/flagCodes';

import styles from './CountryPreview.module.scss';

type FlagsType = { [key: string]: FC<any> };

interface CountryPreviewProps {
  className?: string;
  countryCode?: string | null;
  countryName?: string | null;
  isCountryCodeLabel?: boolean;
  iconOnly?: boolean;
}

const LONG_COUNTRY_CODE_LENGTH = 3;

const CountryPreview: FC<CountryPreviewProps> = ({
  className = '',
  countryCode,
  countryName = '',
  isCountryCodeLabel = false,
  iconOnly = false,
  ...rest
}) => {
  const countries = useSelector(selectCountries);

  // Get country code. Now it may be 3 or 2 digits
  let code = countryCode?.toUpperCase() || null;
  if (!code && countryName) {
    code = countries.find((c) => c.name === countryName)?.code || null;
  }
  if (code && code.length === LONG_COUNTRY_CODE_LENGTH) {
    code = countries.find((c) => c.longCode === code)?.code || null;
  }
  if (!code) return null;

  // Get country name
  let name =
    countryName ||
    countries.find((c) => c.code === code || c.longCode === code)?.name ||
    null;

  // Get flag
  const FlagIcon = flagCodes.includes(code) ? (Flags as FlagsType)[code] : null;
  const icon = FlagIcon ? (
    <FlagIcon className={styles.imgIcon} {...rest} />
  ) : null;

  if (iconOnly) return icon;

  return (
    <div
      className={classnames(styles.countryPreview, className)}
      title={name || undefined}
    >
      {icon}
      {isCountryCodeLabel ? code : name}
    </div>
  );
};

export default memo(CountryPreview);
