export enum LanguageLevel {
  Native = 'NATIVE',
  Beginner = 'BEGINNER',
  Intermediate = 'INTERMEDIATE',
  Advanced = 'ADVANCED'
}

export enum AuthSteps {
  CheckEmail = 'CHECK EMAIL',
  Login = 'LOGIN',
  Registration = 'REGISTRATION',
  ForgotPassword = 'FORGOT PASSWORD',
  SetPassword = 'SET PASSWORD'
}

export enum ErrorMessageType {
  Error = 'error',
  Warning = 'warning'
}

export enum AuthMessages {
  IncorrectPassword = 'Bad credentials'
}

export enum Dictionaries {
  Language = 'LANGUAGE',
  JobStatus = 'JOB_STATUS',
  Industry = 'INDUSTRY',
  ClientCountry = 'CLIENT_COUNTRY',
  LanguageLevel = 'LANGUAGE_LEVEL',
  Skillset = 'SKILLSET',
  ApplicantStatus = 'APPLICANT_STATUS',
  ClientPrivatePublic = 'CLIENT_PRIVATE_PUBLIC',
  ClientStatus = 'CLIENT_STATUS',
  ClientDomesticOver = 'CLIENT_DOMESTIC_OVER',
  ClientYearsInBus = 'CLIENT_YEARS_IN_BUS',
  JobType = 'JOB_TYPE',
  PayrollRates = 'PAYROLL_RATES',
  JobseekerStage = 'JOBSEEKER_STAGE',
  Education = 'EDUCATION',
  JobShift = 'JOB_SHIFT',
  ImmigrationRoutes = 'IMMIGRATION_ROUTES',
  SourcePlatform = 'SOURCE_PLATFORM',
  JobseekerStageTag = 'JOBSEEKER_STAGE_TAG',
  ApplicationStage = 'APPLICATION_STAGE',
  ApplicationStageTag = 'APPLICATION_STAGE_TAG',
  JobFeeStructure = 'JOB_FEE_STRUCTURE'
}

export enum RequestStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'succeeded',
  Failed = 'failed'
}

export enum JobAttachmentTypes {
  TaoContract = 'TAO_CONTRACT',
  ExtraPaperWork = 'EXTRA_PAPERWORK',
  JobImage = 'JOB_IMAGE'
}

export enum JobseekerAttachmentTypes {
  OfferLetter = 'OFFER_LETTER',
  Passport = 'PASSPORT',
  IdCard = 'ID_CARD',
  Visa = 'VISA',
  WorkPermit = 'WORK_PERMIT',
  CV = 'CV',
  UserPrivateDocument = 'USER_PRIVATE_DOCUMENT',
  DriverLicense = 'DRIVER_LICENSE'
}

export enum JobFeeStructures {
  Ace = 'ACE',
  DirectHire = 'DIRECT_HIRE',
  HourlyMarkup = 'HOURLY_MARKUP'
}

export enum MimeTypes {
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf = 'application/pdf',
  jpg = 'image/jpeg',

  jpeg = 'image/jpeg',
  png = 'image/png'
}

export enum JobTypes {
  Contractor = 'CONTRACTOR',
  FullTime = 'FULL_TIME',
  PartTime = 'PART_TIME',
  Remote = 'REMOTE',
  Seasonal = 'SEASONAL'
}

export enum JobseekerStage {
  AllJobseekerStage = 'ALL_JOBSEEKER_STAGE',
  ActiveCandidateJobseekerStage = 'ACTIVE_CANDIDATE_JOBSEEKER_STAGE',
  ProcessingJobseekerStage = 'PROCESSING_JOBSEEKER_STAGE',
  ClosedHireJobseekerStage = 'CLOSED_HIRE_JOBSEEKER_STAGE',
  TerminatedJobseekerStage = 'TERMINATED_JOBSEEKER_STAGE',
  ToClarifyJobseekerStage = 'TO_CLARIFY_JOBSEEKER_STAGE',
  InactiveJobseekerStage = 'INACTIVE_JOBSEEKER_STAGE',
  WaitingListJobseekerStage = 'WAITING_LIST_JOBSEEKER_STAGE'
}

export enum JobseekerTags {
  ClosedHireJobseekerStageOfferLetterSigned = 'CLOSED_HIRE_JOBSEEKER_STAGE_OFFER_LETTER_SIGNED',
  ClosedHireJobseekerStageOnSite = 'CLOSED_HIRE_JOBSEEKER_STAGE_ON_SITE',
  ClosedHireJobseekerStageReplacement = 'CLOSED_HIRE_JOBSEEKER_STAGE_REPLACEMENT',
  InactiveJobseekerStageUnder18 = 'INACTIVE_JOBSEEKER_STAGE_UNDER_18',
  InactiveJobseekerStageNotEligible = 'INACTIVE_JOBSEEKER_STAGE_NOT_ELIGIBLE',
  InactiveJobseekerStageNotInterested = 'INACTIVE_JOBSEEKER_STAGE_NOT_INTERESTED',
  InactiveJobseekerStageUnresponsive = 'INACTIVE_JOBSEEKER_STAGE_UNRESPONSIVE',
  InactiveJobseekerStageRiskProfile = 'INACTIVE_JOBSEEKER_STAGE_RISK_PROFILE',
  ProcessingJobseekerStageClientInterview = 'PROCESSING_JOBSEEKER_STAGE_CLIENT_INTERVIEW',
  ProcessingJobseekerStageInternalInterview = 'PROCESSING_JOBSEEKER_STAGE_INTERNAL_INTERVIEW',
  TerminatedJobseekerStageQuit = 'TERMINATED_JOBSEEKER_STAGE_QUIT',
  TerminatedJobseekerStageLaidOff = 'TERMINATED_JOBSEEKER_STAGE_LAID_OFF'
}

export enum Departments {
  Clients = 'CLIENTS'
}

export enum ProviderType {
  Local = 'local',
  No = 'no'
}

export enum PDFTitles {
  Availability = 'Availability',
  Experience = 'Experience',
  Location = 'Location & Relocation',
  Legal = 'Legal & Work Authorization',
  SalaryExpectations = 'Salary Expectations',
  AvailabilityForInterview = 'Availability for Interview'
}

export enum AlertType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}

export enum GTMEvents {
  SessionStart = 'join_session_start', // triggered when user opens the app
  SelectIndustry = 'join_select_industry', // triggered when user selects an industry from the dropdown on header
  SelectCountry = 'join_select_country', // triggered when user selects a country from the dropdown on filters or apply jobs page
  SelectUSA = 'join_select_country_usa', // triggered when user clicks on a "Select Country" drop-down on filters or apply jobs page and chooses USA
  SelectCanada = 'join_select_country_canada', // triggered when user clicks on a "Select Country" drop-down on filters or apply jobs page and chooses Canada
  BrowseAllJobs = 'join_browsejobs', // triggered when user clicks on the browse all jobs button
  NavigateIntakePage = 'join_access_intake', // triggered when user is redirected to the intake page
  ClickOnJobDetails = 'join_job_details', // triggered when user clicks on a job card details
  ClickOnContinueLogin = 'join_continue_log', // triggered when user clicks on continue after filling the email in the log in module
  ClickOnContinueVerificationCode = 'join_continue_auth', // triggered when user click on continue after filling the verification code and the password
  ClickOnJobApplyTop = 'join_job_apply_start', // triggered when user clicks on the apply top button on job details page
  SelectIndustryOnIntakePage = 'join_industry_intake', // triggered when user selects Looking for a job in the following industries from the dropdown on the intake page
  SelectSkillsOnIntakePage = 'join_skills_intake', // triggered when user selects What are your skills? from the dropdown on the intake page
  SelectCountryOfOrigin = 'join_location_country', // triggered when user selects country of residence from the dropdown on apply jobs page
  SelectCitizenship = 'join_citizenship_country', // triggered when user selects citizenship from the dropdown on apply jobs page
  ClickOnJobSubmit = 'join_job_submit_finish', // triggered when user clicks on the apply button on job details page
  ClickOnJoinEnterEmail = 'join_enter_email', // triggered when user clicks on the enter email button on auth page
  ClickOnJoinEnterPassword = 'join_enter_password', // triggered when user clicks on the enter password button on auth page
  ClickOnTermsConditionsOnIntake = 'join_agree_t&c', // triggered when user clicks on the terms and conditions checkbox / employer or in apply jobs page
  ClickOnDarkMode = 'join_dark_mode', // triggered when user clicks on the dark mode toggle in the header
  ClickOnLoginLink = 'join_login', // triggered when user clicks on the login link in the header
  ClickOnClearFilter = 'join_clear_filter', // triggered when user clicks on the clear button on filter modal
  NavigateOnCongratulationsPage = 'join_congratulations', // triggered when user is redirected to congratulations page
  ClickOnTermsOfUse = 'join_footer_termsofuse', // triggered when user clicks on Terms of Use from footer
  ClickOnCookiePolicy = 'join_footer_cookiepolicy ', // triggered when user clicks on Cookie Policy from footer
  ClickOnPrivacyPolicy = 'join_footer_privacypolicy ', // triggered when user clicks on Privacy Policy from footer
  ClickOnDataPrivacy = 'join_footer_dataprivacy ' // triggered when user clicks on Data Privacy Rights from footer
}

export enum ApplicationTags {
  DeclinedApplicationStageClientRefused = 'DECLINED_APPLICATION_STAGE_CLIENT_REFUSED',
  AlreadyHired = 'ALREADY_HIRED',
  DeclinedApplicationStageJobseekerRefused = 'DECLINED_APPLICATION_STAGE_JOBSEEKER_REFUSED',
  InternalReviewApplicationStageInternalInterview = 'INTERNAL_REVIEW_APPLICATION_STAGE_INTERNAL_INTERVIEW',
  InternalReviewApplicationStageProfileReview = 'INTERNAL_REVIEW_APPLICATION_STAGE_PROFILE_REVIEW',
  TerminatedApplicationStageBillingDispute = 'TERMINATED_APPLICATION_STAGE_BILLING_DISPUTE',
  TerminatedApplicationStageQuit = 'TERMINATED_APPLICATION_STAGE_QUIT',
  TerminatedApplicationStageLaidOff = 'TERMINATED_APPLICATION_STAGE_LAID_OFF'
}

export enum NotificationLevels {
  Alert = 'ALERT',
  Confirm = 'CONFIRMATIONAL',
  Error = 'ERROR',
  Info = 'INFORMATIONAL'
}

export enum SidebarContentTypes {
  Authentication = 'authentication',
  NotificationsView = 'notificationsView'
}

export enum JobApplicationStatus {
  New = 'NEW',
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  Accepted = 'ACCEPTED_APPLICANT_STATUS',
  Declined = 'DECLINED',
  Hired = 'HIRED',
  Completed = 'COMPLETED'
}

export enum UsersAttachmentTypes {
  UserAvatar = 'USER_AVATAR',
  ClientContract = 'CLIENT_CONTRACT',
  TaoContract = 'TAO_CONTRACT',
  ExtraPaperWork = 'EXTRA_PAPERWORK',
  JobImage = 'JOB_IMAGE',
  OfferLetter = 'OFFER_LETTER',
  Passport = 'PASSPORT',
  IdCard = 'ID_CARD',
  Visa = 'VISA',
  WorkPermit = 'WORK_PERMIT',
  CV = 'CV',
  DriverLicense = 'DRIVER_LICENSE',
  PreScreeningCopy = 'PRE_SCREENING_COPY',
  UserPrivateDocument = 'USER_PRIVATE_DOCUMENT',
  ICContract = 'IC_CONTRACT',
  PartnerContract = 'PARTNER_CONTRACT',
  AmbassadorContract = 'AMBASSADOR_CONTRACT',
  OtherJobseekerDocuments = 'OTHER_JOBSEEKER_DOCUMENTS'
}

export enum DialogModalTypes {
  Delete = 'delete'
}

export enum SpinnerSizes {
  Medium = 36,
  Large = 48
}

export enum RedirectTypes {
  Logout = 'logout'
}
