import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { DefaultOptionType } from 'antd/es/select';

import CountryPreview from 'components/CountryPreview/CountryPreview';
import SkillsetSelect from 'components/FormCommon/SkillsetSelect';
import MultiSelectCustom from 'components/MultiSelectCustom';
import PhoneSelect from 'components/PhoneSelect/PhoneSelect';
import ReadMore from 'components/ReadMore/ReadMore';
import SelectLanguageLevels from 'components/SelectLanguageLevels/SelectLanguageLevels';

import { selectCountries } from 'features/countries/countriesSelectors';
import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import useCountryCitySelect from 'hooks/useCountryCitySelect';

import { GTMEventLogger } from 'utils/analytics';
import getCountriesOptions from 'utils/getCountriesOptions';
import parseCity from 'utils/parseCity';

import { CountryOption } from 'types/commons';
import { Jobseeker } from 'types/jobseekers';

import { PRIVACY_PATH, TERMS_PATH } from 'constants/common';
import { SECTION_ROW_GUTTER } from 'constants/common';
import { Dictionaries, GTMEvents } from 'constants/enums';
import { decisionBooleanArray } from 'constants/formData';

interface IntakeFormProps {
  form: FormInstance;
  loading: boolean;
  onFormSubmit: (fields: Partial<Jobseeker>) => Promise<void>;
  initialValues?: Record<string, any>;
}

const IntakeForm = ({
  form,
  loading,
  onFormSubmit,
  initialValues = {}
}: IntakeFormProps): ReactElement => {
  const intl = useIntl();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState<boolean>(false);
  const fieldsValues = Form.useWatch([], form);
  const countries = useSelector(selectCountries);

  const defaultValidateMessages = {
    required: 'Field is required'
  };

  const { countrySelect, citySelect, rawString, setRawString } =
    useCountryCitySelect({
      form,
      isCountryRequired: true,
      isCityRequired: true
    });

  const countriesOptions = getCountriesOptions({
    countries,
    useNameAsValue: true
  });

  const industriesDict = useSelector(selectDictionary(Dictionaries.Industry));
  const jobShiftDict = useSelector(selectDictionary(Dictionaries.JobShift));

  useEffect(() => GTMEventLogger(GTMEvents.NavigateIntakePage), []);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => setIsFormValid(true),
      () => setIsFormValid(false)
    );
  }, [fieldsValues]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    const { location } = initialValues;

    if (!location) {
      return;
    }

    const cityRawString = [
      location.city,
      location.state,
      location.country
    ].join(',');

    setRawString(cityRawString);
  }, [initialValues, form, setRawString]);

  const handleSubmitForm = (): void => {
    const location = parseCity(rawString);
    const fields = {
      ...fieldsValues,
      location
    };

    onFormSubmit(fields);
  };

  const handleSelectChange = (
    value: string,
    fieldName: string,
    GTMEventName?: GTMEvents
  ): void => {
    if (GTMEventName) {
      GTMEventLogger(GTMEventName);
    }

    form.setFieldsValue({ [fieldName]: value });
    form.validateFields([fieldName]);
  };

  const onChangePrivacyCheckbox = (e: CheckboxChangeEvent): void => {
    GTMEventLogger(GTMEvents.ClickOnTermsConditionsOnIntake);
    setIsPrivacyChecked(e.target.checked);
  };

  const isFieldDisabled = (fieldName: string): boolean =>
    initialValues && initialValues[fieldName] !== undefined;

  const privacyPolicyLink = (
    <a
      href={PRIVACY_PATH}
      target="_blank"
      rel="noreferrer"
      aria-label={intl.formatMessage({ id: 'form.intake.policyLink' })}
    >
      <FormattedMessage id="form.intake.policyLink" />
    </a>
  );

  const termsLink = (
    <a
      href={TERMS_PATH}
      target="_blank"
      rel="noreferrer"
      aria-label={intl.formatMessage({ id: 'form.intake.termsLink' })}
    >
      <FormattedMessage id="form.intake.termsLink" />
    </a>
  );

  const renderCountryOption = ({
    data
  }: {
    data: CountryOption;
  }): ReactElement => <CountryPreview countryName={data.label} />;

  const renderCountryLabel = (option: DefaultOptionType): ReactNode => (
    <CountryPreview countryName={String(option.label)} />
  );

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      validateMessages={defaultValidateMessages}
      initialValues={{ ...initialValues }}
    >
      <Row gutter={SECTION_ROW_GUTTER}>
        <Col span={24} md={12}>
          <Form.Item
            name="firstName"
            label={intl.formatMessage({ id: 'form.intake.firstName' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.firstName'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="lastName"
            label={intl.formatMessage({ id: 'form.intake.lastName' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.lastName'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          {countrySelect}
        </Col>
        <Col span={24} md={12}>
          {citySelect}
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="mailingAddress"
            label={intl.formatMessage({ id: 'form.intake.mailAddress' })}
            tooltip={{
              title: intl.formatMessage({ id: 'form.intake.mailAddress.info' }),
              icon: <InfoCircleOutlined />
            }}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.mailAddress.placeholder'
              })}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="citizenship"
            label={intl.formatMessage({ id: 'form.intake.citizenshipNew' })}
            rules={[{ required: true }]}
          >
            <Select
              options={countriesOptions}
              optionRender={renderCountryOption}
              labelRender={renderCountryLabel}
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.citizenshipNew'
              })}
              onChange={(value) =>
                handleSelectChange(
                  value,
                  'citizenship',
                  GTMEvents.SelectCitizenship
                )
              }
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="phoneNumber"
            label={intl.formatMessage({ id: 'form.intake.phone' })}
            rules={[{ required: true }]}
          >
            <PhoneSelect name="phoneNumber" form={form} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="email"
            label={intl.formatMessage({ id: 'form.intake.email' })}
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.email'
              })}
              disabled={isFieldDisabled('email')}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <SelectLanguageLevels
            form={form}
            name="languages"
            label={intl.formatMessage({ id: 'form.intake.languages' })}
            placeholder={intl.formatMessage({
              id: 'form.intake.placeholder.languages'
            })}
          />
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="skills"
            label={intl.formatMessage({ id: 'form.intake.professionNew' })}
            rules={[{ required: true }]}
          >
            <SkillsetSelect
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.professionNew'
              })}
              title=""
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12} className="offset-right">
          <Form.Item
            name="lookingForJobInAreas"
            label={intl.formatMessage({ id: 'form.intake.occupationNew' })}
            rules={[{ required: true }]}
          >
            <MultiSelectCustom
              name="lookingForJobInAreas"
              labelId="form.intake.occupationNew"
              form={form}
              options={industriesDict}
              placeholderId="form.intake.placeholder.occupationNew"
              GTMEventName={GTMEvents.SelectIndustryOnIntakePage}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="weekendOvertime"
            label={intl.formatMessage({
              id: 'screening.form.label.areYouOpenToWork'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={decisionBooleanArray}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="preferredSchedule"
            label={intl.formatMessage({
              id: 'screening.form.label.selectShift'
            })}
            rules={[{ required: true }]}
          >
            <Checkbox.Group options={jobShiftDict} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="felonyConviction"
            label={intl.formatMessage({
              id: 'screening.form.label.felonyConviction'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={decisionBooleanArray}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="backgroundCheckConsent"
            label={intl.formatMessage({
              id: 'screening.form.label.backgroundCheck'
            })}
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={decisionBooleanArray}
              className="vertical-list"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="max-height-group read-more-checkbox"
            rules={[{ required: true }]}
          >
            <Checkbox onChange={onChangePrivacyCheckbox}>
              <FormattedMessage
                id="form.intake.policy"
                values={{
                  privacyLink: privacyPolicyLink,
                  termsLink: termsLink
                }}
              />
              <ReadMore text="By clicking the “SUBMIT” button and submitting this form, I affirm that I have read and agree to this Site’s Terms & Conditions and Privacy Policy. I consent to receive SMS text messages to my cell number provided above for notifications, alerts, and general communication purposes including promotions from Joblio Inc. I understand that I am not required to provide my consent as a condition of purchasing any products or services. I understand that I can opt-out of receiving text messages at any time by responding with STOP. I can reply with HELP to get help. Message and data rates may apply depending on your mobile carrier. Message frequency may vary." />
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className="intake-submit-container">
        <Button
          type="primary"
          loading={loading}
          className="btn btn-primary intake-button"
          htmlType="submit"
          onClick={handleSubmitForm}
          disabled={!isFormValid || !isPrivacyChecked}
        >
          <FormattedMessage id="form.intake.submit" />
        </Button>
      </div>
    </Form>
  );
};

export default IntakeForm;
