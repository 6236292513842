import { FC, ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { UploadFile } from 'antd';
import { Upload } from 'antd';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';

interface UploadResumeProps {
  onChangeResume: (file: UploadFile | null) => void;
  disabled?: boolean;
  defaultFileList?: UploadFile[];
}

const UploadResume: FC<UploadResumeProps> = ({
  onChangeResume,
  disabled,
  defaultFileList = []
}): ReactElement => {
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState<UploadFile[]>(defaultFileList);

  const handleBeforeUpload = (file: UploadFile): boolean => {
    setFileList([file]);
    onChangeResume(file);
    return false;
  };

  const handleRemove = (): void => {
    setFileList([]);
    onChangeResume(null);
  };

  return (
    <div className="upload-button-container">
      <Dragger
        fileList={fileList}
        beforeUpload={handleBeforeUpload}
        accept=".pdf,.doc,.docx"
        maxCount={1}
        onRemove={handleRemove}
        disabled={disabled}
      >
        <UploadIcon />
        <span>
          <FormattedMessage id="form.control.label.uploadFile" />
        </span>
      </Dragger>
    </div>
  );
};

export default UploadResume;
