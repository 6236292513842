/* eslint-disable no-param-reassign */
const adjustIframeImages = (iframe: HTMLIFrameElement | null): void => {
  const iframeDocument =
    iframe?.contentDocument || iframe?.contentWindow?.document;

  if (!iframeDocument) {
    return;
  }

  const body = iframeDocument.body;
  body.style.display = 'flex';
  body.style.alignItems = 'start';
  body.style.justifyContent = 'center';
  const images: HTMLCollectionOf<HTMLImageElement> =
    iframeDocument.getElementsByTagName('img');

  Array.from(images).forEach((img) => {
    img.style.maxWidth = '100%';
    img.style.height = 'auto';
    img.style.display = 'block';
  });
};

export default adjustIframeImages;
