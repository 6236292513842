import { FC, MouseEvent, useState } from 'react';

import { Skeleton } from 'antd';
import cs from 'classnames';
import { StatusCodes } from 'http-status-codes';

import DialogModal from 'components/DialogModal/DialogModal';
import FileViewModal from 'components/FileViewModal/FileModalView';
import downloadFile from 'components/FileViewModal/utils/downloadFile';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import useErrorCatch from 'hooks/useErrorCatch';
import useFileLoader from 'hooks/useFileLoader';

import { del } from 'utils/api/api';
import { isAttachmentToDownload } from 'utils/isAttachmentToDownload';

import { AttachmentTypes } from 'types/attachments';
import { Attachment as AttachmentModel } from 'types/attachments';

import { USERS_DOCUMENTS } from 'constants/endpoints';
import { DialogModalTypes, MimeTypes, SpinnerSizes } from 'constants/enums';
import { NO_USER_PERMISSION_MESSAGE_TEXT } from 'constants/notifications';

import { ReactComponent as FileDownloadIcon } from 'assets/icons/fileDownload.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/paper.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';

import styles from './Attachment.module.scss';

interface AttachmentProps {
  attachment: AttachmentModel<AttachmentTypes>;
  onRemoveFile?: () => void;
  isConfirmDelete?: boolean;
  isPreviewFile?: boolean;
  disabled?: boolean;
}

const Attachment: FC<AttachmentProps> = ({
  attachment,
  onRemoveFile,
  isConfirmDelete = false,
  isPreviewFile = false,
  disabled = false
}) => {
  const {
    filename: name,
    sizeKb,
    createdDate,
    id,
    type,
    fileType
  } = attachment;

  const url = `${USERS_DOCUMENTS}/${id}`;
  const { data, isLoading: isFileLoading, loadFile } = useFileLoader(url);
  const catchError = useErrorCatch();

  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState<boolean>(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);

  const triggerFileDownload = (fileData: string, fileName: string): void => {
    downloadFile({
      catchError,
      dataUrl: fileData,
      name: fileName
    });
  };

  const iconClassName = disabled ? 'icon-disabled' : 'icon-enabled';

  const ATTACHMENT_ICONS: Record<MimeTypes, JSX.Element> = {
    [MimeTypes.doc]: <DocumentIcon className={iconClassName} />,
    [MimeTypes.docx]: <DocumentIcon className={iconClassName} />,
    [MimeTypes.pdf]: <DocumentIcon className={iconClassName} />,
    [MimeTypes.jpeg]: <ImageIcon className={iconClassName} />,
    [MimeTypes.png]: <ImageIcon className={iconClassName} />
  };

  const icon = ATTACHMENT_ICONS[fileType] || null;

  const deleteFile = async (): Promise<void> => {
    if (disabled) {
      return;
    }

    try {
      setIsRemoving(true);
      await del(url);
      if (onRemoveFile) {
        onRemoveFile();
      }
    } catch (error) {
      catchError({
        error,
        default: { message: 'Error deleting file' },
        additionalErrorMessageMap: {
          [StatusCodes.FORBIDDEN]: { message: NO_USER_PERMISSION_MESSAGE_TEXT }
        }
      });
    } finally {
      setIsRemoving(false);
    }
  };

  const handleFileDownload = async (e?: MouseEvent): Promise<void> => {
    e?.stopPropagation();

    if (disabled) {
      return;
    }

    try {
      if (data) {
        triggerFileDownload(data, name);
        return;
      }
      const { data: fileData } = await loadFile();

      if (fileData) {
        triggerFileDownload(fileData, name);
      }
    } catch (error) {
      catchError({
        error,
        default: { message: 'Error downloading file' },
        additionalErrorMessageMap: {
          [StatusCodes.FORBIDDEN]: { message: NO_USER_PERMISSION_MESSAGE_TEXT }
        }
      });
    }
  };

  const handleFilePreviewOrDownload = async (e: MouseEvent): Promise<void> => {
    e.stopPropagation();

    if (disabled) {
      return;
    }

    if (!isPreviewFile || isAttachmentToDownload(attachment)) {
      handleFileDownload();
      return;
    }

    setIsPreviewModalOpen(true);
  };

  const handleFilePreviewModalClose = (): void => {
    setIsPreviewModalOpen(false);
  };

  const handleFileRemove = async (
    event: MouseEvent<SVGSVGElement>
  ): Promise<void> => {
    event.stopPropagation();

    if (isConfirmDelete) {
      setIsConfirmDeleteModalOpen(true);
      return;
    }

    deleteFile();
  };

  const handleDeleteConfirm = (): void => {
    setIsConfirmDeleteModalOpen(false);
    deleteFile();
  };

  const handleDeleteReject = (): void => {
    setIsConfirmDeleteModalOpen(false);
  };

  return (
    <>
      {!isRemoving ? (
        <div
          className={cs(styles.attachment, {
            [styles.attachmentLoading]: isFileLoading,
            [styles.attachmentDisabled]: disabled
          })}
          onClick={handleFilePreviewOrDownload}
        >
          {isFileLoading ? (
            <div className={styles.attachmentSpinner}>
              <LoadingSpinner size={SpinnerSizes.Medium} />
            </div>
          ) : (
            <div className={styles.attachmentIcon}>{icon}</div>
          )}

          <div className={styles.attachmentData}>
            <div
              className={
                disabled ? styles.attachmentNameDisabled : styles.attachmentName
              }
            >
              {name}
            </div>
            <div
              className={
                disabled ? styles.attachmentSizeDisabled : styles.attachmentSize
              }
            >
              {`${createdDate}  ${sizeKb} kb`}
            </div>
          </div>
          <FileDownloadIcon
            onClick={handleFileDownload}
            className={iconClassName}
          />
          {
            <TrashIcon
              onClick={handleFileRemove}
              className={disabled ? 'icon-trash-disabled' : 'icon-trash'}
            />
          }
        </div>
      ) : (
        <Skeleton.Input active size="large" block />
      )}
      {isConfirmDeleteModalOpen && (
        <DialogModal
          type={DialogModalTypes.Delete}
          isOpen={isConfirmDeleteModalOpen}
          onConfirm={handleDeleteConfirm}
          onClose={handleDeleteReject}
          description={
            <>
              Are you sure you want to delete <span>{name}</span>?
            </>
          }
        />
      )}
      {isPreviewModalOpen && (
        <FileViewModal
          isOpen
          onClose={handleFilePreviewModalClose}
          title={`Preview file: ${name}`}
          attachment={attachment}
        />
      )}
    </>
  );
};

export default Attachment;
