import { FC } from 'react';

import { Row } from 'antd';
import cs from 'classnames';

import ApplicationStatus from 'components/ApplicationStatus/ApplicationStatus';
import LocationDisplay from 'components/LocationDisplay/LocationDisplay';
import TableColumn from 'components/TableColumn/TableColumn';

import { Application } from 'types/jobApplications';

import { FALLBACK_VALUE } from 'constants/common';

import styles from './styles.module.scss';

interface InActiveApplicationTableProps {
  application: Application;
}

const InActiveApplicationTable: FC<InActiveApplicationTableProps> = ({
  application
}) => {
  return (
    <div className="table-card">
      <Row>
        <TableColumn
          title={
            application?.status ? (
              <ApplicationStatus status={application?.status} />
            ) : (
              FALLBACK_VALUE
            )
          }
          label="application stage"
          columnConfig={{
            lg: 4
          }}
        />
        <TableColumn
          title={application?.jobName || FALLBACK_VALUE}
          label="job title"
          flex="auto"
        />
        <TableColumn
          title={application?.startDate || FALLBACK_VALUE}
          label="start date"
          columnConfig={{
            lg: 4
          }}
        />
        <TableColumn
          title={application?.separationDate || FALLBACK_VALUE}
          label="separation date"
          columnConfig={{
            lg: 4
          }}
        />
        <TableColumn
          title={
            application?.location ? (
              <LocationDisplay
                location={application?.location}
                countryCode={application?.country.code}
              />
            ) : (
              FALLBACK_VALUE
            )
          }
          label="location"
          columnConfig={{
            lg: 4
          }}
        />
      </Row>
    </div>
  );
};

export default InActiveApplicationTable;
