import { ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import cs from 'classnames';

import CountryPreview from 'components/CountryPreview/CountryPreview';
import { useEditingState } from 'components/LoggedUserProfile/hooks/useEditingState';
import styles from 'components/LoggedUserProfile/styles.module.scss';
import PhoneSelect from 'components/PhoneSelect/PhoneSelect';

import { selectCountries } from 'features/countries/countriesSelectors';

import useCountryCitySelect from 'hooks/useCountryCitySelect';

import { GTMEventLogger } from 'utils/analytics';
import getCountriesOptions from 'utils/getCountriesOptions';
import parseCity from 'utils/parseCity';

import { CountryOption } from 'types/commons';
import { Jobseeker } from 'types/jobseekers';

import { FALLBACK_STRING_VALUE } from 'constants/common';
import { SECTION_ROW_GUTTER } from 'constants/common';
import { GTMEvents } from 'constants/enums';

interface PersonalInformationFormProps {
  loading: boolean;
  user: Partial<Jobseeker>;
  handleFormSubmit: (fields: Partial<Jobseeker>) => Promise<void>;
}

const PersonalInformationForm = ({
  loading,
  user,
  handleFormSubmit
}: PersonalInformationFormProps): ReactElement => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const fieldsValues = Form.useWatch([], form);
  const countries = useSelector(selectCountries);
  const { isEditing, setIsEditing, EditButton } = useEditingState();

  const defaultValidateMessages = {
    required: 'Field is required'
  };

  const {
    firstName,
    lastName,
    phoneNumber,
    location,
    country,
    citizenship,
    mailingAddress
  } = user || {};

  const initialValues = useMemo(
    () => ({
      firstName,
      lastName,
      phoneNumber,
      location: {
        ...location,
        country: country?.code || location?.country || FALLBACK_STRING_VALUE
      },
      citizenship,
      mailingAddress
    }),
    [user]
  );

  const { countrySelect, citySelect, rawString, setRawString } =
    useCountryCitySelect({
      form,
      disabled: !isEditing
    });

  const countriesOptions = getCountriesOptions({
    countries,
    useLongCode: true
  });

  useEffect(() => {
    form.setFieldsValue(initialValues);

    const { location } = initialValues;

    if (location) {
      const cityRawString = [location?.city, location?.state, location?.country]
        .filter(Boolean)
        .join(',');
      setRawString(cityRawString);
    }
  }, [initialValues, form, setRawString]);

  const handleSubmitForm = (): void => {
    const location = parseCity(rawString);
    const fields = { ...fieldsValues, location };
    handleFormSubmit(fields);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  const handleSelectChange = (
    value: string,
    fieldName: string,
    GTMEventName?: GTMEvents
  ): void => {
    if (GTMEventName) GTMEventLogger(GTMEventName);
    form.setFieldsValue({ [fieldName]: value });
    form.validateFields([fieldName]);
  };

  const renderCountryOption = ({
    data
  }: {
    data: CountryOption;
  }): ReactElement => <CountryPreview countryName={data.label} />;

  const renderCountryLabel = (option: DefaultOptionType): ReactNode => (
    <CountryPreview countryName={String(option.label)} />
  );

  return (
    <Form
      form={form}
      scrollToFirstError
      name="intakeForm"
      autoComplete="off"
      className="form-container"
      validateMessages={defaultValidateMessages}
      initialValues={{ ...initialValues }}
    >
      <Row gutter={SECTION_ROW_GUTTER}>
        <Col span={24} className={styles.editButtonContainer}>
          {EditButton}
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24} md={12}>
          <Form.Item
            name="firstName"
            label={intl.formatMessage({ id: 'form.intake.firstName' })}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.firstName'
              })}
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="lastName"
            label={intl.formatMessage({ id: 'form.intake.lastName' })}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.lastName'
              })}
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24} md={12}>
          <Form.Item
            name="phoneNumber"
            label={intl.formatMessage({ id: 'form.intake.phone' })}
          >
            <PhoneSelect name="phoneNumber" form={form} disabled={!isEditing} />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          {countrySelect}
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24} md={12}>
          {citySelect}
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            name="citizenship"
            label={intl.formatMessage({ id: 'form.intake.citizenshipNew' })}
          >
            <Select
              options={countriesOptions}
              optionRender={renderCountryOption}
              labelRender={renderCountryLabel}
              placeholder={intl.formatMessage({
                id: 'form.intake.placeholder.citizenshipNew'
              })}
              onChange={(value) =>
                handleSelectChange(
                  value,
                  'citizenship',
                  GTMEvents.SelectCitizenship
                )
              }
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={SECTION_ROW_GUTTER} className="row-section">
        <Col span={24}>
          <Form.Item
            name="mailingAddress"
            label={intl.formatMessage({ id: 'form.intake.mailAddress' })}
            tooltip={{
              title: intl.formatMessage({ id: 'form.intake.mailAddress.info' }),
              icon: <InfoCircleOutlined />
            }}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'form.intake.mailAddress.placeholder'
              })}
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className={styles.submitContainer}>
        {isEditing && (
          <>
            <Button
              className={styles.cancelButton}
              onClick={handleCancelClick}
              loading={loading}
            >
              <FormattedMessage id="form.button.cancel" />
            </Button>
            <Button
              type="primary"
              loading={loading}
              className="btn btn-primary intake-button"
              onClick={handleSubmitForm}
            >
              <FormattedMessage id="form.button.save" />
            </Button>
          </>
        )}
      </div>
    </Form>
  );
};

export default PersonalInformationForm;
