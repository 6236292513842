import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/userService';

export const fetchProfileData = createAsyncThunk(
  'profile/fetchProfileData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await UserService.getProfile();

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
