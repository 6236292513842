import { Switch } from 'antd';

import useDarkMode from 'hooks/useDarkMode';

import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';

import styles from './DarkModeSwitch.module.scss';

const DarkModeSwitch = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();

  return (
    <Switch
      checked={isDarkMode}
      onChange={toggleDarkMode}
      checkedChildren={<MoonIcon />}
      unCheckedChildren={<SunIcon />}
      className={styles.darkModeSwitch}
    />
  );
};

export default DarkModeSwitch;
