import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cs from 'classnames';

import { SpinnerSizes } from 'constants/enums';

import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  isFullHeight?: boolean;
  size?: SpinnerSizes;
  className?: string;
}

const LoadingSpinner = ({
  isFullHeight = true,
  size = SpinnerSizes.Large,
  className = ''
}: LoadingSpinnerProps): JSX.Element => (
  <div className={cs(isFullHeight ? styles.spinnerContainer : '', className)}>
    <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
  </div>
);

export default LoadingSpinner;
