import { get, patch, post } from 'utils/api/api';

import { JobApplicationsList } from 'types/jobApplications';
import { Jobseeker } from 'types/jobseekers';
import { ChangePasswordFields } from 'types/user';

import {
  CHANGE_PASSWORD,
  JOB_APPLICATIONS,
  PROFILE
} from 'constants/endpoints';

const UserService = {
  getProfile: async (): Promise<Jobseeker> => {
    const res = await get(PROFILE);
    return res;
  },

  getJobApplications: async (
    queryString: string
  ): Promise<JobApplicationsList> => {
    const res = await get(`${JOB_APPLICATIONS}${queryString}`);
    return res;
  },

  updateProfile: async (userProfile: Partial<Jobseeker>): Promise<void> => {
    await patch(PROFILE, userProfile);
  },

  changePasswordProfile: async (data: ChangePasswordFields): Promise<void> => {
    await post(CHANGE_PASSWORD, data);
  }
};

export default UserService;
