import { JobApplicationStatus } from 'constants/enums';

export interface Filters {
  country?: string | undefined;
  industry?: string[] | undefined;
  page?: number | null;
  pageSize: number;
  sortField?: string | null;
  order?: SortOrders | null;
  status?: JobApplicationStatus[] | undefined;
}

export enum SortOrders {
  Asc = 'ascend',
  Desc = 'descend'
}

export interface TimePeriodOption {
  key: string;
  label: string;
  days?: number;
}
