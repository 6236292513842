export const MAIN = '/';
export const NOT_FOUND = '/not-found';

export const JOBS = '/jobs';
export const JOB_DETAILS = `${JOBS}/:id`;
export const AMBASSADOR_JOBS = `${JOBS}/ambassador`;
export const AMBASSADOR_DETAILS = `${JOBS}/ambassador/:id`;

export const INTAKE = '/intake';
export const AMBASSADOR_INTAKE = `${INTAKE}/ambassador/:id`;
export const AMBASSADOR_THANK_YOU = `${INTAKE}/ambassador/thank-you`;
export const INTAKE_THANK_YOU = `${INTAKE}/thank-you`;
export const INTAKE_THANK_YOU_INDUSTRY = `${INTAKE}/thank-you-industry`;

export const QUESTIONNAIRE = '/questionnaire/:jobId/:userId/:applicationId';
export const QUESTIONNAIRE_THANK_YOU = '/questionnaire/thank-you';

export const AMBASSADOR = '/ambassador';

export const ADMIN = '/admin';
export const CHAT = '/admin/chat';

export const ENCRYPTED_LOGIN = '/encrypted_login/hash';

export const PROFILE = '/profile';
export const APPLICATIONS = '/applications';
export const RESET_PASSWORD = `${PROFILE}/reset-password`;
