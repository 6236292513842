export const JOIN = '/join';
export const OPEN = '/open';
export const COUNTRIES = `${OPEN}/countries`;
export const CITIES = `${OPEN}/cities`;
export const DICT = `${OPEN}/dict`;

export const JOBS = `${JOIN}/jobs`;
export const JOB = `${JOIN}/job`;
export const JOB_COUNTRIES = `${JOIN}/job/countries`;

export const JOBSEEKERS = `${JOIN}/jobseekers`;

export const APPLICATIONS = `${JOIN}/applications`;

export const USERS = '/users';
export const USERS_ME = `${USERS}/me`;
export const PROFILE = `${USERS}/profile`;
export const JOB_APPLICATIONS = `${USERS}/applications`;
export const USERS_DOCUMENTS = `${USERS}/documents`;

export const AUTH = '/auth';
export const CHECK_EMAIL = `${AUTH}/checkEmail`;
export const LOGIN = `${AUTH}/login`;
export const LOGIN_HASHED = `${AUTH}/login_hashed`;
export const CHANGE_PASSWORD = `${AUTH}/password`;

export const NOTIFICATIONS = `${USERS}/notifications`;
export const NOTIFICATIONS_COUNT = `${NOTIFICATIONS}/count`;
export const NOTIFICATIONS_READ = `${NOTIFICATIONS}/read`;
