import { ReactElement, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Row
} from 'antd';
import type { UploadFile } from 'antd';
import cs from 'classnames';
import dayjs, { Dayjs } from 'dayjs';

import Alert from 'components/Alert/Alert';
import QuestionnaireProgressBar from 'components/QuestionnaireProgressBar';
import UploadResume from 'components/UploadResume';

import { selectDictionary } from 'features/dictionaries/dictionariesSelectors';

import getLabelByValue from 'utils/getLabelByValue';

import { Attachment } from 'types/attachments';
import { Application } from 'types/jobApplications';
import { Job } from 'types/jobs';

import {
  AlertType,
  Dictionaries,
  JobApplicationStatus,
  UsersAttachmentTypes
} from 'constants/enums';
import { decisionBooleanArray } from 'constants/formData';

import { ReactComponent as RotateIcon } from 'assets/icons/rotate-left.svg';

import styles from './QuestionnaireForm.module.scss';
import { getExistingResume, setInitialFormValues } from './utils';

interface QuestionnaireFormProps {
  form: FormInstance;
  disable: boolean;
  handleSubmit: () => void;
  jobData: Job;
  application: Application;
  onChangeResume: (file: UploadFile | null) => void;
  handleSendLinkToEmail: () => void;
}

const BLOCKED_STATUSES = new Set([
  JobApplicationStatus.Accepted,
  JobApplicationStatus.Declined,
  JobApplicationStatus.Hired,
  JobApplicationStatus.Completed
]);

const QuestionnaireForm = ({
  form,
  disable,
  handleSubmit,
  jobData,
  application,
  onChangeResume,
  handleSendLinkToEmail
}: QuestionnaireFormProps): ReactElement => {
  const intl = useIntl();
  const dateFormat = 'M/D/YYYY';
  const disabledDate = (current: Dayjs): boolean =>
    current && current < dayjs().endOf('day');
  const fieldsValues = Form.useWatch([], form);

  const industriesDict = useSelector(selectDictionary(Dictionaries.Industry));
  const industryLabel =
    getLabelByValue(industriesDict, jobData.industry)?.toLowerCase() || '—';

  const showAlternativeInterviewDate =
    fieldsValues && fieldsValues.clientInterviewAvailability === false;
  const showAlternativeSalaryRange =
    fieldsValues && fieldsValues.okWithClientSalary === false;

  const defaultValidateMessages = {
    required: 'Field is required'
  };

  const location = jobData?.location;

  const getLocation = location
    ? [location.city, location.state, location.country]
        .filter(Boolean)
        .join(', ')
    : '';

  const isApplicationLocked = BLOCKED_STATUSES.has(application.status);
  const isFormDisabled = disable || isApplicationLocked;

  const existingResume = useMemo(
    () => getExistingResume(application.attachments),
    [application.attachments]
  );

  // @ts-ignore
  const validateCheckbox = (rule, value): Promise<string | void> => {
    if (!value) {
      return Promise.reject(new Error('Field is required'));
    }
    return Promise.resolve();
  };

  const handleClearForm = (): void => {
    form.resetFields();
  };

  const handleChangeDecision = (name: string, value: any) => {
    form.setFieldValue(name, value);
  };

  useEffect(() => {
    if (application) {
      setInitialFormValues(form, application);
    }
  }, [application]);

  useEffect(() => {
    form.validateFields({ validateOnly: true });
  }, [fieldsValues]);

  return (
    jobData && (
      <div className="roundedContainer">
        <div className={styles.content}>
          {!isApplicationLocked && (
            <>
              <QuestionnaireProgressBar form={form} />

              <Alert type={AlertType.Info} className={styles.sendEmailNote}>
                <FormattedMessage id="screening.sendEmail.note" />
              </Alert>

              <Button
                type="primary"
                className={cs('btn btn-primary', styles.skipEmailBtn)}
                onClick={handleSendLinkToEmail}
                disabled={isFormDisabled}
              >
                <FormattedMessage id="form.control.label.skipEmailMe" />
              </Button>
            </>
          )}

          <div>
            {isApplicationLocked ? (
              <h1 className={styles.contentTitle}>
                {intl.formatMessage(
                  { id: 'screening.welcomeMessageLocked' },
                  { jobTitle: jobData.title }
                )}
              </h1>
            ) : (
              <h1 className={styles.contentTitle}>
                {intl.formatMessage(
                  { id: 'screening.welcomeMessage' },
                  { jobTitle: jobData.title }
                )}
              </h1>
            )}
            <Form
              form={form}
              scrollToFirstError
              name="screeningForm"
              autoComplete="off"
              className="form-container"
              onFinish={handleSubmit}
              validateMessages={defaultValidateMessages}
              disabled={isFormDisabled}
            >
              {/* Availability */}
              <h2 className={styles.sectionTitle}>
                {intl.formatMessage({
                  id: 'screening.section.title.availability'
                })}
              </h2>
              <Row gutter={[24, 16]} className={styles.blockContainer}>
                <Col span={24} className="offset-right">
                  <Form.Item
                    name="startDate"
                    label={intl.formatMessage({
                      id: 'screening.form.label.whenStart'
                    })}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      // @ts-ignore
                      disabledDate={disabledDate}
                      format={dateFormat}
                      placeholder={dateFormat}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Experience */}
              <h2 className={styles.sectionTitle}>
                {intl.formatMessage({
                  id: 'screening.section.title.experience'
                })}
              </h2>
              <Row gutter={[24, 16]} className={styles.blockContainer}>
                <Col span={24} className="offset-right">
                  <Form.Item
                    name="yearsOfIndustryExperience"
                    label={intl.formatMessage(
                      { id: 'screening.form.label.yearsOfIndustryExperience' },
                      { industry: industryLabel }
                    )}
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      min={0}
                      controls={false}
                      placeholder={intl.formatMessage(
                        {
                          id: 'screening.form.label.yearsOfIndustryExperience.placeholder'
                        },
                        { industry: industryLabel }
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Location & Relocation */}
              <h2 className={styles.sectionTitle}>
                {intl.formatMessage({
                  id: 'screening.section.title.locationRelocation'
                })}
              </h2>
              <Row gutter={[24, 16]} className={styles.blockContainer}>
                <Col span={24}>
                  <Form.Item
                    name="commuting"
                    label={intl.formatMessage(
                      { id: 'screening.form.label.commutingMethod' },
                      { location: getLocation }
                    )}
                    className="large-label"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      options={decisionBooleanArray}
                      className="vertical-list"
                      onChange={(e) =>
                        handleChangeDecision('commuting', e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Legal & Work Authorization */}
              <h2 className={styles.sectionTitle}>
                {intl.formatMessage({
                  id: 'screening.section.title.legalWorkAuthorization'
                })}
              </h2>
              <Row gutter={[24, 16]} className={styles.blockContainer}>
                <Col span={24}>
                  <Form.Item
                    name="workAuthorization"
                    label={intl.formatMessage(
                      { id: 'screening.form.label.workAuthorization' },
                      { country: jobData.location?.country }
                    )}
                    className="large-label"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      options={decisionBooleanArray}
                      className="vertical-list"
                      onChange={(e) =>
                        handleChangeDecision(
                          'workAuthorization',
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Salary Expectations */}
              <h2 className={styles.sectionTitle}>
                {intl.formatMessage({
                  id: 'screening.section.title.salaryExpectations'
                })}
              </h2>
              <Row gutter={[24, 16]} className={styles.blockContainer}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="okWithClientSalary"
                    label={intl.formatMessage({
                      id: 'screening.form.label.comfortableSalaryRange'
                    })}
                    className="large-label"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      options={decisionBooleanArray}
                      className="vertical-list"
                      onChange={(e) =>
                        handleChangeDecision(
                          'okWithClientSalary',
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                {showAlternativeSalaryRange && (
                  <Col span={24} md={12}>
                    <Form.Item
                      name="salaryExpectation"
                      label={intl.formatMessage({
                        id: 'screening.form.label.salaryExpectation'
                      })}
                      className="large-label"
                      rules={[{ required: true }]}
                    >
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'screening.form.label.salaryExpectation.placeholder'
                        })}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              {/* Availability for Interview */}
              <h2 className={styles.sectionTitle}>
                {intl.formatMessage({
                  id: 'screening.section.title.availabilityForInterview'
                })}
              </h2>
              <Row gutter={[24, 16]} className={styles.blockContainer}>
                <Col
                  span={24}
                  md={12}
                  className={cs({
                    'offset-right': !showAlternativeInterviewDate
                  })}
                >
                  <Form.Item
                    name="clientInterviewAvailability"
                    label={intl.formatMessage(
                      { id: 'screening.form.label.interview' },
                      { interviewTime: application?.clientInterviewDate }
                    )}
                    className="large-label"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group
                      options={decisionBooleanArray}
                      className="vertical-list"
                      onChange={(e) =>
                        handleChangeDecision(
                          'clientInterviewAvailability',
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                {showAlternativeInterviewDate && (
                  <Col span={24} md={12}>
                    <Form.Item
                      name="alternateInterviewDate"
                      label={intl.formatMessage({
                        id: 'screening.form.label.alternativeTime'
                      })}
                      className="large-label"
                      rules={[{ required: true }]}
                    >
                      <DatePicker
                        // @ts-ignore
                        disabledDate={disabledDate}
                        format={dateFormat}
                        placeholder={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>

              {/* Upload my CV/Resume */}
              {!isApplicationLocked && (
                <>
                  <h2 className={styles.sectionTitle}>
                    {intl.formatMessage({
                      id: 'screening.section.title.uploadResume'
                    })}
                  </h2>
                  <div className={styles.blockContainer}>
                    <UploadResume
                      onChangeResume={onChangeResume}
                      disabled={isFormDisabled}
                      defaultFileList={existingResume ? [existingResume] : []}
                    />
                  </div>
                  <Row gutter={[24, 24]} className={styles.blockContainer}>
                    <Col span={24}>
                      <Form.Item
                        name="confirmationResponse"
                        valuePropName="checked"
                        rules={[{ validator: validateCheckbox }]}
                      >
                        <Checkbox className="confirm-checkbox-container">
                          <FormattedMessage id="screening.form.label.confirmationResponse" />
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="confirmationRead"
                        valuePropName="checked"
                        rules={[{ validator: validateCheckbox }]}
                      >
                        <Checkbox className="confirm-checkbox-container">
                          <FormattedMessage id="screening.form.label.confirmationRead" />
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <div className={styles.buttonContainer}>
                        <Button
                          type="link"
                          className="btn btn-icon rotation-animation"
                          icon={<RotateIcon />}
                          onClick={handleClearForm}
                          disabled={isFormDisabled}
                        >
                          <FormattedMessage id="form.control.label.clear" />
                        </Button>
                        <Button
                          className="btn btn-info"
                          onClick={handleSendLinkToEmail}
                          disabled={isFormDisabled}
                        >
                          <FormattedMessage id="form.control.label.skipEmailMe" />
                        </Button>
                        <Button
                          type="primary"
                          disabled={isFormDisabled}
                          className="btn btn-primary"
                          htmlType="submit"
                        >
                          <FormattedMessage id="form.intake.submit" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    )
  );
};

export default QuestionnaireForm;
