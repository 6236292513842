import { useNavigate } from 'react-router-dom';

import { APPLICATIONS } from 'constants/pathNames';

import { ReactComponent as BriefcaseIcon } from 'assets/icons/briefcase.svg';

import styles from '../styles.module.scss';

const JobApplications = () => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.control}
      onClick={() => navigate(APPLICATIONS)}
      role="button"
      tabIndex={0}
      title="Job Applications"
    >
      <BriefcaseIcon />
    </div>
  );
};

export default JobApplications;
