import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Modal } from 'antd';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import { setLoadingAttachmentId } from 'features/attachments/attachmentsSlice';

import useErrorCatch from 'hooks/useErrorCatch';
import useFileLoader from 'hooks/useFileLoader';
import { useAppDispatch } from 'hooks/useTypedRedux';

import adjustIframeImages from 'utils/adjustIframeImages';
import { isAttachmentToDownload } from 'utils/isAttachmentToDownload';

import { Attachment, AttachmentTypes } from 'types/attachments';

import { FORCED_SHOW_THUMBNAILS_FILE_TYPES } from 'constants/attachmentsSettings';
import { USERS_DOCUMENTS } from 'constants/endpoints';
import { MimeTypes, SpinnerSizes } from 'constants/enums';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import styles from './FileViewModal.module.scss';
import { isImageFile } from './utils/checkFileType';
import downloadFile from './utils/downloadFile';
import generateFileName from './utils/generateFileName';

const MODAL_WIDTH = 900;

interface FileViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  attachment?: Attachment<AttachmentTypes> | null;
}

const FileViewModal: FC<FileViewModalProps> = ({
  isOpen,
  onClose,
  title,
  attachment
}) => {
  const dispatch = useAppDispatch();
  const catchError = useErrorCatch();
  const ref = useRef<HTMLIFrameElement>(null);
  const [downloadUrl, setDownloadUrl] = useState<string>('');

  const {
    data,
    isLoading,
    loadFile,
    // Actual file type
    fileType: downloadedFileType
  } = useFileLoader(downloadUrl, onClose);

  useEffect(() => {
    if (!attachment) {
      return;
    }

    const { id, fileType, thumbnails } = attachment;
    const url = `${USERS_DOCUMENTS}/${id}`;
    let downloadUrl = url;
    const thumbnailUrl = thumbnails && thumbnails[0].url;

    if (thumbnailUrl && FORCED_SHOW_THUMBNAILS_FILE_TYPES.includes(fileType)) {
      downloadUrl += `/thumb/${thumbnailUrl}`;
    }
    setDownloadUrl(downloadUrl);
  }, [attachment]);

  useEffect(() => {
    if (!downloadUrl) {
      return;
    }
    loadFile();
  }, [downloadUrl, loadFile]);

  const isFileToDownload = attachment && isAttachmentToDownload(attachment);

  // Download unsupported files
  useEffect(() => {
    if (!isFileToDownload || !data) {
      return;
    }
    downloadFile({
      catchError,
      dataUrl: data,
      name:
        attachment?.filename ||
        generateFileName(attachment.id, attachment?.fileType as MimeTypes)
    });

    onClose();
  }, [isFileToDownload, data, attachment]);

  const handleLoadIframe = (): void => {
    const iframe = ref.current;

    if (!iframe || !isImageFile(downloadedFileType)) {
      return;
    }
    adjustIframeImages(iframe);
  };

  const canOpenInModal = useMemo(
    () => isOpen && !isFileToDownload,
    [isOpen, isFileToDownload]
  );

  const isShowData = !isLoading && data;

  useEffect(() => {
    if (!isFileToDownload || !attachment?.id) {
      return;
    }

    // We need this to propagate downloading state to eye icon for files, we are not going to preview
    dispatch(setLoadingAttachmentId(isLoading ? attachment.id : null));
  }, [isFileToDownload, attachment?.id, isLoading, dispatch]);

  return (
    <>
      {canOpenInModal && (
        <Modal
          open
          onCancel={onClose}
          footer={null}
          title={title}
          closeIcon={<CloseIcon />}
          destroyOnClose
          width={MODAL_WIDTH}
        >
          {isLoading && (
            <div className={styles.spinner}>
              <LoadingSpinner size={SpinnerSizes.Large} isFullHeight={false} />
            </div>
          )}
          {isShowData && (
            <iframe
              onLoad={handleLoadIframe}
              src={data}
              className={styles.frame}
              ref={ref}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default FileViewModal;
