import { get } from 'utils/api/api';

import { Country } from 'types/commons';

import { CITIES, COUNTRIES, JOB_COUNTRIES } from 'constants/endpoints';

const CommonsService = {
  getCitiesByCountryCodeAndSpelling: async (
    countryCode: string,
    spelling: string
  ): Promise<string[]> => {
    const response = await get(
      `${CITIES}?countryCode=${countryCode}&search=${spelling}`,
      {},
      true
    );
    return response;
  },

  getCountries: async (): Promise<Country[]> => {
    const response = await get(COUNTRIES, {}, true);
    return response;
  },

  getActiveJobCountries: async (): Promise<Country[]> => {
    const response = await get(JOB_COUNTRIES);
    return response;
  }
};

export default CommonsService;
