import { FC, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';

import cs from 'classnames';
import * as Flags from 'country-flag-icons/react/3x2';

import { selectCountries } from 'features/countries/countriesSelectors';

import { Country, Location } from 'types/commons';

import { flagCodes } from 'constants/flagCodes';

import styles from './LocationDisplay.module.scss';

type FlagsType = Record<string, FC<any>>;
const FlagsMap: FlagsType = Flags;

interface LocationDisplayProps {
  location: Location;
  className?: string;
  noFlag?: boolean;
  countryCode?: string | null;
}

const LocationDisplay: FC<LocationDisplayProps> = ({
  location,
  className,
  noFlag = false,
  countryCode
}): ReactElement | null => {
  const countries = useSelector(selectCountries);
  const { country, state, city } = location ?? {};

  const resolvedCountryCode = useMemo(() => {
    return (
      countryCode || countries.find((c) => c.name === country)?.code || null
    );
  }, [countryCode, countries, country]);

  if (!resolvedCountryCode || !country) return null;

  const FlagIcon =
    !noFlag && flagCodes.includes(resolvedCountryCode)
      ? FlagsMap[resolvedCountryCode]
      : null;

  return (
    <div className={cs(styles.container, className)}>
      {FlagIcon && <FlagIcon className={styles.countryCode} />}
      {[city, state, country].filter(Boolean).join(', ')}
    </div>
  );
};

export default LocationDisplay;
