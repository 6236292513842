import { useCallback, useState } from 'react';

import { StatusCodes } from 'http-status-codes';

import fetchFileWithMimeType from 'utils/api/fetchFileWithMimeType';

import { MimeTypes } from 'constants/enums';
import { NO_USER_PERMISSION_MESSAGE_TEXT } from 'constants/notifications';

import useErrorCatch from './useErrorCatch';

const useFileLoader = (
  url: string,
  onError?: () => void
): {
  data: string | null;
  fileType: MimeTypes | undefined;
  isLoading: boolean;
  loadFile: () => Promise<{
    data: string | null;
    fileType: MimeTypes | undefined;
  }>;
} => {
  const catchError = useErrorCatch();
  const [data, setData] = useState<string | null>(null);
  const [fileType, setFileType] = useState<MimeTypes | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const loadFile = useCallback(async () => {
    try {
      setIsLoading(true);
      const { blob, fileType } = await fetchFileWithMimeType(url);

      setFileType(fileType);
      const objUrl = window.URL.createObjectURL(blob);
      setData(objUrl);

      return { data: objUrl, fileType };
    } catch (error) {
      catchError({
        error,
        default: {
          message: 'Operation Failed',
          description: `Failed to download file`
        },
        additionalErrorMessageMap: {
          [StatusCodes.FORBIDDEN]: { message: NO_USER_PERMISSION_MESSAGE_TEXT }
        }
      });
      onError?.();
      return { data: null, fileType: undefined };
    } finally {
      setIsLoading(false);
    }
  }, [url, setIsLoading, setFileType, setData]);

  return { data, fileType, isLoading, loadFile };
};

export default useFileLoader;
