import { FC, useEffect, useMemo, useState } from 'react';

import { Col, Row, Skeleton } from 'antd';

import Attachment from 'components/Attachment/Attachment';
import UploadFileWithButton from 'components/UploadFileWithButton/UploadFileWithButton';

import {
  Attachment as AttachmentModel,
  AttachmentTypes
} from 'types/attachments';

import { commonFileTypes } from 'constants/attachmentsSettings';

import styles from './AttachmentsComponent.module.scss';

type AttachmentsComponentProps = {
  onChange: () => void;
  attachmentType: AttachmentTypes;
  direction?: 'vertical' | 'horizontal';
  ownerId?: string;
  childId?: string;
  editable?: boolean;
  attachmentList?: AttachmentModel<AttachmentTypes>[] | null;
  allowedFileTypes?: string;
  label?: string;
  isHideUpload?: boolean;
  isConfirmDelete?: boolean;
  isPreviewFile?: boolean;
  disabled?: boolean;
};

const AttachmentsComponent: FC<AttachmentsComponentProps> = ({
  direction = 'vertical',
  attachmentList,
  editable,
  ownerId,
  childId,
  attachmentType,
  allowedFileTypes = commonFileTypes,
  onChange,
  label,
  isHideUpload = false,
  isConfirmDelete = false,
  isPreviewFile = false,
  disabled = false
}) => {
  const [isShowSkeleton, setIsShowSkeleton] = useState(false);

  const handleSkeletonOpen = (): void => {
    setIsShowSkeleton(true);
  };

  const handleSkeletonClose = (): void => {
    setIsShowSkeleton(false);
  };

  useEffect(() => {
    handleSkeletonClose();
  }, [attachmentList]);

  const attachments = useMemo(
    () =>
      attachmentList
        ?.filter((attachment) => attachment.type === attachmentType)
        .sort((a, b) => {
          return +new Date(b.createdDate) - +new Date(a.createdDate);
        }),
    [attachmentList, attachmentType]
  );

  const colClassName =
    direction === 'vertical' ? styles.col : styles.colHorizontal;

  const isShowUpload = !isHideUpload;

  return (
    <Row
      className={direction === 'vertical' ? styles.row : styles.rowHorizontal}
      gutter={[8, 12]}
    >
      {label && (
        <Col span={24} className={styles.label}>
          {label}
        </Col>
      )}
      {isShowSkeleton && (
        <Col className={colClassName} span={24}>
          <Skeleton.Input active size="large" block />
        </Col>
      )}
      {attachments?.map((attachment) => (
        <Col key={attachment.id} className={colClassName} span={24}>
          <Attachment
            attachment={attachment as AttachmentModel<AttachmentTypes>}
            onRemoveFile={onChange}
            isConfirmDelete={isConfirmDelete}
            isPreviewFile={isPreviewFile}
            disabled={disabled}
          />
        </Col>
      ))}
      {isShowUpload && (
        <Col className={colClassName} span={24}>
          <UploadFileWithButton
            allowedFileTypes={allowedFileTypes}
            attachmentType={attachmentType}
            ownerId={ownerId as string}
            childId={childId}
            onAddFile={onChange}
            onUploadStart={handleSkeletonOpen}
            onUploadEnd={handleSkeletonClose}
            isEditable={editable && !disabled}
          />
        </Col>
      )}
    </Row>
  );
};

export default AttachmentsComponent;
