import { catchErrorProps } from 'hooks/useErrorCatch';

interface DownloadFileArgs {
  dataUrl: string;
  catchError: (props: catchErrorProps) => void;
  isOpenFile?: boolean;
  name?: string;
}

const DEFAULT_FILE_NAME = 'file';

const downloadFile = ({
  dataUrl,
  catchError,
  isOpenFile = false,
  name = DEFAULT_FILE_NAME
}: DownloadFileArgs): void => {
  try {
    const link = document.createElement('a');
    link.href = dataUrl;

    if (isOpenFile) {
      link.target = '_blank';
    } else {
      link.setAttribute('download', name);
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    catchError({
      error,
      default: {
        message: 'Operation Failed',
        description: 'Failed to download file'
      },
      consoleMessage: JSON.stringify(error)
    });
  }
};

export default downloadFile;
