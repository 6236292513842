import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Col, Row } from 'antd';

import { AppDispatch } from 'app/store';

import AttachmentsComponent from 'components/AttachmentsComponent/AttachmentsComponent';

import { selectUsersDocuments } from 'features/attachments/attachmentsSelectors';
import { getUsersDocuments } from 'features/attachments/attachmentsThunks';

import useErrorCatch from 'hooks/useErrorCatch';
import { useAppSelector } from 'hooks/useTypedRedux';

import { USER_ATTACHMENTS_FILE_TYPES } from 'constants/attachmentsSettings';
import { SECTION_ROW_GUTTER } from 'constants/common';
import { UsersAttachmentTypes } from 'constants/enums';

import styles from './AttachmentsView.module.scss';

const AttachmentsView: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const catchError = useErrorCatch();
  const usersDocuments = useAppSelector(selectUsersDocuments);

  const loadUserAttachments = async (): Promise<void> => {
    try {
      await dispatch(getUsersDocuments()).unwrap();
    } catch (error) {
      catchError({
        error,
        default: {
          description: 'Failed to load user attachments'
        }
      });
    }
  };

  useEffect(() => {
    loadUserAttachments();
  }, []);

  const handleAttachmentChange = (): void => {
    loadUserAttachments();
  };

  return (
    <div className="container">
      <Row gutter={SECTION_ROW_GUTTER}>
        <Col span={24}>
          <div className={styles.fileSection}>
            <div className={styles.subHeader}>Resume/CV</div>
            <AttachmentsComponent
              onChange={handleAttachmentChange}
              attachmentType={UsersAttachmentTypes.CV}
              attachmentList={usersDocuments}
              direction={'horizontal'}
              allowedFileTypes={
                USER_ATTACHMENTS_FILE_TYPES[UsersAttachmentTypes.CV]
              }
              isConfirmDelete
              isPreviewFile
              editable
            />
          </div>

          <div className={styles.fileSection}>
            <div className={styles.subHeader}>Immigration Documents</div>

            <div className={styles.fileSubSection}>
              <AttachmentsComponent
                onChange={handleAttachmentChange}
                attachmentType={UsersAttachmentTypes.WorkPermit}
                attachmentList={usersDocuments}
                direction={'horizontal'}
                allowedFileTypes={
                  USER_ATTACHMENTS_FILE_TYPES[UsersAttachmentTypes.WorkPermit]
                }
                isConfirmDelete
                isPreviewFile
                editable
                label="Work Permit"
              />
            </div>

            <div className={styles.fileSubSection}>
              <AttachmentsComponent
                onChange={handleAttachmentChange}
                attachmentType={UsersAttachmentTypes.Visa}
                attachmentList={usersDocuments}
                direction={'horizontal'}
                allowedFileTypes={
                  USER_ATTACHMENTS_FILE_TYPES[UsersAttachmentTypes.Visa]
                }
                isHideUpload={false}
                isConfirmDelete
                isPreviewFile
                editable
                label="Visa"
              />
            </div>
          </div>

          <div className={styles.fileSection}>
            <div className={styles.subHeader}>Personal Documents</div>

            <div className={styles.fileSubSection}>
              <AttachmentsComponent
                onChange={handleAttachmentChange}
                attachmentType={UsersAttachmentTypes.IdCard}
                attachmentList={usersDocuments}
                direction={'horizontal'}
                allowedFileTypes={
                  USER_ATTACHMENTS_FILE_TYPES[UsersAttachmentTypes.IdCard]
                }
                isConfirmDelete
                isPreviewFile
                editable
                label="ID card"
              />
            </div>

            <div className={styles.fileSubSection}>
              <AttachmentsComponent
                onChange={handleAttachmentChange}
                attachmentType={UsersAttachmentTypes.Passport}
                attachmentList={usersDocuments}
                direction={'horizontal'}
                allowedFileTypes={
                  USER_ATTACHMENTS_FILE_TYPES[UsersAttachmentTypes.Passport]
                }
                isConfirmDelete
                isPreviewFile
                editable
                label="Passport"
              />
            </div>

            <div className={styles.fileSubSection}>
              <AttachmentsComponent
                onChange={handleAttachmentChange}
                attachmentType={UsersAttachmentTypes.DriverLicense}
                attachmentList={usersDocuments}
                direction={'horizontal'}
                allowedFileTypes={
                  USER_ATTACHMENTS_FILE_TYPES[
                    UsersAttachmentTypes.DriverLicense
                  ]
                }
                isConfirmDelete
                isPreviewFile
                editable
                label="Driver's license"
              />
            </div>
          </div>

          <div className={styles.fileSection}>
            <div className={styles.subHeader}>Other</div>
            <div className={styles.fileSubSection}>
              <AttachmentsComponent
                onChange={handleAttachmentChange}
                attachmentType={UsersAttachmentTypes.OtherJobseekerDocuments}
                attachmentList={usersDocuments}
                direction={'horizontal'}
                allowedFileTypes={
                  USER_ATTACHMENTS_FILE_TYPES[
                    UsersAttachmentTypes.OtherJobseekerDocuments
                  ]
                }
                isConfirmDelete
                isPreviewFile
                editable
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AttachmentsView;
