import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SidebarContentTypes } from 'constants/enums';

interface SidebarState {
  showSidebar: boolean;
  contentId: SidebarContentTypes | null;
}

const initialState: SidebarState = {
  showSidebar: false,
  contentId: null
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
    setSidebarContent(
      state,
      action: PayloadAction<SidebarContentTypes | null>
    ) {
      if (!state.showSidebar) {
        state.showSidebar = true;
      }
      state.contentId = action.payload;
    },
    closeSidebar(state) {
      state.showSidebar = false;
      state.contentId = null;
    }
  }
});

export const { toggleSidebar, setSidebarContent, closeSidebar } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
