import { FC, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Form, Input } from 'antd';

import Alert from 'components/Alert/Alert';

import postApiData from 'utils/api/postApiData';

import { AlertType } from 'constants/enums';

import styles from '../Authentication.module.scss';

interface VerificationCodeProps {
  email: string;
}

const VerificationCode: FC<VerificationCodeProps> = ({ email }) => {
  const intl = useIntl();
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    if (email) {
      resendCode();
    }
  }, [email]);

  const resendCode = (): void => {
    postApiData('/auth/verification_code/send', { email }).then(() => {
      setIsTimerActive(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsTimerActive(false);
            return 120;
          }
          return prevTimer - 1;
        });
      }, 1000);
    });
  };

  const handleInput = (e: FormEvent<HTMLInputElement>): void => {
    const input = e.currentTarget;
    input.value = input.value.replace(/\D/g, '').slice(0, 6);
  };

  return (
    <>
      {isTimerActive && (
        <Alert type={AlertType.Info} className={styles.codeAlert}>
          <FormattedMessage id="auth.label.verificationCodeMessage" />
        </Alert>
      )}
      <Form.Item
        label={intl.formatMessage({ id: 'form.label.verificationCode' })}
        className={styles.verificationCode}
        name="verification"
        rules={[
          {
            required: true,
            message: 'Please input your verification code!'
          },
          {
            len: 6,
            message: 'Verification code must be exactly 6 digits long'
          }
        ]}
      >
        <Input
          onInput={handleInput}
          addonAfter={
            isTimerActive ? (
              <span>{timer}s</span>
            ) : (
              <Button onClick={resendCode}>Resend code</Button>
            )
          }
          placeholder="______"
        />
      </Form.Item>
    </>
  );
};

export default VerificationCode;
