import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelector } from 'react-redux';

import { FormInstance, Input, Select } from 'antd';
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumberFromString
} from 'libphonenumber-js/min';

import CountryPreview from 'components/CountryPreview/CountryPreview';

import { selectCountries } from 'features/countries/countriesSelectors';

import useInitialPhoneNumber from 'hooks/useInitialPhoneNumber';
import useWindowDimensions from 'hooks/useWindowDimensions';

import styles from './PhoneSelect.module.scss';

const { Option } = Select;

interface PhoneSelectProps {
  form: FormInstance;
  name: string;
  disabled?: boolean;
}

const PhoneSelect: FC<PhoneSelectProps> = ({
  form,
  name,
  disabled = false
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowDimensions();

  const countries = useSelector(selectCountries);
  const isValidCountryCode = (code: string): boolean =>
    getCountries().includes(code as CountryCode);

  const countryList = useMemo(
    () =>
      countries
        .filter((country) => isValidCountryCode(country.code))
        .map((country) => ({
          label: country.name,
          value: country.name,
          code: country.code,
          longCode: country.longCode,
          callingCode: getCountryCallingCode(country.code as CountryCode)
        })),
    [countries]
  );

  const {
    selectedCountry,
    phoneCode,
    phoneNumber,
    isValid,
    setSelectedCountry,
    setPhoneCode,
    setPhoneNumber,
    setIsValid
  } = useInitialPhoneNumber(form, name, countryList);

  const [parentWidth, setParentWidth] = useState<number>(0);

  const phoneValue = useMemo(
    () => (phoneNumber ? `+${phoneCode}${phoneNumber}` : undefined),
    [phoneCode, phoneNumber]
  );

  useEffect(() => {
    if (phoneValue !== form.getFieldValue(name)) {
      form.setFieldsValue({ [name]: phoneValue });
    }

    const currentErrors = form.getFieldError(name);
    const hasError = currentErrors.length > 0;
    const shouldUpdate = (isValid && hasError) || (!isValid && !hasError);

    if (shouldUpdate) {
      form.setFields([
        { name, errors: isValid ? [] : ['Invalid phone number'] }
      ]);
    }
  }, [phoneValue, form, name, isValid]);

  useEffect(() => {
    const newWidth = containerRef.current?.offsetWidth ?? 0;
    if (newWidth !== parentWidth) {
      setParentWidth(newWidth);
    }
  }, [windowWidth, parentWidth]);

  const validatePhoneNumber = useCallback(
    (number: string, country: { callingCode?: string; code?: string }) => {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        `+${country.callingCode ?? ''}${number}`,
        country.code as CountryCode
      );
      return parsedPhoneNumber
        ? isValidPhoneNumber(parsedPhoneNumber.number)
        : false;
    },
    []
  );

  const handleCountryChange = useCallback(
    (value: string): void => {
      const country = countryList.find((c) => c.value === value);
      if (country) {
        setSelectedCountry(country);
        setPhoneCode(country.callingCode);
      }
    },
    [countryList, setSelectedCountry, setPhoneCode]
  );

  const handlePhoneNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const input = e.target.value.replace(/\s/g, '');
      const withoutCountryCode = input.replace(/^\+\d+/, '');

      const isValidNow = validatePhoneNumber(
        withoutCountryCode,
        selectedCountry || {}
      );
      setIsValid((prev) => (prev !== isValidNow ? isValidNow : prev));

      setPhoneNumber(withoutCountryCode);
    },
    [validatePhoneNumber, selectedCountry, setPhoneNumber, setIsValid]
  );

  const columnDataChildren = useMemo(
    () =>
      countryList.map((country) => (
        <Option
          key={country.code}
          value={country.value}
          label={<CountryPreview countryCode={country.code} iconOnly />}
        >
          <CountryPreview
            countryCode={country.code}
            countryName={country.label}
          />{' '}
          (+{country.callingCode})
        </Option>
      )),
    [countryList]
  );

  return (
    <div className={styles.container} ref={containerRef}>
      <Input
        addonBefore={
          <Select
            value={selectedCountry?.value || undefined}
            onChange={handleCountryChange}
            optionLabelProp="label"
            popupClassName={styles.dropdownContainer}
            popupMatchSelectWidth={parentWidth}
            disabled={disabled}
          >
            {columnDataChildren}
          </Select>
        }
        disabled={disabled}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        prefix={phoneCode ? `+${phoneCode}` : ''}
      />
    </div>
  );
};

export default PhoneSelect;
