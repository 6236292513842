import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Form, notification } from 'antd';
import ApplicationService from 'services/applicationService';

import IntakeForm from 'components/IntakeForm';
import LoadingIndicator from 'components/LoadingIndicator';

import { selectUser, selectUserId } from 'features/auth/authSelectors';

import { Jobseeker } from 'types/jobseekers';

import { AMBASSADOR_THANK_YOU } from 'constants/pathNames';

import Logo from 'assets/images/svg/joblio-logo-color.svg';

import styles from './AmbassadorIntakePage.module.scss';

const IntakePage = (): ReactElement => {
  const navigate = useNavigate();
  const { id: ambassadorId } = useParams();
  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
    if (ambassadorId) {
      setLoading(true);
    }
  }, [ambassadorId]);

  const selectedJobId = location.state?.jobId;
  const jobseekerId = useSelector(selectUserId);
  const email = useSelector(selectUser)?.email;
  const sourcePlatform = 'join.joblio intake';

  const handleSubmitForm = async (
    formFields: Partial<Jobseeker>
  ): Promise<void> => {
    setLoadingButton(true);

    try {
      await submitIntakeForm(formFields);

      if (selectedJobId) {
        const applicationResponse = await createApplicationRecord(
          jobseekerId,
          selectedJobId
        );
        navigateToQuestionnaire(applicationResponse.id);
      } else {
        navigate(AMBASSADOR_THANK_YOU);
      }
    } catch (error) {
      handleSubmissionError(error);
    } finally {
      setLoadingButton(false);
    }
  };

  const submitIntakeForm = async (formFields: Record<string, unknown>) => {
    const fields = {
      ...formFields,
      sourcePlatform
    };
    await ApplicationService.postIntakeForm(fields);
  };

  const createApplicationRecord = async (
    jobseekerId: string,
    jobId: string
  ) => {
    return ApplicationService.postCreateApplication({ jobseekerId, jobId });
  };

  const navigateToQuestionnaire = (applicationRecordId: string) => {
    navigate(
      `/questionnaire/${selectedJobId}/${jobseekerId}/${applicationRecordId}`,
      { replace: true }
    );
    notification.success({
      message: "You've successfully applied for a job",
      placement: 'topRight',
      duration: 5
    });
  };

  const handleSubmissionError = (error: unknown) => {
    console.error(error);
    notification.error({
      message: 'Failed to process intake form',
      description: 'Please try again later',
      placement: 'topRight',
      duration: 5
    });
  };

  const content = (
    <div className="roundedContainer">
      <div className={styles.content}>
        <img className={styles.logo} src={Logo} alt="logo" />
        <h2 className="title">
          <FormattedMessage id="intake.contentTitle" />
        </h2>
        <p className={styles.textMargin}>
          <FormattedMessage id="form.intake.ambassadorGetConnected" />
        </p>
        <IntakeForm
          form={form}
          onFormSubmit={handleSubmitForm}
          loading={loadingButton}
          initialValues={{ email }}
        />
      </div>
    </div>
  );

  return loading ? <LoadingIndicator /> : content;
};

export default IntakePage;
