import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from 'services/userService';

import { RootState } from 'app/store';

import { generateParams } from 'features/filters/filtersSlice';

export const fetchJobApplicationsData = createAsyncThunk(
  'applications/fetchJobApplicationsData',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const params = generateParams({
      ...state.filters
    });

    try {
      const response = await UserService.getJobApplications(params);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
