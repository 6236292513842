import { ReactElement, lazy } from 'react';

import RouteWithLayout from 'routes/components/RouteWithLayout';

import AmbassadorIntakePage from 'pages/AmbassadorIntakePage';
import ApplicationsPage from 'pages/ApplicationsPage/ApplicationsPage';
import ChatPage from 'pages/ChatPage';
import EncryptedLoginPage from 'pages/EncryptedLogin/EncryptedLogin';
import IntakePage from 'pages/IntakePage';
import IntakeThankYouPage from 'pages/IntakeThankYouPage';
import IntakeThankYouPageIndustry from 'pages/IntakeThankYouPageIndustry/IntakeThankYouPageIndustry';
import JobDetails from 'pages/JobDetails/JobDetails';
import Jobs from 'pages/Jobs/Jobs';
import LoggedUserProfilePage from 'pages/LoggedUserProfilePage/LoggedUserProfilePage';
import QuestionnairePage from 'pages/QuestionnairePage';
import QuestionnaireThankYouPage from 'pages/QuestionnaireThankYouPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';

import * as path from 'constants/pathNames';

const NotFoundPage = lazy(() => import('pages/NotFoundPage'));

const MainPage = lazy(() => import('pages/MainPage'));

type RouteObject = {
  path: string;
  key: string;
  element: ReactElement;
  protectedRoute?: boolean;
  children?: RouteObject[];
};

const createRoute = (
  path: string,
  key: string,
  element: ReactElement,
  protectedRoute: boolean = false,
  children: RouteObject[] = []
): RouteObject => ({
  path,
  key,
  protectedRoute,
  element,
  children
});

const Index = [
  createRoute(path.MAIN, 'MAIN_ROOT', <RouteWithLayout />, false, [
    createRoute(path.MAIN, 'MAIN', <MainPage />),
    createRoute(path.JOB_DETAILS, 'JOB_DETAILS', <JobDetails />),
    createRoute(path.JOBS, 'JOBS', <Jobs />),
    createRoute(path.INTAKE, 'INTAKE', <IntakePage />),
    createRoute(
      path.INTAKE_THANK_YOU,
      'INTAKE_THANK_YOU',
      <IntakeThankYouPage />
    ),
    createRoute(
      path.INTAKE_THANK_YOU_INDUSTRY,
      'INTAKE_THANK_YOU_INDUSTRY',
      <IntakeThankYouPageIndustry />
    ),
    createRoute(path.AMBASSADOR, 'AMBASSADOR', <MainPage />),
    createRoute(path.AMBASSADOR_JOBS, 'AMBASSADOR_JOBS', <Jobs />),
    createRoute(
      path.AMBASSADOR_INTAKE,
      'AMBASSADOR_INTAKE',
      <AmbassadorIntakePage />
    ),
    createRoute(
      path.AMBASSADOR_THANK_YOU,
      'AMBASSADOR_THANK_YOU',
      <IntakeThankYouPage />
    ),
    createRoute(path.AMBASSADOR_DETAILS, 'AMBASSADOR_DETAILS', <JobDetails />),
    createRoute(
      path.QUESTIONNAIRE,
      'QUESTIONNAIRE',
      <QuestionnairePage />,
      true
    ),
    createRoute(
      path.QUESTIONNAIRE_THANK_YOU,
      'QUESTIONNAIRE_THANK_YOU',
      <QuestionnaireThankYouPage />
    ),
    createRoute(
      path.ENCRYPTED_LOGIN,
      'ENCRYPTED_LOGIN',
      <EncryptedLoginPage />
    ),
    createRoute(path.APPLICATIONS, 'APPLICATIONS', <ApplicationsPage />, true)
  ]),
  createRoute(
    path.ADMIN,
    'ADMIN',
    <RouteWithLayout config={{ hideFooter: true }} />,
    false,
    [createRoute(path.CHAT, 'CHAT', <ChatPage />)]
  ),
  createRoute(path.PROFILE, 'PROFILE MAIN', <RouteWithLayout />, true, [
    createRoute(path.PROFILE, 'PROFILE', <LoggedUserProfilePage />),
    createRoute(path.RESET_PASSWORD, 'RESET_PASSWORD', <ResetPasswordPage />)
  ]),
  createRoute(path.NOT_FOUND, 'NOT_FOUND', <NotFoundPage />)
];

export default Index;
